import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useNotificacion } from '../../../hooks'
import { Loader } from '../../../ui';
import { decryptRolLocalStorage } from '../../../utils';

export const Notificaciones = ({ openModal, setOpenModal, children, listado, id_avaluo }) => {

    const { crearNotificacionIndividual, actualizarNotificacionIndividual, confirmacion, cantidad_notificaciones, loading, notificaciones } = useNotificacion();
    let rol = decryptRolLocalStorage();

    function closeModal() { }

    const saveListado = () => {
        if (listado.length > 0) {
            if (rol === '4') {
                if (confirmacion === null) {
                    //significa que es la primera vez que se hará un registro de notificaciones
                    crearNotificacionIndividual(id_avaluo, listado, setOpenModal);
                } else if (cantidad_notificaciones === listado.length) {
                    setOpenModal(false);
                } else {
                    actualizarNotificacionIndividual(id_avaluo, listado, setOpenModal);
                }
            } else {
                let seleccionadosBase = 0;
                let seleccionadosActual = 0
                for (let i = 0; i < notificaciones.length; i++) {
                    if (notificaciones[i].seleccionado) {
                        seleccionadosBase += 1;
                    }
                }
                for (let i = 0; i < listado.length; i++) {
                    if (listado[i].seleccionado) {
                        seleccionadosActual += 1;
                    }
                }

                if (seleccionadosBase !== seleccionadosActual) {
                    if (seleccionadosActual === cantidad_notificaciones) {
                        actualizarNotificacionIndividual(id_avaluo, listado, setOpenModal, 1);
                    } else {
                        actualizarNotificacionIndividual(id_avaluo, listado, setOpenModal);
                    }
                } else {
                    setOpenModal(false);
                }
            }
        } else {
            setOpenModal(false);
        }

    }


    return (
        <>
            {loading && <Loader />}
            <Transition appear show={openModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className='flex justify-between'>
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Notificaciones
                                        </Dialog.Title>
                                        <button type='button' onClick={saveListado} className='bg-red-600 hover:bg-red-700 h-7 w-7 rounded-full text-white font-bold'>X</button>
                                    </div>
                                    {children}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
