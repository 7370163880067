export * from './auth/page/LoginPage';
export * from './menu/page/Menu';
export * from './avaluo/pages/Avaluo';
export * from './avaluo/pages/GeneracionAvaluo';
export * from './avaluo/pages/EstadoAvaluo';
export * from './avaluo/pages/Secretario/AvaluoAprobacion';
export * from './avaluo/pages/Secretario/AvaluoAutorizacion';
export * from './avaluo/pages/Secretario/AvaluoAutorizado';
export * from './avaluo/pages/Jefe/AvaluoPendienteAutorizacion';
export * from './user/Usuario';
export * from './colaborador/Colaborador';
export * from './avaluo/pages/ConsultaAvaluo';
export * from './avaluo/pages/Solicitud_Avaluo/SolicitudAvaluo';
export * from './avaluo/pages/Reporteria/Reportes';
export * from './avaluo/pages/fase/Fase';
export * from './avaluo/pages/Referenciales/Referenciales';