import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { detalleInmuebleCredentials, detalleInmuebleTotalCredentials, isLoading, loaded } from "./detalleInmuebleSlice";


export const obtenerDetalleInmuebleCompleto = (id_avaluo, setValue, setCrearEditar, setEdicion) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/detalleinmueble/arealegal', { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(detalleInmuebleCredentials(data));
            if (data.detalle_inmueble.length !== 0) {
                setValue('area_legal', data.area_legal);
                setValue('area_plano', data.detalle_inmueble[0].area_plano);
                setValue('area_campo', data.detalle_inmueble[0].area_campo);
                setValue('area_legal2', data.area_legal);
                setValue('area_fisica', data.detalle_inmueble[0].area_campo);

                let areaFisica = Number.parseFloat(data.detalle_inmueble[0].area_campo);

                let diferencia = 0;

                if (areaFisica > Number.parseFloat(data.area_legal)) {
                    diferencia = areaFisica - Number.parseFloat(data.area_legal);
                } else if (areaFisica < Number.parseFloat(data.area_legal)) {
                    diferencia = Number.parseFloat(data.area_legal) - areaFisica;
                }
                let porcentaje = (diferencia * 100) / Number.parseFloat(data.area_legal);

                setValue('diferencia', diferencia.toFixed(2));
                setValue('porcentaje', porcentaje.toFixed(2));
                setValue('forma', data.detalle_inmueble[0].forma);
                setValue('uso_principal', data.detalle_inmueble[0].uso_principal);
                setValue('topografia', data.detalle_inmueble[0].topografia);
                setValue('servicio_agua_entubada', data.detalle_inmueble[0].servicio_agua_entubada);
                setValue('energia_electrica', data.detalle_inmueble[0].energia_electrica);
                setValue('drenaje', data.detalle_inmueble[0].drenaje);
                setCrearEditar('Editar');
                setEdicion(false);
            } else {
                setEdicion(true);
            }

        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const crearEditarDetalleInmuebleNuevo = (id_avaluo, data, crearEditar, setEdicion, setValue, setCrearEditar) => {
    return async (dispatch) => {
        try {
            if (crearEditar === 'Crear') {
                dispatch(isLoading());
                await apiRest.post('/detalleinmueble/crear', data, { headers: { 'token': getToken() }, params: { id_avaluo } });
                successModal('Registro creado correctamente', 'center');
                dispatch(loaded());
                dispatch(obtenerDetalleInmuebleCompleto(id_avaluo, setValue, setCrearEditar, setEdicion))
            } else {
                dispatch(isLoading());
                await apiRest.put('/detalleinmueble/editar', data, { headers: { 'token': getToken() }, params: { id_avaluo } });
                successModal('Registro editado correctamente', 'center');
                setEdicion(false);
                dispatch(loaded());
            }
        } catch (error) {
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const obtenerDetalleInmuebleIndividual = (id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/detalleinmueble', { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(detalleInmuebleTotalCredentials(data));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
        }
    }
}