import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { colaboradorCredentials, colaboradorFilstroCredentials, colaboradorIndividualCredentials, isLoading, loaded } from "./colaboradorSlice";



export const obtenerColaboradores = (busqueda = '', limite, pagina, agencia = '', puesto = '') => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/colaborador', { headers: { 'token': getToken() }, params: { busqueda, limite, pagina, agencia, puesto } });
            dispatch(colaboradorCredentials(data));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
            errorModal('ocurrió un error al cargar la información de los colaboradores');
        }
    }
}

export const obtenerColaboradoresFiltro = (agencia = '', setValue, colaborador) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/colaborador/filtro', { headers: { 'token': getToken() }, params: { agencia } });
            dispatch(colaboradorFilstroCredentials(data));
            setValue('id_colaborador', colaborador);
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
            errorModal('ocurrió un error al cargar la información de los colaboradores');
        }
    }
}

export const obtenerColaboradorIndividual = (id_colaborador, setShow2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/colaborador/individual', { headers: { 'token': getToken() }, params: { id_colaborador } });
            dispatch(colaboradorIndividualCredentials(data));
            setShow2(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('Ocurrió un error al cargar la información del colaborador');
        }
    }
}

export const crearColaborador = (data, setShow, setValue) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/colaborador/crear', data, { headers: { 'token': getToken() } });
            dispatch(loaded());
            successModal('Registro creado correctamente', 'center');
            setShow(false);
            setValue('buscador', data.colaborador);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('Ocurrió un error al crear al colaborador');
        }
    }
}

export const editarColaborador = (id_colaborador, data, setShow2, setValue2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/colaborador/editar', data, { headers: { 'token': getToken() }, params: { id_colaborador } });
            dispatch(loaded());
            successModal('Registro editado correctamente', 'center');
            setShow2(false);
            setValue2('buscador', data.colaborador);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('Ocurrió un error al editar al colaborador');
        }
    }
}

export const eliminarColaborador = (id_colaborador) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/colaborador/eliminar', { headers: { 'token': getToken() }, params: { id_colaborador } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerColaboradores());
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('Ocurrió un error al eliminar al colaborador');
        }
    }
}