import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAgencia, useAvaluoSlice, useUser } from "../../../../hooks";
import { InputDate2, InputSearch2, Loader, Pagination, SelectOption4, TotalPagesAndData, TransitionPopover } from "../../../../ui";
import { TdTable, ThTable, TrTable } from "../../components";
import { questionModal } from "../../../../utils";
import { InformeAvaluo } from "../InformeAvaluo";


export const AvaluoPendienteAutorizacion = () => {

    const { register, watch } = useForm();
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const { avaluos, totalPaginas, obtenerTotalAvaluos, avaluoAutorizado, loading, avaluoInforme, cantidad } = useAvaluoSlice();
    const { valuadores, asesores, obtenerTotalValuadores, obtenerTotalAsesores } = useUser();
    const { agencia, obtenerTotalAgencias } = useAgencia();

    let watchItems = watch();

    useEffect(() => {
        obtenerTotalValuadores()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        obtenerTotalAvaluos(watchItems.buscador, page, 2, watchItems.valuador, "", watchItems.id_agencia, watchItems.id_usuario, watchItems.fechaA, watchItems.fechaB);
        // eslint-disable-next-line
    }, [watchItems.buscador, page, watchItems.valuador, watchItems.id_agencia, watchItems.id_usuario, watchItems.fechaA, watchItems.fechaB]);

    useEffect(() => {
        setPage(1);
    }, [watchItems.buscador, watchItems.valuador, watchItems.id_agencia, watchItems.id_usuario, watchItems.fechaA, watchItems.fechaB]);

    useEffect(() => {
        obtenerTotalAgencias();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (watchItems.id_agencia === undefined || watchItems.id_agencia === '') {
            obtenerTotalAsesores('');
        } else {
            obtenerTotalAsesores(watchItems.id_agencia);
        }
        // eslint-disable-next-line
    }, [watchItems.id_agencia])

    const visualizarInforme = (id_avaluo) => {
        avaluoInforme(id_avaluo, setShow);
    }


    return (
        <>
            {loading && <Loader />}
            <div className='mx-20 my-14'>
                <div className='my-5 text-gray-400 font-bold text-4xl'>
                    <h1>
                        AVALÚOS PENDIENTES DE AUTORIZAR
                        <hr className='w-1/3' />
                    </h1>

                </div>
                <div className='flex lg:flex-nowrap lg:justify-start flex-wrap'>
                    <InputSearch2 nameLabel='buscador' register={register} name="buscador" position='lg:w-1/3 mb-3 font-semibold text-gray-500 mx-1' />
                    <SelectOption4 nameLabel='Valuador' register={register} name="valuador" array={valuadores} valueOption='id_usuario' nameOption='nombre' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                    <SelectOption4 nameLabel='agencia' register={register} name="id_agencia" array={agencia} valueOption='id_agencia' nameOption='agencia' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                    <SelectOption4 nameLabel='asesor' register={register} name="id_usuario" array={asesores} valueOption='id_usuario' nameOption='nombre' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                    <InputDate2 nameLabel='(Aprobados) de:' register={register} name='fechaA' position='lg:w-1/5' />
                    <InputDate2 nameLabel='(Aprobados) hasta:' register={register} name='fechaB' position='lg:w-1/5' />
                </div>
                <table className={"border-collapse w-full"}>
                    <thead>
                        <tr>
                            <ThTable titulo='Correlativo' />
                            <ThTable titulo='Solicitante' />
                            <ThTable titulo='CIF' />
                            <ThTable titulo='Ubicación Legal' />
                            <ThTable titulo='Agencia' />
                            <ThTable titulo='Asesor' />
                            <ThTable titulo='Valuador' />
                            <ThTable titulo='Solicitud' />
                            <ThTable titulo='Aprobación' />
                            <ThTable titulo='Acciones' />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            avaluos.map((item, index) => (
                                <TrTable key={index}>
                                    <TdTable titulo='Correlativo' valor={item.solicitud_avaluo.correlativo} />
                                    <TdTable titulo='Solicitante' valor={item.solicitante} />
                                    <TdTable titulo='CIF' valor={item.solicitud_avaluo.cif_solicitante} />
                                    <TdTable titulo='Ubicación Legal' valor={item.ubicacion_legal} />
                                    <TdTable titulo='Agencia' valor={item.solicitud_avaluo.agencium.agencia} />
                                    <TdTable titulo='Asesor' valor={item.solicitud_avaluo.usuario.nombre} />
                                    <TdTable titulo='Valuador' valor={item.usuario.nombre} />
                                    <TdTable titulo='Solicitud' valor={item.fecha_aprobacion} />
                                    <TdTable titulo='Aprobación' valor={item.fecha_aprobado} />
                                    <TdTable valor={
                                        <div className='flex justify-center mx-2'>
                                            <div className="w-4 mr-3 transform hover:text-green-500 hover:scale-110 ">
                                                <svg onClick={() => visualizarInforme(item.id_avaluo)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </div>
                                            <div className="w-4 transform hover:text-blue-500 hover:scale-110">
                                                <svg onClick={() => questionModal('Autorizar', '¿Desea autorizar este avalúo?', () => avaluoAutorizado(item.id_avaluo, obtenerTotalAvaluos))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                                </svg>
                                            </div>
                                        </div>
                                    } />
                                </TrTable>
                            ))
                        }
                    </tbody>
                </table>
                <div className="flex justify-end mt-3">
                    <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
                </div>
                <div className="flex justify-end mt-3">
                    <TotalPagesAndData amount={cantidad} page={page} totPages={totalPaginas} />
                </div>
                <TransitionPopover show={show} setShow={setShow}>
                    <InformeAvaluo setShow={setShow} />
                </TransitionPopover>
            </div>
        </>
    )
}
