import { TdTable, ThTable, TrTable } from '../../components'
import { Loader, TransitionPopover } from '../../../../ui'
import { EditarValorInfraestructura } from './EditarValorInfraestructura'
import { useAvaluoSlice, useInfraestructuraSlice } from '../../../../hooks';

export const ValorInfraestructura = ({ show3, setShow3 }) => {

    const { infraestructuras, obtenerInfraestructuraIndividual, loading } = useInfraestructuraSlice();
    const { avaluoIndividual } = useAvaluoSlice();

    const separator = (numb) => {
        if (numb) {
            var str = numb.toString().split(".");
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");
        } else {
            return 0.00
        }
    }

    return (
        <>
            {loading && <Loader />}
            <div className='mx-20 my-10'>
                <div className="my-2 w-full px-3 ">
                    <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">INFRAESTRUCTURA</h1>
                </div>

                <table className={"border-collapse w-full"}>
                    <thead>
                        <tr>
                            <ThTable titulo='Construcción' color="bg-orange-600" />
                            <ThTable titulo='Área m2' color="bg-orange-600" />
                            <ThTable titulo='Valor total' color="bg-orange-600" />
                            <ThTable titulo='Acciones' color="bg-orange-600" />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            infraestructuras.map((item, index) => (
                                <TrTable key={index}>
                                    <TdTable titulo='Construccion' valor={item.construccion} />
                                    <TdTable titulo='Area' valor={item.area} />
                                    <TdTable titulo='Valor total' valor={
                                        item.valor ?
                                            avaluoIndividual.tipo_avaluo === 1 ?
                                                `Q. ${separator((((Math.floor((Number.parseFloat(item.valor) * Number.parseFloat(item.edad_castigo) * Number.parseFloat(item.mantenimiento_castigo) * Number.parseFloat(item.proceso_constructivo_castigo) * Number.parseFloat(item.deseabilidad_castigo) * 0.7) / 1)) * 1) * Number.parseFloat(item.area)).toFixed(2))}`
                                                :
                                                `Q. ${separator((((Math.floor((Number.parseFloat(item.valor) * Number.parseFloat(item.edad_castigo) * Number.parseFloat(item.mantenimiento_castigo) * Number.parseFloat(item.proceso_constructivo_castigo) * Number.parseFloat(item.deseabilidad_castigo)) / 1)) * 1) * Number.parseFloat(item.area)).toFixed(2))}`
                                            : 'PENDIENTE'
                                    } />
                                    <TdTable valor={
                                        <div className='flex justify-center mx-2'>
                                            <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                                                <svg onClick={() => obtenerInfraestructuraIndividual(item.id_infraestructura, setShow3)}
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                </svg>
                                            </div>
                                        </div>
                                    } />
                                </TrTable>
                            ))
                        }
                    </tbody>
                </table>
                <TransitionPopover show={show3} setShow={setShow3}>
                    <EditarValorInfraestructura setShow3={setShow3} />
                </TransitionPopover>
            </div >
        </>
    )
}
