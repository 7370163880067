
export const ModalGenericoCrear = ({ showModal, setShowModal, title, children, handleSubmit, reset, funcion, id_registro_propiedad }) => {

    const cerrarModal = () => {
        reset();
        setShowModal(false);
    }

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-start p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-bold text-gray-400">
                                        {title}
                                    </h3>
                                </div>
                                {/*body*/}
                                <form onSubmit={handleSubmit((data) => funcion(data, setShowModal, id_registro_propiedad, reset))}>
                                    <div className="relative p-6 flex-auto">
                                        {children}
                                    </div>

                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={cerrarModal}
                                        >
                                            Cerrar
                                        </button>
                                        <button
                                            className="bg-blue-900 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:bg-blue-800 hover:shadow-2xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="submit"
                                        // onClick={() => setShowModal(false)}
                                        >
                                            Guardar
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}
