import { useForm } from "react-hook-form";
import { InputEmail, InputNumber, InputPassword, InputText, SelectOption } from "../../ui";
import { useEffect } from "react";
import { useAgencia, useUser } from "../../hooks";
import { questionModal } from "../../utils";

let estados = [{ id_estado: 1, estado: 'Habilitado' }, { id_estado: 2, estado: 'Deshabilitado' }];

export const CrearUsuario = ({ setShow, setValue }) => {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const { obtenerRolesTotales, roles, crearUsuarioIndividual } = useUser();
  const { agencia, obtenerTotalAgencias } = useAgencia();

  useEffect(() => {
    obtenerRolesTotales();
    obtenerTotalAgencias();
    // eslint-disable-next-line
  }, [])


  return (
    <>
      <div className='flex justify-center'>
        <div className="w-full max-w-4xl bg-white rounded-xl shadow-2xl px-6 py-3 mb-5 mt-10">
          <div className='mb-6 text-gray-400 font-bold sm:text-2xl text-xl'>
            <h1>
              CREAR USUARIO
              <hr />
            </h1>
          </div>
          <form onSubmit={handleSubmit((data) => questionModal('Crear usuario', '¿Está seguro que desea crear a este usuario?', () => crearUsuarioIndividual(data, setShow, setValue)))}>
            <div className="flex flex-wrap -mx-3 mb-6">
              <InputText nameLabel='Nombre' register={register} name="nombre" message="La información es requerida" position="md:w-1/2" errors={errors} Placeholder="Ej. José David Ruiz Rojas" />
              <InputText nameLabel='Usuario' register={register} name="usuario" message="La información es requerida" position="md:w-1/2" errors={errors} Placeholder="Ej. cojruiz" />
              <InputPassword nameLabel='Contraseña' register={register} name="contrasenia" message="La información es requerida" position="md:w-1/2" errors={errors} Placeholder="****************" />
              <InputEmail nameLabel='Correo electrónico' register={register} name="correo_electronico" message="La información es requerida" position="md:w-1/2" errors={errors} Placeholder="jose.ruiz@cobanesmicoope.com" />
              <InputNumber nameLabel='Teléfono Celular' register={register} name="telefono" message="La información es requerida" position="md:w-1/2" errors={errors} Placeholder="31769827" />
              <SelectOption nameLabel='Estado' register={register} name='estado' message='El valor es requerido' errors={errors} array={estados} valueOption='id_estado' nameOption='estado' position="md:w-1/2" />
              <SelectOption nameLabel='Rol' register={register} name='id_rol' message='El valor es requerido' errors={errors} array={roles} valueOption='rol' nameOption='descripcion' position="md:w-1/2" />
              <SelectOption nameLabel='Agencia' register={register} name='id_agencia' message='El valor es requerido' errors={errors} array={agencia} valueOption='id_agencia' nameOption='agencia' position="md:w-1/2" />

              <div className="w-full md:w-full px-3">
                <div className="text-center flex">
                  <input
                    className="w-full mr-1 px-3 py-2 font-bold text-white bg-blue-900 rounded-md cursor-pointer hover:bg-blue-800 focus:outline-none focus:shadow-outline"
                    type="submit"
                    value='Crear'
                  />
                  <button type="button" onClick={() => setShow(false)} className="font-bold text-white w-full px-4 py-2 bg-red-700 rounded-md cursor-pointer hover:bg-red-600 focus:outline-none focus:shadow-outline">
                    Cancelar
                  </button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>

    </>
  )
}
