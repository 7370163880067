import { useForm } from "react-hook-form";
import { useInfraestructuraSlice } from "../../../../hooks";
import { InputDecimal, InputText, NormalButton2, SelectOption } from "../../../../ui";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { decryptId, questionModal } from "../../../../utils";

let techo = [{ tipoTecho: 'Entrepiso de madera' }, { tipoTecho: 'Lámina' }, { tipoTecho: 'Lámina/Herrería' }, { tipoTecho: 'Lámina/Madera' }, { tipoTecho: 'Losa' }, { tipoTecho: 'Losa prefabricada' }, { tipoTecho: 'Losacero' }, { tipoTecho: 'Teja' }, { tipoTecho: 'Terraza' }, { tipoTecho: 'Terraza española' }, { tipoTecho: 'Palma' }, { tipoTecho: 'No posee' }, { tipoTecho: 'Otro' }]
let revestimientoTecho = [{ revestimiento: 'Cielo falso' }, { revestimiento: 'Machimbre' }, { revestimiento: 'Repello y cernido' }, { revestimiento: 'No posee' }, { revestimiento: 'Otro' }]
let pared = [{ tipoPared: 'Block' }, { tipoPared: 'Concreto' }, { tipoPared: 'Ladrillo' }, { tipoPared: 'Madera' }, { tipoPared: 'Tabla roca' }, { tipoPared: 'Tabla yeso' }, { tipoPared: 'No Posee' }, { tipoPared: 'Otro' }]
let revestimientoPared = [{ revestimiento: 'Repello' }, { revestimiento: 'Block visto' }, { revestimiento: 'Fachaleta' }, { revestimiento: 'No posee' }, { revestimiento: 'Otros' }]
let piso = [{ tipoPiso: 'Baldosa' }, { tipoPiso: 'Cemento' }, { tipoPiso: 'Ceramica' }, { tipoPiso: 'Granito' }, { tipoPiso: 'Madera' }, { tipoPiso: 'No posee' }, { tipoPiso: 'Otro' }]
let ventana = [{ tipoVentana: 'Aluminio' }, { tipoVentana: 'Herrería' }, { tipoVentana: 'Madera' }, { tipoVentana: 'PVC' }, { tipoVentana: 'No posee' }, { tipoVentana: 'Otro' }]
let puerta = [{ tipoPuerta: 'Cortina metálica' }, { tipoPuerta: 'Herrería' }, { tipoPuerta: 'Madera' }, { tipoPuerta: 'Prefabricadas' }, { tipoPuerta: 'No posee' }, { tipoPuerta: 'Otro' }]
let mantenimiento = [{ tipoMantenimiento: 'Bueno' }, { tipoMantenimiento: 'Regular' }, { tipoMantenimiento: 'Malo' }, { tipoMantenimiento: 'Precario' }]
let electrica = [{ instalacion: 'Oculta' }, { instalacion: 'Expuesta' }, { instalacion: 'Semi oculta' }, { instalacion: 'No posee' }]

export const EditarInfraestructura = ({ setShow2 }) => {

  const { infraestructura, editarInfraestructuraIndividual } = useInfraestructuraSlice();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const { id } = useParams();

  useEffect(() => {
    setValue('construccion', infraestructura.construccion);
    setValue('area', infraestructura.area);
    setValue('edad', infraestructura.edad);
    setValue('techo', infraestructura.techo);
    setValue('revestimiento_techo', infraestructura.revestimiento_techo);
    setValue('pared', infraestructura.pared);
    setValue('revestimiento_pared', infraestructura.revestimiento_pared);
    setValue('piso', infraestructura.piso);
    setValue('ventana', infraestructura.ventana);
    setValue('puerta', infraestructura.puerta);
    setValue('mantenimiento', infraestructura.mantenimiento);
    setValue('instalacion_electrica', infraestructura.instalacion_electrica);
    // eslint-disable-next-line
  }, [infraestructura])


  return (
    <div className='flex justify-center mt-4'>
      <div className='w-full max-w-7xl bg-gray-50 rounded-lg shadow-2xl p-6'>
        <div className=" mx-auto p-4">
          <form onSubmit={handleSubmit((data) => questionModal('Editar', '¿Desea confirmar la edición de este registro?', () => editarInfraestructuraIndividual(infraestructura.id_infraestructura, data, decryptId(id), setShow2)))}>
            {/* questionModal('Editar', '¿Desea editar el registro?', () => crearInfraestructuraIndividual(decryptId(id), data, setShow)) */}
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="my-2 w-full px-3 ">
                <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">EDITAR INFRAESTRUCTURA</h1>
              </div>
              <InputText nameLabel='Construcción' register={register} name="construccion" message="El dato es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. construcción 1" />
              <InputDecimal nameLabel='Área m2' register={register} name="area" message="El dato es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 45.00" />
              <InputText nameLabel='Edad' register={register} name="edad" message="El dato es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 6" />
              <SelectOption nameLabel='Techo' register={register} name='techo' message='El dato es requerido' errors={errors} array={techo} valueOption='tipoTecho' nameOption='tipoTecho' position="md:w-1/2" requerido={false} />
              <SelectOption nameLabel='Revestimiento del techo' register={register} name='revestimiento_techo' message='El dato es requerido' errors={errors} array={revestimientoTecho} valueOption='revestimiento' nameOption='revestimiento' position="md:w-1/2" requerido={false} />
              <SelectOption nameLabel='Pared' register={register} name='pared' message='El dato es requerido' errors={errors} array={pared} valueOption='tipoPared' nameOption='tipoPared' position="md:w-1/2" />
              <SelectOption nameLabel='Revestimiento de la pared' register={register} name='revestimiento_pared' message='El dato es requerido' errors={errors} array={revestimientoPared} valueOption='revestimiento' nameOption='revestimiento' position="md:w-1/2" />
              <SelectOption nameLabel='Piso' register={register} name='piso' message='El dato es requerido' errors={errors} array={piso} valueOption='tipoPiso' nameOption='tipoPiso' position="md:w-1/3" requerido={false} />
              <SelectOption nameLabel='Ventanas' register={register} name='ventana' message='El dato es requerido' errors={errors} array={ventana} valueOption='tipoVentana' nameOption='tipoVentana' position="md:w-1/3" requerido={false} />
              <SelectOption nameLabel='Puertas' register={register} name='puerta' message='El dato es requerido' errors={errors} array={puerta} valueOption='tipoPuerta' nameOption='tipoPuerta' position="md:w-1/3" requerido={false} />
              <SelectOption nameLabel='Mantenimiento' register={register} name='mantenimiento' message='El dato es requerido' errors={errors} array={mantenimiento} valueOption='tipoMantenimiento' nameOption='tipoMantenimiento' position="md:w-1/2" />
              <SelectOption nameLabel='Instalación eléctrica' register={register} name='instalacion_electrica' message='El dato es requerido' errors={errors} array={electrica} valueOption='instalacion' nameOption='instalacion' position="md:w-1/2" requerido={false} />

              <div className="w-full mx-3 mt-1 text-center flex justify-end">
                <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title='Editar' />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div >
  )
}
