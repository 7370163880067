import { createSlice } from '@reduxjs/toolkit';

export const infraestructuraSlice = createSlice({
    name: 'infraestructura',
    initialState: {
        infraestructuras: [],
        loading: false,
        infraestructura: {}
    },
    reducers: {
        infraestructuraCredentials: (state, { payload }) => {
            state.infraestructuras = payload.infraestructuras;
            state.loading = false;
        },
        infraestructuraIndividualCredentials: (state, {payload}) => {
            state.infraestructura = payload.infraestructura;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});


// Action creators are generated for each case reducer function
export const { infraestructuraCredentials, isLoading, loaded, infraestructuraIndividualCredentials } = infraestructuraSlice.actions;