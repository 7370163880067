import { useForm } from "react-hook-form";
import { useAnexo } from "../../../../hooks";
import { InputImage, InputText, NormalButton2, SelectOption } from "../../../../ui";
import { decryptId, questionModal } from "../../../../utils";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const CrearAnexo = ({ setShow }) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [fileList, setFileList] = useState([]);
    const { crearAnexoIndividual } = useAnexo();
    const { id } = useParams();


    return (
        <div className='flex justify-center mt-4'>
            <div className='w-full max-w-7xl bg-gray-50 rounded-lg shadow-2xl p-6'>
                <div className=" mx-auto p-4">
                    <form onSubmit={handleSubmit((data) => questionModal('Crear', '¿Desea guardar el registro?', () => crearAnexoIndividual(data, setShow, decryptId(id), fileList, setFileList)))}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="my-2 w-full px-3 ">
                                <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">CREAR ANEXO</h1>
                            </div>
                            <InputText nameLabel='Descripción' register={register} name="descripcion" message="El dato es requerido" errors={errors} Placeholder="Ej. construcción 1" />
                            <SelectOption nameLabel='Dimensión de las imágenes' register={register} name='dimension' message='El valor es requerido' errors={errors} array={dimensiones} valueOption='dimension' nameOption='dimension' position="md:w-1/2" />
                            <InputImage fileList={fileList} setFileList={setFileList} />
                            <div className="w-full mx-3 mt-1 text-center flex justify-end">
                                <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title='Guardar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}

const dimensiones = [
    {dimension: '1/1'},
    {dimension: '1/2'},
    {dimension: '1/4'},
]