import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { isLoading, loaded, observacionCredentials, observacionIndividualCredentials } from "./observacionSlice";


export const obtenerObservaciones = (id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/observacion', { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(observacionCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const obtenerObservacion = (id_observacion, setShow2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/observacion/individual', { headers: { 'token': getToken() }, params: { id_observacion } });
            dispatch(observacionIndividualCredentials(data));
            setShow2(true);
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
        }
    }
}


export const eliminarObservacion = (id_observacion, id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/observacion/eliminar', { headers: { 'token': getToken() }, params: { id_observacion } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerObservaciones(id_avaluo));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const crearObservacion = (id_avaluo, data, setShow) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/observacion/crear', data, { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            dispatch(obtenerObservaciones(id_avaluo));
            setShow(false);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const editarObservacion = (id_observacion, id_avaluo, data, setShow2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/observacion/editar', data, { headers: { 'token': getToken() }, params: { id_observacion } });
            dispatch(loaded())
            successModal('Registro editado correctamente', 'center');
            dispatch(obtenerObservaciones(id_avaluo));
            setShow2(false);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded())
            errorModal('error al hacer la edición');
        }
    }
}