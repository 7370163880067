import { useEffect, useState } from "react"
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { useForm } from "react-hook-form";
import { errorModal, questionModal } from "../../../../../../utils";
import { InputText, NormalButton, NormalButton2 } from "../../../../../../ui";

export const RegistroFase2Gravamen = ({ tab, setTab, formulario, setFormulario }) => {

  const [contador, setContador] = useState(1);
  const [gravamen, setGravamen] = useState([]);
  const { register, handleSubmit, formState: { errors }, watch, unregister, setValue } = useForm();
  let watchItems = watch();

  useEffect(() => {
    if (formulario.gravamen) {
      if (formulario.gravamen.length > 0) {
        let arregloAux = [];
        for (let i = 0; i < formulario.gravamen.length; i++) {
          arregloAux.push({ numero: i + 1 });
          setValue(`deudor${i + 1}`, formulario.gravamen[i].deudor);
          setValue(`acreedor${i + 1}`, formulario.gravamen[i].acreedor)
        }
        setGravamen(arregloAux);
        setValue('numero_gravamen', formulario.gravamen[0].numero_gravamen);
        setContador(formulario.gravamen.length + 1);
      }
    }
    // eslint-disable-next-line
  }, [])

  const agregarGravamen = () => {
    if (watchItems.numero_gravamen === '' || watchItems.numero_gravamen === undefined) {
      errorModal('Debe ingresar primero el número de gravamen')
    } else {
      setGravamen([...gravamen, { numero: contador }]);
      setContador(contador + 1);
    }
  }

  const quitarGravamen = (item, index) => {
    if (item.numero === (contador - 1)) {
      let auxGravamen = [...gravamen]
      auxGravamen.splice(index, 1)
      setGravamen(auxGravamen)
      unregister(`deudor${item.numero}`);
      unregister(`acreedor${item.numero}`);
      setContador(contador - 1)
    } else {
      errorModal('existen gravámenes posteriores a este');
    }
  }

  const almacenarDatosFormulario = (data) => {
    let gravamen = [];
    for (let i = 1; i < contador; i++) {
      gravamen.push({
        numero_gravamen: data.numero_gravamen,
        deudor: data[`deudor${i}`],
        acreedor: data[`acreedor${i}`]
      })
    }
    setFormulario({ ...formulario, gravamen });
    setTab(tab + 1);
  }

  return (
    <form onSubmit={handleSubmit((data) => almacenarDatosFormulario(data))}>
      <div className="flex flex-wrap">
        <div className='w-full pt-5 mb-4 border-b-8 border-gray-300 rounded-md flex items-center justify-center'>

          <label className=' text-center block text-2xl font-bold text-gray-400'>
            Gravámenes vigentes
          </label>
          <button type='button' className='h-6 w-6 text-green-600 hover:-translate-y-1 hover:scale-110 ease-in-out delay-150 duration-100' onClick={agregarGravamen}>
            <PlusCircleIcon />
          </button>
        </div>

        <div className="w-full">
          <InputText nameLabel='Número' register={register} name="numero_gravamen" requerido={false} message="El numero es requerido" position="md:w-1/2" errors={errors} Placeholder="1 y 2" disabled={(contador > 1) ? true : false} />
        </div>

        {
          gravamen.map((item, index) =>
            <div key={item.numero} className='my-4 px-1 pb-4 w-full  flex flex-wrap' >

              <div className='w-full bg-gray-400 rounded-t-xl flex items-center justify-center'>
                <label className='text-center block text-xl font-bold text-white'>
                  Gravamen
                </label>
                <button type='button' className='h-6 w-6 ml-5 text-red-600 hover:-translate-y-1 hover:scale-110 ease-in-out delay-150 duration-100' onClick={() => questionModal('Eliminar', 'Esta Segurdo de Eliminar', () => { quitarGravamen(item, index) })}>
                  <XCircleIcon />
                </button>
              </div>

              <div className='w-full md:flex border-x-4 border-b-4 border-gray-400 rounded-b-xl shadow-lg p-2'>
                <div className='md:mr-2 md:w-1/2'>
                  <InputText nameLabel='Deudor' register={register} name={`deudor${item.numero}`} message="El deudor es requerido" errors={errors} Placeholder="Hector Mazariegos" />
                </div>
                <div className='md:ml-2 md:w-1/2'>
                  <InputText nameLabel='Acreedor' register={register} name={`acreedor${item.numero}`} message="El acreedor es requerido" errors={errors} Placeholder="COOPERATIVA DE AHORRO Y CRÉDITO INTEGRAL “COBAN” RESPONSABILIDAD LIMITADA" />
                </div>
              </div>
            </div>
          )
        }

        <div className="w-full mx-3 mt-5 text-center flex justify-end">
          <NormalButton funcion={() => setTab(tab - 1)} bgColor='bg-red-700' bgText='text-white font-semibold' hoverColor='hover:bg-red-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title={
            <div className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
              Anterior
            </div>
          } />

          <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title={
            <div className="flex">
              Siguiente
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </div>
          } />
          {/* <input
            className="mr-1 px-10 py-2 font-bold text-white bg-blue-900 rounded-md cursor-pointer hover:bg-blue-800 focus:outline-none focus:shadow-outline"
            type="submit"
            value={`${} continuar`}
          /> */}
        </div>
      </div>
    </form>
  )
}
