import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRegistroPropiedadSlice } from "../../../../../../hooks";
import { decryptId, questionModal } from "../../../../../../utils";
import { InputDecimal, NormalButton, NormalButton2 } from "../../../../../../ui";

export const RegistroFase7DetalleDesmembracion = ({ tab, setTab, formulario, setShow }) => {

  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
  const { crearRegistro, obtenerTotalRegistroPropiedad } = useRegistroPropiedadSlice();
  const { id } = useParams();
  let watchItems = watch();

  const almacenarDatosFormulario = (data) => {
    let formularioFinal = { ...formulario, ...data }
    // setFormulario({ ...formulario, ...data });
    crearRegistro(decryptId(id), formularioFinal, setShow, () => obtenerTotalRegistroPropiedad(decryptId(id)));
  }

  useEffect(() => {
    let areaOriginal = Number.parseFloat(watchItems.area_original);
    let auxArea = 0;
    if (formulario.desmembracion.length <= 0) {
      setValue('total_desmembracion', auxArea)
    } else {
      for (let i = 0; i < formulario.desmembracion.length; i++) {
        auxArea = auxArea + Number.parseFloat(formulario.desmembracion[i].area_desmembracion);
      }
      setValue('total_desmembracion', auxArea.toFixed(2));
    }
    if (isNaN(areaOriginal)) {
      setValue('area_registral_actual', 0)
    } else {
      setValue('area_registral_actual', (areaOriginal - auxArea).toFixed(2))
    }
    // eslint-disable-next-line
  }, [watchItems.area_original])


  return (
    <form onSubmit={handleSubmit((data) => questionModal('Crear', 'Desea crear este registro', () => almacenarDatosFormulario(data)))}>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="mb-2 mt-6 w-full px-3 ">
          <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Área actual</h1>
        </div>
        <InputDecimal nameLabel='Área original (1ra de dominio)' register={register} name="area_original" message="El área es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. 16,787.04" />
        <InputDecimal nameLabel='Total, desmembraciones' register={register} name="total_desmembracion" message="El total es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 510" disabled={true} />
        <InputDecimal nameLabel='Área registral actual' register={register} name="area_registral_actual" message="El área es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. 16,787.04" disabled={true} />
        <div className="w-full mx-3 mt-5 text-center flex justify-end">
          <NormalButton funcion={() => setTab(tab - 1)} bgColor='bg-red-700' bgText='text-white font-semibold' hoverColor='hover:bg-red-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title={
            <div className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
              Anterior
            </div>
          } />

          <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title='Enviar' />
        </div>
      </div>
    </form>
  )
}
