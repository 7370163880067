export * from './NotFound';
export * from './Loader';
export * from './Navbar';
export * from './Input';
export * from './Button';
export * from './Pagination';
export * from './Footer';
export * from './TransitionPopover';
export * from './Lash';
export * from './CircleTabs'
export * from './TransitionEdition';
export * from './Modal';
export * from './ModalCreate';
export * from './ModalGenericoCrear';
export * from './Autorization';
export * from './SwitchComponent';
export * from './TotalPagesAndData';
export * from './ModalEditNumberGravamen';