import { useForm } from "react-hook-form";
import { useAnexo } from "../../../../hooks";
import { InputImage, InputText, NormalButton2, SelectOption } from "../../../../ui";
import { decryptId, questionModal } from "../../../../utils";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const EditarAnexo = ({ setShow2 }) => {

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [fileList, setFileList] = useState([]);
  const { editarAnexoIndividual, anexoIndividual, imagenes, eliminarImagenIndividual } = useAnexo();
  const { id } = useParams();

  useEffect(() => {
    setValue('descripcion', anexoIndividual.descripcion);
    setValue('dimension', anexoIndividual.dimension);
    // eslint-disable-next-line
  }, [])



  return (
    <div className='flex justify-center mt-4'>
      <div className='w-full max-w-7xl bg-gray-50 rounded-lg shadow-2xl p-6'>
        <div className=" mx-auto p-4">
          <form onSubmit={handleSubmit((data) => questionModal('Editar', '¿Desea guardar el registro?', () => editarAnexoIndividual(data, setShow2, decryptId(id), fileList, setFileList, anexoIndividual.id_anexo)))}>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="my-2 w-full px-3 ">
                <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">EDITAR ANEXO</h1>
              </div>
              <InputText nameLabel='Descripción' register={register} name="descripcion" message="El dato es requerido" errors={errors} Placeholder="Ej. construcción 1" />
              <SelectOption nameLabel='Dimensión de las imágenes' register={register} name='dimension' message='El valor es requerido' errors={errors} array={dimensiones} valueOption='dimension' nameOption='dimension' position="md:w-1/2" />
              <InputImage fileList={fileList} setFileList={setFileList} />
              <div className="w-full mx-3 mt-1 text-center flex justify-end">
                <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title='Guardar' />
              </div>
            </div>
          </form>

          <div className='w-full pr-2 mt-2'>
            <div className="my-2 w-full px-3 ">
              <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">IMÁGENES GUARDADAS</h1>
            </div>
            <div className='flex flex-wrap place-content-center w-full'>
              {
                imagenes ?
                  imagenes.map((item) =>
                    <div key={item.id_imagen} className='relative w-48 pt-5 pr-5 m-1 cursor-auto z-10'>
                      <img src={item.url} className="h-44 w-44 border-2 border-black shadow-xl animate__animated animate__bounce" alt='Cargando...' />
                      <button className='h-7 w-7 bg-red-500 text-white rounded-full absolute top-0 right-0 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-red-700 duration-300'
                        onClick={() => questionModal('Eliminar', 'Desea eliminar la imagen?', () => eliminarImagenIndividual(item.id_imagen, anexoIndividual.id_anexo))}>
                        X
                      </button>
                    </div>
                  ) :
                  <p className="mt-2 text-gray-500 tracking-wide">Sin Imagenes</p>
              }
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

const dimensiones = [
  {dimension: '1/1'},
  {dimension: '1/2'},
  {dimension: '1/4'},
]