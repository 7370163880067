import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { errorModal, questionModal } from "../../../../../../utils";
import { InputText, NormalButton, NormalButton2 } from "../../../../../../ui";

export const RegistroFase4Limitacion = ({ tab, setTab, formulario, setFormulario }) => {
  const [contador, setContador] = useState(1);
  const [limitacion, setLimitacion] = useState([]);
  const { register, handleSubmit, formState: { errors }, unregister, setValue } = useForm();

  useEffect(() => {
    if (formulario.limitacion) {
      if (formulario.limitacion.length > 0) {
        let arregloAux = [];
        for (let i = 0; i < formulario.limitacion.length; i++) {
          arregloAux.push({ numero: i + 1 });
          setValue(`descripcion${i + 1}`, formulario.limitacion[i].descripcion)
        }
        setLimitacion(arregloAux);
        setContador(formulario.limitacion.length + 1);
      }
    }
    // eslint-disable-next-line
  }, [])

  const agregarLimitacion = () => {
    setLimitacion([...limitacion, { numero: contador }]);
    setContador(contador + 1);

  }

  const quitarLimitacion = (item, index) => {
    if (item.numero === (contador - 1)) {
      let auxLimitacion = [...limitacion];
      auxLimitacion.splice(index, 1);
      setLimitacion(auxLimitacion);
      unregister(`descripcion${item.numero}`);
      setContador(contador - 1)
    } else {
      errorModal('existen limitaciones posteriores a esta');
    }
  }

  const almacenarDatosFormulario = (data) => {
    let limitacion = [];
    for (let i = 1; i < contador; i++) {
      limitacion.push({
        numero_limitacion: i,
        descripcion: data[`descripcion${i}`]
      })
    }
    setFormulario({ ...formulario, limitacion });
    setTab(tab + 1);
  }

  return (
    <form onSubmit={handleSubmit((data) => almacenarDatosFormulario(data))}>
      <div className="flex flex-wrap">
        <div className='w-full pt-5 mb-4 border-b-8 border-gray-300 rounded-md flex items-center justify-center'>
          <label className=' text-center block text-2xl font-bold text-gray-400'>
            Limitaciones
          </label>
          <button type='button' className='h-6 w-6 text-green-600 hover:-translate-y-1 hover:scale-110 ease-in-out delay-150 duration-100' onClick={agregarLimitacion}>
            <PlusCircleIcon />
          </button>
        </div>
        {
          limitacion.map((item, index) =>
            <div key={item.numero} className='my-4 px-1 pb-4 w-full  flex flex-wrap' >

              <div className='w-full bg-gray-400 rounded-t-xl flex items-center justify-center'>
                <label className='text-center block text-xl font-bold text-white'>
                  Limitación No. {item.numero}
                </label>
                <button type='button' className='h-6 w-6 ml-5 text-red-600 hover:-translate-y-1 hover:scale-110 ease-in-out delay-150 duration-100' onClick={() => questionModal('Eliminar', 'Esta Seguro de Eliminar', () => { quitarLimitacion(item, index) })}>
                  <XCircleIcon />
                </button>
              </div>

              <div className='w-full md:flex border-x-4 border-b-4 border-gray-400 rounded-b-xl shadow-lg p-2'>
                <InputText nameLabel='descripción' register={register} name={`descripcion${item.numero}`} message="La descripción es requerida" errors={errors} Placeholder="Ej. acceso complejo" />
              </div>
            </div>
          )
        }

        <div className="w-full mx-3 mt-5 text-center flex justify-end">
          <NormalButton funcion={() => setTab(tab - 1)} bgColor='bg-red-700' bgText='text-white font-semibold' hoverColor='hover:bg-red-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title={
            <div className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
              Anterior
            </div>
          } />

          <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title={
            <div className="flex">
              Siguiente
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </div>
          } />
        </div>
      </div>
    </form>
  )
}
