import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { infraestructuraCredentials, infraestructuraIndividualCredentials, isLoading, loaded } from "./infraestructuraSlice";



export const obtenerInfraestructuras = (id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/infraestructura', { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(infraestructuraCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const obtenerInfraestructura = (id_infraestructura, setShow2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/infraestructura/individual', { headers: { 'token': getToken() }, params: { id_infraestructura } });
            dispatch(infraestructuraIndividualCredentials(data));
            setShow2(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const eliminarInfraestructura = (id_infraestructura, id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/infraestructura/eliminar', { headers: { 'token': getToken() }, params: { id_infraestructura } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerInfraestructuras(id_avaluo));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const crearInfraestructura = (id_avaluo, data, setShow) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/infraestructura/crear', data, { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            dispatch(obtenerInfraestructuras(id_avaluo));
            setShow(false);
        } catch (error) {
            dispatch(loaded())
            interceptorResponse(error);
            errorModal('error al hacer el guardado');
        }
    }
}

export const editarInfraestructura = (id_infraestructura, data, id_avaluo, setShow2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/infraestructura/editar', data, { headers: { 'token': getToken() }, params: { id_infraestructura } });
            dispatch(loaded())
            successModal('Registro editado correctamente', 'center');
            dispatch(obtenerInfraestructuras(id_avaluo));
            setShow2(false);
        } catch (error) {
            dispatch(loaded())
            interceptorResponse(error);
            errorModal('error al hacer la edición');
        }
    }
}