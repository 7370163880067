import { useDispatch, useSelector } from "react-redux";
import { crearObservacion, editarObservacion, eliminarObservacion, obtenerObservacion, obtenerObservaciones } from "../store/slice/observacion/thunks";

export const useObservacion = () => {

    const dispatch = useDispatch();
    const { observaciones, loading, observacion } = useSelector((state) => state.observacion);

    const obtenerInfraestructurasCompletas = (id_avaluo) => {
        dispatch(obtenerObservaciones(id_avaluo));
    }

    const obtenerObservacionIndividual = (id_observacion, setShow2) => {
        dispatch(obtenerObservacion(id_observacion, setShow2));
    }

    const eliminarObservacionIndividual = (id_observacion, id_avaluo) => {
        dispatch(eliminarObservacion(id_observacion, id_avaluo));
    }

    const crearObservacionIndividual = (id_avaluo, data, setShow) => {
        dispatch(crearObservacion(id_avaluo, data, setShow));
    }

    const editarObservacionIndividual = (id_observacion, id_avaluo, data, setShow2) => {
        dispatch(editarObservacion(id_observacion, id_avaluo, data, setShow2));
    }

    return {
        //valores
        observaciones,
        loading,
        observacion,

        //funciones
        obtenerInfraestructurasCompletas,
        obtenerObservacionIndividual,
        eliminarObservacionIndividual,
        crearObservacionIndividual,
        editarObservacionIndividual
    }
}