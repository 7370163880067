import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAgencia, useAvaluoSlice, useUser } from "../../../hooks";
import { InputDate2, InputSearch2, Loader, Pagination, SelectOption4, TotalPagesAndData, TransitionPopover } from "../../../ui";
import { TdTable, ThTable, TrTable } from "../components";
import { InformeAvaluo } from "./InformeAvaluo";



export const ConsultaAvaluo = () => {

    const { register, watch } = useForm();
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const { avaluos, totalPaginas, obtenerTotalAvaluos, loading, avaluoInforme, cantidad } = useAvaluoSlice();
    const { valuadores, asesores, obtenerTotalValuadores, obtenerTotalAsesores } = useUser();
    const { agencia, obtenerTotalAgencias } = useAgencia();

    let watchItems = watch();

    useEffect(() => {
        obtenerTotalValuadores()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        obtenerTotalAvaluos(watchItems.buscador, page, 3, watchItems.valuador, "", watchItems.id_agencia, watchItems.id_usuario, watchItems.fechaA, watchItems.fechaB);
        // eslint-disable-next-line
    }, [watchItems.buscador, page, watchItems.valuador, watchItems.id_agencia, watchItems.id_usuario, watchItems.fechaA, watchItems.fechaB]);

    useEffect(() => {
        setPage(1);
    }, [watchItems.buscador, watchItems.valuador, watchItems.id_agencia, watchItems.id_usuario, watchItems.fechaA, watchItems.fechaB]);

    useEffect(() => {
        obtenerTotalAgencias();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (watchItems.id_agencia === undefined || watchItems.id_agencia === '') {
            obtenerTotalAsesores('');
        } else {
            obtenerTotalAsesores(watchItems.id_agencia);
        }
        // eslint-disable-next-line
    }, [watchItems.id_agencia])

    const visualizarInforme = (id_avaluo) => {
        avaluoInforme(id_avaluo, setShow);
    }


    return (
        <>
            {loading && <Loader />}
            <div className='mx-20 my-14'>
                <div className='my-5 text-gray-400 font-bold text-4xl'>
                    <h1>
                        AVALÚOS AUTORIZADOS
                        <hr className='w-1/3' />
                    </h1>

                </div>
                <div className='flex lg:flex-nowrap lg:justify-start flex-wrap'>
                    <InputSearch2 nameLabel='buscador' register={register} name="buscador" position='lg:w-1/3 mb-3 font-semibold text-gray-500 mx-1' />
                    <SelectOption4 nameLabel='Valuador' register={register} name="valuador" array={valuadores} valueOption='id_usuario' nameOption='nombre' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                    <SelectOption4 nameLabel='agencia' register={register} name="id_agencia" array={agencia} valueOption='id_agencia' nameOption='agencia' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                    <SelectOption4 nameLabel='asesor' register={register} name="id_usuario" array={asesores} valueOption='id_usuario' nameOption='nombre' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                    <InputDate2 nameLabel='(Autorizados) de:' register={register} name='fechaA' position='lg:w-1/5' />
                    <InputDate2 nameLabel='(Autorizados) hasta:' register={register} name='fechaB' position='lg:w-1/5' />
                </div>
                <table className={"border-collapse w-full"}>
                    <thead>
                        <tr>
                            <ThTable titulo='Correlativo' />
                            <ThTable titulo='Solicitante' />
                            <ThTable titulo='CIF' />
                            <ThTable titulo='Ubicación Legal' />
                            <ThTable titulo='Agencia' />
                            <ThTable titulo='Asesor' />
                            <ThTable titulo='Valuador' />
                            <ThTable titulo='Solicitud' />
                            <ThTable titulo='Autorización' />
                            <ThTable titulo='Acciones' />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            avaluos.map((item, index) => (
                                <TrTable key={index}>
                                    <TdTable titulo='Correlativo' valor={item.solicitud_avaluo.correlativo} />
                                    <TdTable titulo='Solicitante' valor={item.solicitante} />
                                    <TdTable titulo='CIF' valor={item.solicitud_avaluo.cif_solicitante} />
                                    <TdTable titulo='Ubicación Legal' valor={item.ubicacion_legal} />
                                    <TdTable titulo='Agencia' valor={item.solicitud_avaluo.agencium.agencia} />
                                    <TdTable titulo='Asesor' valor={item.solicitud_avaluo.usuario.nombre} />
                                    <TdTable titulo='Valuador' valor={item.usuario.nombre} />
                                    <TdTable titulo='Solicitud' valor={item.solicitud_avaluo.fecha_ingreso_solicitud} />
                                    <TdTable titulo='Autorización' valor={item.fecha_autorizado} />
                                    <TdTable valor={
                                        <div className='flex justify-center mx-2'>
                                            <div className="w-4 mr-3 transform hover:text-green-500 hover:scale-110 ">
                                                <svg onClick={() => visualizarInforme(item.id_avaluo)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </div>
                                        </div>


                                    } />
                                </TrTable>
                            ))
                        }
                    </tbody>
                </table>
                <div className="flex justify-end mt-3">
                    <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
                </div>
                <div className="flex justify-end mt-3">
                    <TotalPagesAndData amount={cantidad} page={page} totPages={totalPaginas} />
                </div>
                <TransitionPopover show={show} setShow={setShow}>
                    <InformeAvaluo setShow={setShow} />
                </TransitionPopover>
            </div>
        </>
    )
}
