import { useForm } from "react-hook-form";
import { useSolicitud } from "../../../../hooks";
import { InputDate, InputDecimal, InputNumber, InputPDF, InputText, SelectOption, SelectOption5 } from "../../../../ui";
import { useEffect, useState } from "react";
import { departamentos, errorModal, questionModal } from "../../../../utils";
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/outline'


let creditos = [{ credito: 'Crédito Nuevo' }, { credito: 'Recredito' }]
let inmueble = [{ tipo_inmueble: 'Finca registrada' }, { tipo_inmueble: 'Derechos de posesión' }]
export const CrearSolicitudAvaluo = ({ setShow, setValue2 }) => {

    const { register, handleSubmit, formState: { errors }, watch, unregister, setValue } = useForm();
    const { obtenerTotalSolicitudes, crearNuevaSolicitud } = useSolicitud();
    const [fileList, setFileList] = useState([]);
    const [cambio, setCambio] = useState(true);
    let watchItem = watch();

    const [contador, setContador] = useState(2);
    const [datos, setDatos] = useState([{ numero: 1 }]);

    useEffect(() => {
        if (watchItem.tipo_inmueble === 'Finca registrada') {
            for (let i = 0; i < datos.length; i++) {
                unregister(`numero_escritura${i + 1}`);
                unregister(`fecha_escrituracion${i + 1}`);
                unregister(`nombre_notario${i + 1}`);
                unregister(`lugar_inmueble${i + 1}`);
            }
            setCambio(true);
            setValue(`finca${1}`, '');
            setValue(`folio${1}`, '');
            setValue(`libro${1}`, '');
            setValue(`departamento${1}`, ''); // nuevo
            setValue(`lugar_inmueble${1}`, '');
        } else {
            for (let i = 0; i < datos.length; i++) {
                unregister(`finca${i + 1}`);
                unregister(`folio${i + 1}`);
                unregister(`libro${i + 1}`);
                unregister(`departamento${i + 1}`); //nuevo
                unregister(`lugar_inmueble${i + 1}`);
            }
            setCambio(false);
            setValue(`numero_escritura${1}`, '');
            setValue(`fecha_escrituracion${1}`, '');
            setValue(`nombre_notario${1}`, '');
            setValue(`lugar_inmueble${1}`, '');
        }
        setContador(2);
        setDatos([{ numero: 1 }]);
        // eslint-disable-next-line
    }, [watchItem.tipo_inmueble])

    const agregarDatos = () => {
        setDatos([...datos, { numero: contador }]);
        setContador(contador + 1);
    }

    const quitarDatos = (item, index) => {
        if (item.numero === (contador - 1)) {
            let auxDatos = [...datos]
            auxDatos.splice(index, 1)
            setDatos(auxDatos)

            if (cambio) {
                unregister(`finca${item.numero}`);
                unregister(`folio${item.numero}`);
                unregister(`libro${item.numero}`);
                unregister(`departamento${item.numero}`); //nuevo
            } else {
                unregister(`numero_escritura${item.numero}`);
                unregister(`fecha_escrituracion${item.numero}`);
                unregister(`nombre_notario${item.numero}`);
            }
            unregister(`lugar_inmueble${item.numero}`);
            setContador(contador - 1)
        } else {
            errorModal('existen registros posteriores a este');
        }
    }


    return (
        <>
            <div className='flex justify-center'>
                <div className="w-full max-w-4xl bg-white rounded-xl shadow-2xl px-6 py-3 mb-5 mt-10">
                    <div className='mb-6 text-gray-400 font-bold sm:text-2xl text-xl'>
                        <h1>
                            CREAR SOLICITUD DE AVALÚO
                            <hr />
                        </h1>
                    </div>
                    <form onSubmit={handleSubmit((data) => crearNuevaSolicitud(data, setShow, setValue2, obtenerTotalSolicitudes, watchItem, fileList, setFileList, contador))}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <SelectOption5 nameLabel='Tipo de inmueble' register={register} name='tipo_inmueble' message='El valor es requerido' errors={errors} array={inmueble} valueOption='tipo_inmueble' nameOption='tipo_inmueble' position="md:w-1/3" />
                            <InputText nameLabel='Solicitante' register={register} name="solicitante" message="El solicitante es requerido" errors={errors} Placeholder="Ej. Pedro González Hernandez" />
                            <InputNumber nameLabel='CIF del solicitante' register={register} name="cif_solicitante" message="El cif es requerido" errors={errors} Placeholder="Ej. 90785" position="md:w-1/2" />
                            <InputText nameLabel='Teléfono del solicitante' register={register} name="telefono_solicitante" message="El telefono es requerido" errors={errors} Placeholder="Ej. 31769822" position="md:w-1/2" />
                            <InputText nameLabel='Nombre(s) de propietario(s)' register={register} name="propietario" message="El propietario es requerido" errors={errors} Placeholder="Ej. Juan Fernández, Fredy Ortiz" />

                            <div className='w-full bg-gray-400 rounded-md flex items-center justify-center mx-3 mb-3'>
                                <label className='text-center block text-sm font-bold text-white'>
                                    {
                                        cambio ?
                                            'Finca Folio Libro'
                                            :
                                            'No. escritura, fecha escritura, nombre notario'
                                    }
                                </label>
                                <button type='button' className='h-5 w-5 ml-2 text-green-700 hover:-translate-y-1 hover:scale-110 ease-in-out delay-150 duration-100' onClick={agregarDatos}>
                                    <PlusCircleIcon />
                                </button>
                            </div>
                            {
                                datos.map((item, index) =>
                                    cambio ?
                                        <div className="w-full flex flex-wrap border-2 rounded-xl pt-2 mb-5">
                                            <InputText nameLabel='finca' register={register} name={`finca${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. finca 15" position="md:w-1/3" />
                                            <InputText nameLabel='folio' register={register} name={`folio${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. folio 22" position="md:w-1/3" />
                                            <InputText nameLabel={
                                                <div className="flex justify-between">
                                                    Libro
                                                    {
                                                        index === 0 ? null :
                                                            <button type='button' className='h-6 w-6 ml-5 text-red-600 hover:-translate-y-1 hover:scale-110 ease-in-out delay-150 duration-100' onClick={() => questionModal('Eliminar', '¿Está seguro que desea eliminar el registro?', () => { quitarDatos(item, index) })}>
                                                                <XCircleIcon />
                                                            </button>
                                                    }
                                                </div>
                                            }
                                                register={register} name={`libro${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. libro 3E" position="md:w-1/3" />
                                            <SelectOption nameLabel='departamento' register={register} name={`departamento${item.numero}`} message='El valor es requerido' errors={errors} array={departamentos} valueOption='departamento' nameOption='departamento' position="md:w-1/3" />
                                            <InputText nameLabel={`Lugar del inmueble ${item.numero}`} register={register} name={`lugar_inmueble${item.numero}`} message="El lugar es requerido" errors={errors} Placeholder="Ej. BARRIO SAN MARCOS ZONA 4 COBAN AV" />
                                        </div>
                                        :
                                        <div className="w-full flex flex-wrap border-2 rounded-xl pt-2 mb-5">
                                            <InputText nameLabel='No. de escritura' register={register} name={`numero_escritura${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. 558874" position="md:w-1/3" />
                                            <InputDate nameLabel='Fecha de la escrituración' register={register} name={`fecha_escrituracion${item.numero}`} message="La fecha es requerida" position="md:w-1/3" errors={errors} />
                                            <InputText nameLabel={
                                                <div className="flex justify-between">
                                                    Nombre del notario
                                                    {
                                                        index === 0 ? null :
                                                            <button type='button' className='h-6 w-6 ml-5 text-red-600 hover:-translate-y-1 hover:scale-110 ease-in-out delay-150 duration-100' onClick={() => questionModal('Eliminar', '¿Está seguro que desea eliminar el registro?', () => { quitarDatos(item, index) })}>
                                                                <XCircleIcon />
                                                            </button>
                                                    }
                                                </div>
                                            }
                                                register={register} name={`nombre_notario${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. Lic. Fausto Hernández" position="md:w-1/3" />
                                            <InputText nameLabel={`Lugar del inmueble ${item.numero}`} register={register} name={`lugar_inmueble${item.numero}`} message="El lugar es requerido" errors={errors} Placeholder="Ej. BARRIO SAN MARCOS ZONA 4 COBAN AV" />
                                        </div>
                                )
                            }

                            <InputText nameLabel='Número de boleta' register={register} name="numero_boleta_pago" message="El número es requerido" errors={errors} Placeholder="Ej. 2125862" position="md:w-1/2" />
                            <InputDate nameLabel='Fecha de la boleta de pago' register={register} name="fecha_pago_avaluo" message="La fecha es requerida" position="md:w-1/2" errors={errors} />
                            <InputDecimal nameLabel='Monto del pago' register={register} name="cantidad_pago_avaluo" message="El monto es requerido" errors={errors} Placeholder="Ej. 125" position="md:w-1/2" />
                            <SelectOption nameLabel='Tipo de crédito' register={register} name='tipo_credito' message='El valor es requerido' errors={errors} array={creditos} valueOption='credito' nameOption='credito' position="md:w-1/2" />
                            <InputPDF fileList={fileList} setFileList={setFileList} nameLabel={cambio ? 'DPI, CONSULTA ELECTRÓNICA, PLANO Y BOLETAS DE PAGO' : "DPI, CONSTANCIA DE CATASTRO/DECLARACIÓN JURADA, ESCRITURAS Y PLANO"} />

                            <div className="w-full md:w-full px-3">
                                <div className="text-center flex">
                                    <input
                                        className="w-full mr-1 px-3 py-2 font-bold text-white bg-blue-900 rounded-md cursor-pointer hover:bg-blue-800 focus:outline-none focus:shadow-outline"
                                        type="submit"
                                        value='Crear'
                                    />
                                    <button type="button" onClick={() => setShow(false)} className="font-bold text-white w-full px-4 py-2 bg-red-700 rounded-md cursor-pointer hover:bg-red-600 focus:outline-none focus:shadow-outline">
                                        Cancelar
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}
