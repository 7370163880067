import { createSlice } from '@reduxjs/toolkit';

export const valorInmuebleSlice = createSlice({
    name: 'valorInmueble',
    initialState: {
        valorInmueble: [],
        descripcionReferencial: [],
        descripcionReferencialIndividual: {},
        valorInmuebleIndividual: {},
        loading: false,
    },
    reducers: {
        valorInmuebleCredentials: (state, { payload }) => {
            state.descripcionReferencial = payload.descripcionReferencial;
            state.valorInmueble = payload.valorInmueble;
            state.loading = false;
        },
        descripcionReferencialCredentials: (state, { payload }) => {
            state.descripcionReferencialIndividual = payload.descripcionReferencialIndividual;
            state.loading = false;
        },
        valorInmuebleIndividualCredentials: (state, { payload }) => {
            state.valorInmuebleIndividual = payload.valorInmueble;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});


// Action creators are generated for each case reducer function
export const { valorInmuebleCredentials, isLoading, loaded, descripcionReferencialCredentials, valorInmuebleIndividualCredentials } = valorInmuebleSlice.actions;