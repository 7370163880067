import { createSlice } from '@reduxjs/toolkit';

export const observacionSlice = createSlice({
    name: 'observacion',
    initialState: {
        observaciones: [],
        loading: false,
        observacion: {}
    },
    reducers: {
        observacionCredentials: (state, { payload }) => {
            state.observaciones = payload.observaciones;
            state.loading = false;
        },
        observacionIndividualCredentials: (state, { payload }) => {
            state.observacion = payload.observacion;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});


// Action creators are generated for each case reducer function
export const { observacionCredentials, isLoading, loaded, observacionIndividualCredentials } = observacionSlice.actions;