import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDetalleInmuebleSlice } from "../../../../hooks";
import { decryptId, questionModal, successModal } from "../../../../utils";
import { InputDecimal, InputText, Loader, NormalButton, NormalButton2, SelectOption } from "../../../../ui";
import { useForm } from "react-hook-form";

let formaFisica = [{ forma: 'forma regular' }, { forma: 'forma irregular' }, { forma: 'forma muy irregular' }, { forma: 'forma triangular' }, { forma: 'forma triángulo nabla' }, { forma: 'forma triángulo delta' }];
let topografia = [{ topografia: 'plana' }, { topografia: 'inclinada' }, { topografia: 'semi-inclinada' }, { topografia: 'ondulada' }, { topografia: 'escarpada' }]
let servicios = [{ opcion: 'Sí' }, { opcion: 'No' }];

export const DetalleInmueble = () => {

    const { id } = useParams();
    const [edicion, setEdicion] = useState(false);
    const [crearEditar, setCrearEditar] = useState('Crear');
    const { area_legal, loading, detalle_inmueble, obtenerDetalleInmueble, crearEditarDetalleInmueble } = useDetalleInmuebleSlice();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    let watchItems = watch();

    useEffect(() => {
        obtenerDetalleInmueble(decryptId(id), setValue, setCrearEditar, setEdicion);
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        setValue('area_legal', area_legal);
        setValue('area_legal2', area_legal);
        setValue('area_fisica', watchItems.area_campo);
        if (watchItems.area_campo === undefined) {
            setValue('diferencia', 0);
            setValue('porcentaje', 0);
        } else {
            let areaFisica = Number.parseFloat(watchItems.area_campo);
            let diferencia = 0;

            if (areaFisica > Number.parseFloat(area_legal)) {
                diferencia = areaFisica - Number.parseFloat(area_legal);
            } else if (areaFisica < Number.parseFloat(area_legal)) {
                diferencia = Number.parseFloat(area_legal) - areaFisica;
            }

            let porcentaje = (diferencia * 100) / Number.parseFloat(area_legal);

            setValue('diferencia', diferencia.toFixed(2));
            setValue('porcentaje', porcentaje.toFixed(2));
        }
        // eslint-disable-next-line
    }, [area_legal, watchItems.area_campo])

    const activarEdicion = () => {
        if (edicion) {
            successModal('Edicion desactivada', 'center', 'info');
            setEdicion(false);
        } else {
            successModal('Edicion activada', 'center', 'warning');
            setEdicion(true);
        }
    }

    return (
        <>
            {loading && <Loader />}
            <div className=" mx-12 p-2">
                <form onSubmit={handleSubmit((data) => questionModal(crearEditar === 'Crear' ? "¡Crear!" : "¡Editar!", "¿Desea guardar el detalle del inmueble", () => crearEditarDetalleInmueble(decryptId(id), data, crearEditar, setEdicion, setValue, setCrearEditar)))}>

                    {
                        detalle_inmueble.length === 0
                            ?
                            <div className="w-full mx-3 text-center flex justify-end">
                                <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title='Guardar' />
                            </div>
                            :
                            <div className="w-full mx-3 mt-5 text-center flex justify-end">
                                <NormalButton funcion={() => questionModal(edicion ? 'Desactivar edición' : 'Activar edición', edicion ? '¿Desea desactivar el modo de edición?' : '¿Desea activar el modo de edición?', activarEdicion)} bgColor={edicion ? 'bg-yellow-700' : 'bg-green-700'} bgText='text-white font-semibold' hoverColor={edicion ? 'hover:bg-yellow-800' : 'hover:bg-green-800'} dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title={edicion ? "Desactivar edición" : "Activar edición"} />
                                <NormalButton2 typeButton="submit" bgColor={edicion ? 'bg-blue-900  cursor-pointer' : 'bg-blue-300'} bgText='text-white font-semibold' hoverColor={edicion ? 'hover:bg-blue-800' : ''} dimension="mr-1 px-1 sm:px-6 py-2 rounded-md" title="Guardar" disabledButton={edicion ? false : true} />
                            </div>

                    }

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="my-2 w-full px-3 ">
                            <h1 className="text-xl font-bold  border-b-2 border-gray-300 text-gray-400">Descripción</h1>
                        </div>
                        <InputDecimal nameLabel='Área legal' register={register} name="area_legal" message="El área es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. 435.2" disabled={true} />
                        <InputDecimal nameLabel='Área según plano' register={register} name="area_plano" message="El área es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. 436.4" disabled={edicion ? false : true} />
                        <InputDecimal nameLabel='Área verificada en campo' register={register} name="area_campo" message="El área es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. 436.4" disabled={edicion ? false : true} />

                        <div className="my-2 w-full px-3 ">
                            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-300 text-gray-400">Comparación de áreas</h1>
                        </div>
                        <InputDecimal nameLabel='Área legal' register={register} name="area_legal2" message="El área es requerida" position="md:w-1/4" errors={errors} Placeholder="Ej. 435.2" disabled={true} />
                        <InputDecimal nameLabel='Área física' register={register} name="area_fisica" message="El área es requerida" position="md:w-1/4" errors={errors} Placeholder="Ej. 436.4" disabled={true} />
                        <InputDecimal nameLabel='Diferencia' register={register} name="diferencia" message="El área es requerida" position="md:w-1/4" errors={errors} Placeholder="Ej. 2.2" disabled={true} />
                        <InputDecimal nameLabel='%' register={register} name="porcentaje" message="El porcentaje es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 1.56" disabled={true} />

                        <div className="my-2 w-full px-3 ">
                            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-300 text-gray-400">Características físicas</h1>
                        </div>
                        <SelectOption nameLabel='Forma' register={register} name='forma' message='La forma es requerida' position="md:w-1/3" errors={errors} array={formaFisica} valueOption='forma' nameOption='forma' disabled={edicion ? false : true} />
                        <InputText nameLabel='Uso principal' register={register} name="uso_principal" message="El uso es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. ganadería" disabled={edicion ? false : true} />
                        <SelectOption nameLabel='Topografía' register={register} name='topografia' message='La topografía es requerida' position="md:w-1/3" errors={errors} array={topografia} valueOption='topografia' nameOption='topografia' disabled={edicion ? false : true} />

                        <div className="my-2 w-full px-3 ">
                            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-300 text-gray-400">Servicios públicos instalados</h1>
                        </div>
                        <SelectOption nameLabel='Servicio agua entubada' register={register} name='servicio_agua_entubada' message='El dato es requerido' position="md:w-1/3" errors={errors} array={servicios} valueOption='opcion' nameOption='opcion' disabled={edicion ? false : true} />
                        <SelectOption nameLabel='Energía eléctrica' register={register} name='energia_electrica' message='El dato es requerido' position="md:w-1/3" errors={errors} array={servicios} valueOption='opcion' nameOption='opcion' disabled={edicion ? false : true} />
                        <SelectOption nameLabel='Drenajes' register={register} name='drenaje' message='El dato es requerido' position="md:w-1/3" errors={errors} array={servicios} valueOption='opcion' nameOption='opcion' disabled={edicion ? false : true} />
                    </div>
                </form>
            </div>
        </>
    )
}
