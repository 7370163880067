import { apiRest } from "../../../api/apiRest";
import { errorModal, successModal } from "../../../utils";
import { getToken, interceptorResponse } from "../../../utils/manager";
import { anexoCredentials, anexoIndividualCredentials, imagenCredentials, isLoading, loaded } from "./anexoSlice";


export const obtenerAnexos = (id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/anexo', { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(anexoCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const obtenerAnexoIndividual = (id_anexo, setShow2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/anexo/individual', { headers: { 'token': getToken() }, params: { id_anexo } });
            dispatch(anexoIndividualCredentials(data));
            setShow2(true);
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
        }
    }
}

export const eliminarAnexo = (id_anexo, id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/anexo/eliminar', { headers: { 'token': getToken() }, params: { id_anexo } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerAnexos(id_avaluo));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const crearAnexo = (data, setShow, id_avaluo, fileList, setFileList) => {
    return async (dispatch) => {
        try {

            const f = new FormData();

            let titles = Object.keys(data);
            for (let i = 0; i < titles.length; i++) {
                let valueTitle = titles[i];
                f.append(titles[i], data[valueTitle]);
            }

            for (let i = 0; i < fileList.length; i++) {
                f.append('archivos', fileList[i].archivo);
            }


            dispatch(isLoading());
            await apiRest.post('/anexo/crear', f, { headers: { 'token': getToken() }, params: { id_avaluo }, 'Content-Type': 'multipart/form-data' });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            setFileList([]);
            dispatch(obtenerAnexos(id_avaluo));
            setShow(false);
        } catch (error) {
            dispatch(loaded())
            interceptorResponse(error);
            errorModal('error al hacer el guardado');
        }
    }
}

export const editarAnexo = (data, setShow2, id_avaluo, fileList, setFileList, id_anexo) => {
    return async (dispatch) => {
        try {

            const f = new FormData();

            let titles = Object.keys(data);
            for (let i = 0; i < titles.length; i++) {
                let valueTitle = titles[i];
                f.append(titles[i], data[valueTitle]);
            }

            for (let i = 0; i < fileList.length; i++) {
                f.append('archivos', fileList[i].archivo);
            }

            dispatch(isLoading());
            await apiRest.post('/anexo/editar', f, { headers: { 'token': getToken() }, params: { id_anexo }, 'Content-Type': 'multipart/form-data' });
            dispatch(loaded())
            successModal('Registro editado correctamente', 'center');
            setFileList([]);
            dispatch(obtenerAnexos(id_avaluo));
            setShow2(false);
        } catch (error) {
            dispatch(loaded())
            interceptorResponse(error);
            errorModal('error al hacer el guardado');
        }
    }
}

export const obtenerImagenes = (id_anexo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/imagen', { headers: { 'token': getToken() }, params: { id_anexo } });
            dispatch(imagenCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const eliminarImagen = (id_imagen, id_anexo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/imagen/eliminar', { headers: { 'token': getToken() }, params: { id_imagen } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerImagenes(id_anexo));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}