import { useDispatch, useSelector } from "react-redux";
import { crearEditarDetalleInmuebleNuevo, obtenerDetalleInmuebleCompleto, obtenerDetalleInmuebleIndividual } from "../store/slice/detalleInmueble";

export const useDetalleInmuebleSlice = () => {

    const dispatch = useDispatch();
    const { area_legal, loading, detalle_inmueble, detalle_inmueble_total } = useSelector((state) => state.detalleInmueble);


    const obtenerDetalleInmueble = (id_avaluo, setValue, setCrearEditar, setEdicion) => {
        dispatch(obtenerDetalleInmuebleCompleto(id_avaluo, setValue, setCrearEditar, setEdicion));
    }

    const crearEditarDetalleInmueble = (id_avaluo, data, crearEditar, setEdicion, setValue, setCrearEditar) => {
        dispatch(crearEditarDetalleInmuebleNuevo(id_avaluo, data, crearEditar, setEdicion, setValue, setCrearEditar));
    }

    const obtenerDetalleInmuebleIndividualTotal = (id_avaluo) => {
        dispatch(obtenerDetalleInmuebleIndividual(id_avaluo));
    }

    return {
        //valores
        area_legal,
        loading,
        detalle_inmueble,
        detalle_inmueble_total,

        //funciones
        crearEditarDetalleInmueble,
        obtenerDetalleInmueble,
        obtenerDetalleInmuebleIndividualTotal
    }
}