import { useState } from 'react'
import { RegistroFase1 } from './RegistroFase1';
import { RegistroFase2Gravamen } from './RegistroFase2Gravamen';
import { RegistroFase3Anotacion } from './RegistroFase3Anotacion';
import { RegistroFase4Limitacion } from './RegistroFase4Limitacion';
import { RegistroFase5Colindancia } from './RegistroFase5Colindancia';
import { RegistroFase6Desmembracion } from './RegistroFase6Desmembracion';
import { RegistroFase7DetalleDesmembracion } from './RegistroFase7DetalleDesmembracion';
import { CircleTab, CircleTabs } from '../../../../../../ui';

export const CrearRegistro = ({ setShow }) => {

    const [tab, setTab] = useState(0);
    const [formulario, setFormulario] = useState({});

    return (
        <div className='flex justify-center mt-4 animate__animated animate__backInLeft'>
            <div className='w-full max-w-7xl bg-gray-50 rounded-lg shadow-2xl p-6'>
                <CircleTabs tab={tab}>
                    <CircleTab tab={tab} valor={1} />
                    <CircleTab tab={tab} valor={2} />
                    <CircleTab tab={tab} valor={3} />
                    <CircleTab tab={tab} valor={4} />
                    <CircleTab tab={tab} valor={5} />
                    <CircleTab tab={tab} valor={6} />
                </CircleTabs>

                {
                    tab === 0 ? <RegistroFase1 tab={tab} setTab={setTab} formulario={formulario} setFormulario={setFormulario} /> :
                        tab === 1 ? <RegistroFase2Gravamen tab={tab} setTab={setTab} formulario={formulario} setFormulario={setFormulario} /> :
                            tab === 2 ? <RegistroFase3Anotacion tab={tab} setTab={setTab} formulario={formulario} setFormulario={setFormulario} /> :
                                tab === 3 ? <RegistroFase4Limitacion tab={tab} setTab={setTab} formulario={formulario} setFormulario={setFormulario} /> :
                                    tab === 4 ? <RegistroFase5Colindancia tab={tab} setTab={setTab} formulario={formulario} setFormulario={setFormulario} /> :
                                        tab === 5 ? <RegistroFase6Desmembracion tab={tab} setTab={setTab} formulario={formulario} setFormulario={setFormulario} /> :
                                            <RegistroFase7DetalleDesmembracion tab={tab} setTab={setTab} formulario={formulario} setShow={setShow} />
                }
            </div>
        </div>
    )
}
