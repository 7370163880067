import { createSlice } from '@reduxjs/toolkit';

export const plusvaliaMinusvaliaSlice = createSlice({
    name: 'plusvaliaMinusvalia',
    initialState: {
        plusvaliaMinusvalia: [],
        loading: false,
    },
    reducers: {
        plusvaliaMinusvaliaCredentials: (state, { payload }) => {
            state.plusvaliaMinusvalia = payload.plusvalias_minusvalias;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});


// Action creators are generated for each case reducer function
export const { plusvaliaMinusvaliaCredentials, isLoading, loaded } = plusvaliaMinusvaliaSlice.actions;