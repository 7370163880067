import React from 'react'
import { TdTable, ThTable, TrTable } from '../../components'
import { useValorInmueble } from '../../../../hooks';

export const DetalleReferenciales = ({ setShow }) => {

    const { descripcionReferencial} = useValorInmueble();
    const separator = (numb) => {
        if (numb) {
            var str = numb.toString().split(".");
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");
        } else {
            return 0.00
        }
    }

    return (
        <div className='mx-20 my-10'>
            <div className="my-2 w-full px-3 ">
                <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">DESCRIPCIONES REFERENCIALES</h1>
            </div>
            <table className={"border-collapse w-full"}>
                <thead>
                    <tr>
                        <ThTable titulo='Valor' color="bg-green-800" />
                        <ThTable titulo='Nombre' color="bg-green-800" />
                        <ThTable titulo='Teléfono' color="bg-green-800" />
                        <ThTable titulo='Dimensional' color="bg-green-800" />
                        <ThTable titulo='Referencia' color="bg-green-800" />
                    </tr>
                </thead>
                <tbody>
                    {
                        descripcionReferencial.map((item, index) => (
                            <TrTable key={index}>
                                <TdTable titulo='Valor' valor={`Q. ${separator(item.valor)}`} />
                                <TdTable titulo='Nombre' valor={item.nombre === null || item.nombre === '' ? 'SIN REGISTROS' : item.nombre} />
                                <TdTable titulo='Teléfono' valor={item.telefono === null || item.telefono === '' ? 'SIN REGISTROS' : item.telefono} />
                                <TdTable titulo='Dimensional' valor={item.dimensional} />
                                <TdTable titulo='Referencia' valor={item.referencia} />
                            </TrTable>
                        ))
                    }
                </tbody>
            </table>
            {/* <TransitionPopover show={show2} setShow={setShow2}>
                <EditarDescripcionReferencial setShow2={setShow2} />
            </TransitionPopover> */}
        </div >
    )
}
