import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { comentariosSolicitudCredentials, correlativosSolicitudCredentials, isLoading, limpiarSolicitudIndividualCredentials, loaded, modificarValorCredentials, solicitudCredentials, solicitudIndividualCredentials } from "./solicitudSlice";


export const obtenerSolicitudes = (busqueda = "", limite = "", pagina, id_usuario = "", id_agencia = "", fechaA = "", fechaB = "", asignado) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/solicitud', { headers: { 'token': getToken() }, params: { busqueda, limite, pagina, id_usuario, id_agencia, fechaA, fechaB, asignado } });
            dispatch(solicitudCredentials(data));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
            // errorModal('Error al obtener las solicitudes');
        }
    }
}

export const crearSolicitudes = (data, setShow, setValue2, funcion, watchItem, fileList, setFileList, contador) => {
    return async (dispatch) => {
        try {
            let finca_folio_libro = '';
            let numero_fecha_nombre_escritura = '';
            let lugar_inmueble = '';
            let data1 = {};

            if (data.tipo_inmueble === 'Finca registrada') {
                for (let i = 1; i < contador; i++) {
                    if (i === contador - 1) {
                        finca_folio_libro += data[`finca${i}`] + " " + data[`folio${i}`] + " " + data[`libro${i}`] + " " + data[`departamento${i}`]
                        lugar_inmueble += data[`lugar_inmueble${i}`]
                    } else {
                        finca_folio_libro += data[`finca${i}`] + " " + data[`folio${i}`] + " " + data[`libro${i}`] + " " + data[`departamento${i}`] + ","
                        lugar_inmueble += data[`lugar_inmueble${i}`] + ","
                    }
                }

                data1 = {
                    tipo_inmueble: data.tipo_inmueble,
                    solicitante: data.solicitante,
                    cif_solicitante: data.cif_solicitante,
                    telefono_solicitante: data.telefono_solicitante,
                    propietario: data.propietario,
                    numero_boleta_pago: data.numero_boleta_pago,
                    fecha_pago_avaluo: data.fecha_pago_avaluo,
                    cantidad_pago_avaluo: data.cantidad_pago_avaluo,
                    tipo_credito: data.tipo_credito,
                    finca_folio_libro,
                    lugar_inmueble
                }

            } else {
                for (let i = 1; i < contador; i++) {
                    if (i === contador - 1) {
                        numero_fecha_nombre_escritura += data[`numero_escritura${i}`] + " " + data[`fecha_escrituracion${i}`] + " " + data[`nombre_notario${i}`]
                        lugar_inmueble += data[`lugar_inmueble${i}`]
                    } else {
                        numero_fecha_nombre_escritura += data[`numero_escritura${i}`] + " " + data[`fecha_escrituracion${i}`] + " " + data[`nombre_notario${i}`] + ","
                        lugar_inmueble += data[`lugar_inmueble${i}`] + ","
                    }
                }

                data1 = {
                    tipo_inmueble: data.tipo_inmueble,
                    solicitante: data.solicitante,
                    cif_solicitante: data.cif_solicitante,
                    telefono_solicitante: data.telefono_solicitante,
                    propietario: data.propietario,
                    numero_boleta_pago: data.numero_boleta_pago,
                    fecha_pago_avaluo: data.fecha_pago_avaluo,
                    cantidad_pago_avaluo: data.cantidad_pago_avaluo,
                    tipo_credito: data.tipo_credito,
                    numero_fecha_nombre_escritura,
                    lugar_inmueble
                }
            }

            const f = new FormData();

            let titles = Object.keys(data1);
            for (let i = 0; i < titles.length; i++) {
                let valueTitle = titles[i];
                f.append(titles[i], data1[valueTitle]);
            }

            for (let i = 0; i < fileList.length; i++) {
                f.append('archivos', fileList[i].archivo);
            }

            dispatch(isLoading());
            await apiRest.post('/solicitud/crear', f, { headers: { 'token': getToken() } });
            dispatch(loaded())
            funcion(watchItem.solicitante, "", 1, "", "", "", "", 0);
            setValue2('buscador', watchItem.solicitante);
            setFileList([]);
            successModal('Registro creado correctamente', 'center');
            setShow(false);
        } catch (error) {
            dispatch(loaded())
            errorModal('error al hacer el guardado');
            interceptorResponse(error);
        }
    }
}

export const editarSolicitud = (id_solicitud_avaluo, data, setShow3, setValue, funcion, watchItem, fileList, setFileList, contador, rol) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());

            let finca_folio_libro = '';
            let numero_fecha_nombre_escritura = '';
            let lugar_inmueble = '';
            let data1 = {};

            if (data.tipo_inmueble === 'Finca registrada') {
                for (let i = 1; i < contador; i++) {
                    if (i === contador - 1) {
                        finca_folio_libro += data[`finca${i}`] + " " + data[`folio${i}`] + " " + data[`libro${i}`] + " " + data[`departamento${i}`]
                        lugar_inmueble += data[`lugar_inmueble${i}`]
                    } else {
                        finca_folio_libro += data[`finca${i}`] + " " + data[`folio${i}`] + " " + data[`libro${i}`] + " " + data[`departamento${i}`] + ","
                        lugar_inmueble += data[`lugar_inmueble${i}`] + ","
                    }
                }

                data1 = {
                    tipo_inmueble: data.tipo_inmueble,
                    solicitante: data.solicitante,
                    cif_solicitante: data.cif_solicitante,
                    telefono_solicitante: data.telefono_solicitante,
                    propietario: data.propietario,
                    numero_boleta_pago: data.numero_boleta_pago,
                    fecha_pago_avaluo: data.fecha_pago_avaluo,
                    cantidad_pago_avaluo: data.cantidad_pago_avaluo,
                    tipo_credito: data.tipo_credito,
                    finca_folio_libro,
                    lugar_inmueble
                }

            } else {
                for (let i = 1; i < contador; i++) {
                    if (i === contador - 1) {
                        numero_fecha_nombre_escritura += data[`numero_escritura${i}`] + " " + data[`fecha_escrituracion${i}`] + " " + data[`nombre_notario${i}`]
                        lugar_inmueble += data[`lugar_inmueble${i}`]
                    } else {
                        numero_fecha_nombre_escritura += data[`numero_escritura${i}`] + " " + data[`fecha_escrituracion${i}`] + " " + data[`nombre_notario${i}`] + ","
                        lugar_inmueble += data[`lugar_inmueble${i}`] + ","
                    }
                }

                data1 = {
                    tipo_inmueble: data.tipo_inmueble,
                    solicitante: data.solicitante,
                    cif_solicitante: data.cif_solicitante,
                    telefono_solicitante: data.telefono_solicitante,
                    propietario: data.propietario,
                    numero_boleta_pago: data.numero_boleta_pago,
                    fecha_pago_avaluo: data.fecha_pago_avaluo,
                    cantidad_pago_avaluo: data.cantidad_pago_avaluo,
                    tipo_credito: data.tipo_credito,
                    numero_fecha_nombre_escritura,
                    lugar_inmueble
                }
            }

            if (rol === '4') {
                data1 = {
                    ...data1,
                    cantidad_avaluos: data.cantidad_avaluos,
                    id_valuador: data.id_valuador
                }
            }

            const f = new FormData();

            let titles = Object.keys(data1);
            for (let i = 0; i < titles.length; i++) {
                let valueTitle = titles[i];
                f.append(titles[i], data1[valueTitle]);
            }

            for (let i = 0; i < fileList.length; i++) {
                f.append('archivos', fileList[i].archivo);
            }

            await apiRest.put('/solicitud/editar', f, { headers: { 'token': getToken() }, params: { id_solicitud_avaluo } });
            dispatch(loaded())
            funcion(watchItem.solicitante, "", 1, "", "", "", "", 0);
            setValue('buscador', watchItem.solicitante);
            setFileList([]);
            successModal('Registro editado correctamente', 'center');
            setShow3(false);
        } catch (error) {
            dispatch(loaded())
            errorModal('error al hacer el guardado');
            interceptorResponse(error);
        }
    }
}

export const obtenerSolicitud = (id_solicitud_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/solicitud/individual', { headers: { 'token': getToken() }, params: { id_solicitud_avaluo } });
            dispatch(solicitudIndividualCredentials(data));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
        }
    }
}

export const eliminarDocumento = (id_imagen_solicitud_avaluo, id_solicitud_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/solicitud/eliminar/documento', { headers: { 'token': getToken() }, params: { id_imagen_solicitud_avaluo } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerSolicitud(id_solicitud_avaluo));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
        }
    }
}

export const limpiarSolicitudIndividual = () => {
    return async (dispatch) => {
        try {
            dispatch(limpiarSolicitudIndividualCredentials());
        } catch (error) {
            interceptorResponse(error);
        }
    }
}

export const rechazarSolicitud = (funcion, id_solicitud_avaluo, data, watchItems, setShow3, setValue2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/solicitud/rechazar', data, { headers: { 'token': getToken() }, params: { id_solicitud_avaluo } });
            dispatch(loaded());

            successModal('Registro rechazado correctamente', 'center');
            setShow3(false);
            funcion(watchItems.solicitante, "", 1, "", "", "", "", 2);
            setValue2('buscador', watchItems.solicitante);
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
        }
    }
}

export const responderComentario = (id_solicitud_avaluo, data) => {
    return async (dispatch) => {
        try {
            // dispatch(isLoading());
            await apiRest.put('/solicitud/rechazar', data, { headers: { 'token': getToken() }, params: { id_solicitud_avaluo } });
            // dispatch(loaded());
        } catch (error) {
            // dispatch(loaded());
            interceptorResponse(error);
        }
    }
}

export const obtenerComentarios = (id_solicitud_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/solicitud/comentario', { headers: { 'token': getToken() }, params: { id_solicitud_avaluo } });
            dispatch(comentariosSolicitudCredentials(data));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
        }
    }
}

export const correlativosAsignados = () => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/solicitud/correlativo', { headers: { 'token': getToken() } });
            dispatch(correlativosSolicitudCredentials(data));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
            // errorModal('Error al obtener las solicitudes');
        }
    }
}

export const modificarValor = (avaluos, id) => {
    return async (dispatch) => {
        try {
            const aux = avaluos.map(item => {
                if (item.id_solicitud_avaluo === id) {
                    return { ...item, lectura: 1 };
                } else {
                    return { ...item };
                }
            });
            dispatch(modificarValorCredentials(aux));
        } catch (error) {
            errorModal('Error');
            interceptorResponse(error);
        }
    }
}

export const ingresarSolicitud = (id_solicitud_avaluo, setValue2, watchItems, setShow3) => {
    return async (dispatch) => {
        try {
            let data = {asignado: 4};
            dispatch(isLoading());
            await apiRest.put('/solicitud/ingresar', data, { headers: { 'token': getToken() }, params: { id_solicitud_avaluo } });
            dispatch(loaded());

            successModal('Solicitud ingresada correctamente', 'center');
            setShow3(false);
            dispatch(obtenerSolicitudes(watchItems.solicitante, "", 1, "", "", "", "", 4))
            setValue2('buscador', watchItems.solicitante);
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
        }
    }
}