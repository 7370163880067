import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { isLoading, loaded, modificarNotificacionesCredentials, notificacionCredentials } from "./notificacionSlice";

export const obtenerNotificaciones = (id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/notificacion', { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(notificacionCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const crearNotificacion = (id_avaluo, data, setOpenModal) => {
    return async (dispatch) => {
        try {
            setOpenModal(false);
            dispatch(isLoading());
            await apiRest.post('/notificacion', data, { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(loaded());
            dispatch(obtenerNotificaciones(id_avaluo));
            successModal('Registro creado correctamente', 'center');
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const editarNotificacion = (id_avaluo, data, setOpenModal, completado) => {
    return async (dispatch) => {
        try {
            setOpenModal(false);
            dispatch(isLoading());
            await apiRest.put('/notificacion', data, { headers: { 'token': getToken() }, params: { id_avaluo, completado } });
            dispatch(loaded());
            dispatch(obtenerNotificaciones(id_avaluo));
            successModal('Información agregada correctamente', 'center');
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const modificarNotificaciones = (arreglo) => {
    return async (dispatch) => {
        dispatch(modificarNotificacionesCredentials(arreglo));
    }
}