import { useForm } from "react-hook-form";
import { InputDate, InputText, SelectOption } from "../../../ui";
import { useAvaluoSlice, useSolicitud } from "../../../hooks";
import { useEffect } from "react";

let tipo_avaluo = [{ id: 1, avaluo: 'Avalúo hipotecario' }, { id: 2, avaluo: 'Activo extraordinario' }];
export const CrearAvaluo = ({ setShow, setValue }) => {

    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const { crearNuevoAvaluo, obtenerTotalAvaluos } = useAvaluoSlice();
    const { totalCorrelativosAsignados, correlativos } = useSolicitud();
    let watchItem = watch();

    useEffect(() => {
        totalCorrelativosAsignados();
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <div className='flex justify-center'>
                <div className="w-full max-w-4xl bg-white rounded-xl shadow-2xl px-6 py-3 mb-5 mt-10">
                    <div className='mb-6 text-gray-400 font-bold sm:text-2xl text-xl'>
                        <h1>
                            INFORMACIÓN GENERAL DEL INMUEBLE VALUADO
                            <hr />
                        </h1>
                    </div>
                    <form onSubmit={handleSubmit((data) => crearNuevoAvaluo(data, setShow, setValue, obtenerTotalAvaluos, watchItem))}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <InputText nameLabel='Nombre del solicitante' register={register} name="solicitante" message="El solicitante es requerido" errors={errors} Placeholder="Ej. Pedro González Hernandez" />
                            <InputText nameLabel='Nombre(s) de propietario(s)' register={register} name="propietario" message="El propietario es requerido" errors={errors} Placeholder="Ej. Juan Fernández, Fredy Ortiz" />
                            <InputText nameLabel='Ubicación Legal' register={register} name="ubicacion_legal" message="La ubiación es requerida" errors={errors} Placeholder="Ej. Terreno X, ubicado en el municipio de X, departamento XX." />
                            <InputText nameLabel='Ubicación según inspección realizada en campo' register={register} name="ubicacion_inspeccion_campo" message="La ubiación es requerida" errors={errors} Placeholder="Ej. Caserío X, ubicado en el municipio de X, departamento XX." />
                            <InputText nameLabel='Coordenada (Central)' register={register} name="coordenada" message="Las coordenadas son requeridas" position="md:w-1/2" errors={errors} Placeholder="Ej. 15.10°-89.73°" />
                            <InputDate nameLabel='Fecha de visita' register={register} name="fecha_visita" message="La fecha es requerida" position="md:w-1/2" errors={errors} />
                            <SelectOption nameLabel='Correlativo de solicitud' register={register} name='id_solicitud_avaluo' message='El valor es requerido' errors={errors} array={correlativos} valueOption='id_solicitud_avaluo' nameOption='correlativo' position="md:w-1/2" />
                            <SelectOption nameLabel='Tipo de avalúo' register={register} name='tipo_avaluo' message='El valor es requerido' errors={errors} array={tipo_avaluo} valueOption='id' nameOption='avaluo' position="md:w-1/2" />

                            <div className="w-full md:w-full px-3">
                                <div className="text-center flex">
                                    <input
                                        className="w-full mr-1 px-3 py-2 font-bold text-white bg-blue-900 rounded-md cursor-pointer hover:bg-blue-800 focus:outline-none focus:shadow-outline"
                                        type="submit"
                                        value='Crear'
                                    />
                                    <button type="button" onClick={() => setShow(false)} className="font-bold text-white w-full px-4 py-2 bg-red-700 rounded-md cursor-pointer hover:bg-red-600 focus:outline-none focus:shadow-outline">
                                        Cancelar
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}
