import { Tab } from "@headlessui/react";
import { Lash } from "../../../../../../ui";
import { EdicionRegistroFase1 } from "./EdicionRegistroFase1";
import { EdicionRegistroFase2 } from "./EdicionRegistroFase2";
import { EdicionRegistroFase3 } from "./EdicionRegistroFase3";
import { EdicionRegistroFase4 } from "./EdicionRegistroFase4";
import { EdicionRegistroFase5 } from "./EdicionRegistroFase5";
import { EdicionRegistroFase6 } from "./EdicionRegistroFase6";
import { EdicionRegistroFase7 } from "./EdicionRegistroFase7";


export const EditarRegistro = ({ edicion, setEdicion }) => {

    let tabs = [
        { titulo: 'Registro' },
        { titulo: 'Gravámenes' },
        { titulo: 'Anotaciones' },
        { titulo: 'Limitaciones' },
        { titulo: 'Medidas y colindancias' },
        { titulo: 'Desmembraciones' },
        { titulo: 'Área actual' }
    ];

    return (
        <Lash arrays={tabs}>
            <Tab.Panel key={1}>
                <EdicionRegistroFase1 edicion={edicion} setEdicion={setEdicion} />
            </Tab.Panel>

            <Tab.Panel key={2}>
                <EdicionRegistroFase2 />
            </Tab.Panel>

            <Tab.Panel key={3}>
                <EdicionRegistroFase3 />
            </Tab.Panel>

            <Tab.Panel key={4}>
                <EdicionRegistroFase4 />
            </Tab.Panel>

            <Tab.Panel key={5}>
                <EdicionRegistroFase5 />
            </Tab.Panel>

            <Tab.Panel key={6}>
                <EdicionRegistroFase6 />
            </Tab.Panel>

            <Tab.Panel key={7}>
                <EdicionRegistroFase7 edicion={edicion} setEdicion={setEdicion} />
            </Tab.Panel>
        </Lash>
    )
}
