import { useForm } from "react-hook-form";
import { useRegistroPropiedadSlice } from "../../../../../../hooks";
import { InputDate, InputDecimal, InputText, Modal, ModalGenericoCrear, NormalButton, SelectOption } from "../../../../../../ui";
import { TdTable, ThTable, TrTable } from "../../../../components";
import { useEffect, useState } from "react";
import { departamentos, questionModal } from "../../../../../../utils";

export const EdicionRegistroFase6 = () => {

    const { desmembracion, detalleCompletoRegistroPropiedad, obtenerDesmembracion, editarDesmembracion, eliminarDesmembracion, crearDesmembracion } = useRegistroPropiedadSlice();
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const [showModal, setShowModal] = useState(false);
    const [showModalCreate, setShowModalCreate] = useState(false);
    const [idDesmembracion, setIdDesmembracion] = useState();

    useEffect(() => {
        setValue('numero_desmembracion', desmembracion.numero_desmembracion);
        setValue('finca', desmembracion.finca);
        setValue('folio', desmembracion.folio);
        setValue('libro', desmembracion.libro);
        setValue('departamento', desmembracion.departamento);
        setValue('fecha', desmembracion.fecha);
        setValue('area_desmembracion', desmembracion.area_desmembracion);
        // eslint-disable-next-line
    }, [desmembracion])

    const abrirModalEdicion = (id_desmembracion) => {
        obtenerDesmembracion(id_desmembracion, setShowModal);
        setIdDesmembracion(id_desmembracion);
    }

    return (
        <div className='mx-20 my-10'>
            <div className='flex justify-end'>
                <NormalButton funcion={() => setShowModalCreate(true)} bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' title={
                    <div className="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Crear Desmembración
                    </div>
                } />
            </div>
            <table className={"border-collapse w-full"}>
                <thead>
                    <tr>
                        <ThTable titulo='Número de desmembración' />
                        <ThTable titulo='finca' />
                        <ThTable titulo='folio' />
                        <ThTable titulo='libro' />
                        <ThTable titulo='departamento' />
                        <ThTable titulo='area de desmembración' />
                        <ThTable titulo='Acciones' />
                    </tr>
                </thead>
                <tbody>
                    {
                        detalleCompletoRegistroPropiedad.desmembracion.map((item, index) => (
                            <TrTable key={index}>
                                <TdTable titulo='Número de desmembración' valor={item.numero_desmembracion} />
                                <TdTable titulo='finca' valor={item.finca} />
                                <TdTable titulo='folio' valor={item.folio} />
                                <TdTable titulo='libro' valor={item.libro} />
                                <TdTable titulo='departamento' valor={item.departamento} />
                                <TdTable titulo='area de desmembración' valor={item.area_desmembracion} />
                                <TdTable valor={
                                    <div className='flex justify-center mx-2'>
                                        <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                                            <svg onClick={() => abrirModalEdicion(item.id_desmembracion)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                            </svg>
                                        </div>
                                        <div className="w-4 transform hover:text-red-500 hover:scale-110">
                                            <svg onClick={() => questionModal('Eliminar Desmembración', '¿Desea eliminar esta desmembración?', () => eliminarDesmembracion(item.id_desmembracion, item.id_registro_propiedad))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>

                                        </div>
                                    </div>
                                } />
                            </TrTable>
                        ))
                    }
                </tbody>
            </table>
            <Modal showModal={showModal} setShowModal={setShowModal} title='EDITAR COLINDANCIA' handleSubmit={handleSubmit} id_item={idDesmembracion} funcion={editarDesmembracion} id_registro_propiedad={detalleCompletoRegistroPropiedad.registro_propiedad.id_registro_propiedad} >
                <div className='my-4 px-1 pb-4 w-full  flex flex-wrap' >

                    <div className='w-full bg-gray-400 rounded-t-xl flex items-center justify-center'>
                        <label className='text-center block text-xl font-bold text-white'>
                            Desmembración
                        </label>
                    </div>

                    <div className='w-full flex border-x-4 border-b-4 border-gray-400 rounded-b-xl shadow-lg p-2'>
                        <div className='flex flex-wrap w-full'>
                            <InputDecimal nameLabel='No. desmembración' register={register} name='numero_desmembracion' message="El número es obligatorio" errors={errors} Placeholder="Ej. 1" position="md:w-1/3" />
                            <InputText nameLabel='Finca' register={register} name='finca' message="El registro de finca es obligatorio" errors={errors} Placeholder="Ej. 7718" position="md:w-1/3" />
                            <InputText nameLabel='Folio' register={register} name='folio' message="El registro del folio es obligatorio" errors={errors} Placeholder="Ej. 218" position="md:w-1/3" />
                            <InputText nameLabel='Libro' register={register} name='libro' message="El registro del libro es obligatorio" errors={errors} Placeholder="Ej. 116E" position="md:w-1/3" />
                            <SelectOption nameLabel='Departamento' register={register} name='departamento' message='El departamento es requerido' errors={errors} array={departamentos} valueOption='departamento' nameOption='departamento' position="md:w-1/3"/>
                            <InputDate nameLabel='Fecha' register={register} name='fecha' message='La fecha es requerida' errors={errors} position="md:w-1/3" />
                            <InputDecimal nameLabel='Área m2' register={register} name='area_desmembracion' message="El área es requerida" errors={errors} Placeholder="Ej. 624.90" position="md:w-1/2" />
                        </div>
                    </div>
                </div>
            </Modal>

            <ModalGenericoCrear showModal={showModalCreate} setShowModal={setShowModalCreate} title='CREAR COLINDANCIA' handleSubmit={handleSubmit} reset={reset} funcion={crearDesmembracion} id_registro_propiedad={detalleCompletoRegistroPropiedad.registro_propiedad.id_registro_propiedad}>
                <div className='my-4 px-1 pb-4 w-full  flex flex-wrap' >

                    <div className='w-full bg-gray-400 rounded-t-xl flex items-center justify-center'>
                        <label className='text-center block text-xl font-bold text-white'>
                            Desmembración
                        </label>
                    </div>

                    <div className='w-full flex border-x-4 border-b-4 border-gray-400 rounded-b-xl shadow-lg p-2'>
                        <div className='flex flex-wrap w-full'>
                            <InputDecimal nameLabel='No. desmembración' register={register} name='numero_desmembracionA' message="El número es obligatorio" errors={errors} Placeholder="Ej. 1" position="md:w-1/3" />
                            <InputText nameLabel='Finca' register={register} name='fincaA' message="El registro de finca es obligatorio" errors={errors} Placeholder="Ej. 7718" position="md:w-1/3" />
                            <InputText nameLabel='Folio' register={register} name='folioA' message="El registro del folio es obligatorio" errors={errors} Placeholder="Ej. 218" position="md:w-1/3" />
                            <InputText nameLabel='Libro' register={register} name='libroA' message="El registro del libro es obligatorio" errors={errors} Placeholder="Ej. 116E" position="md:w-1/3" />
                            <SelectOption nameLabel='Departamento' register={register} name='departamentoA' message='El departamento es requerido' errors={errors} array={departamentos} valueOption='departamento' nameOption='departamento' position="md:w-1/3"/>
                            <InputDate nameLabel='Fecha' register={register} name='fechaA' message='La fecha es requerida' errors={errors} position="md:w-1/3" />
                            <InputDecimal nameLabel='Área m2' register={register} name='area_desmembracionA' message="El área es requerida" errors={errors} Placeholder="Ej. 624.90" position="md:w-1/2" />
                        </div>
                    </div>
                </div>
            </ModalGenericoCrear >
        </div>
    )
}
