import { Tab } from "@headlessui/react";
import { Lash } from "../../../ui";
import { DetalleInmueble, Infraestructura, PlusvaliaMinusvalia, RegistroPropiedad, Observacion, ValorInmueble, Anexos } from "../views";

export const GeneracionAvaluo = () => {

  let tabs = [
    { titulo: 'Información del registro de la propiedad' },
    { titulo: '1. Detalles del inmueble' },
    { titulo: '2. Infraestructura' },
    { titulo: '3. Plusvalía/Minusvalía' },
    { titulo: '4. Observaciones' },
    { titulo: '5. Valores del inmueble' },
    { titulo: '6. Anexos' }
  ];

  return (
    <Lash arrays={tabs}>
      <Tab.Panel key={1}>
        <RegistroPropiedad />
      </Tab.Panel>

      <Tab.Panel key={2}>
        <DetalleInmueble />
      </Tab.Panel>

      <Tab.Panel key={3}>
        <Infraestructura />
      </Tab.Panel>

      <Tab.Panel key={4}>
        <PlusvaliaMinusvalia />
      </Tab.Panel>

      <Tab.Panel key={5}>
        <Observacion />
      </Tab.Panel>

      <Tab.Panel key={6}>
        <ValorInmueble />
      </Tab.Panel>

      <Tab.Panel key={7}>
        <Anexos />
      </Tab.Panel>
    </Lash>
  )
}
