import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL

export const apiRest = axios.create({
    baseURL: `${apiURL}/api`
})

export const apiInforme = axios.create({
    baseURL: 'https://micoope.jsreportonline.net/api/report',
    headers: {
        'Authorization': 'Basic bWljb29wZTptaWNvb3BlMTIzNA=='
    },
    responseType: 'blob'
});