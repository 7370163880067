import React, { useEffect, useState } from 'react'
import { TdTable, ThTable, TrTable } from '../../components';
import { InputText, NormalButton, SelectOption, SwitchComponent } from '../../../../ui';
import { useForm } from 'react-hook-form';
import { decryptId, errorModal, questionModal, successModal } from '../../../../utils';
import { usePlusvaliaMinusvalia } from '../../../../hooks';
import { useParams } from 'react-router-dom';

let plusvaliasMinusvalias = [{ tipo: 'Plusvalía' }, { tipo: 'Minusvalía' }];

export const CrearPlusvaliaMinusvalia3 = ({ setShow }) => {

    let plusvalia = [
        { id: 0, tipo: 'Plusvalía', estado: false, comentario: 'El inmueble se encuentra ubicado en área Zona Homogéneas Físicas Urbanas.' },
        { id: 1, tipo: 'Plusvalía', estado: false, comentario: 'El uso del inmueble es Comercial y residencial.' },
        { id: 2, tipo: 'Plusvalía', estado: false, comentario: 'El inmueble cuenta con servicios públicos instalados.' },
        { id: 3, tipo: 'Plusvalía', estado: false, comentario: 'Los límites del polígono se encuentran debidamente identificadas.' },
        { id: 4, tipo: 'Plusvalía', estado: false, comentario: 'El área y las distancias registrales sí coinciden con lo verificado en campo.' },
        { id: 5, tipo: 'Plusvalía', estado: false, comentario: 'La forma del polígono es regular.' },
        { id: 6, tipo: 'Plusvalía', estado: false, comentario: 'Posición del inmueble encuadra.' },
        { id: 7, tipo: 'Plusvalía', estado: false, comentario: 'Solar a nivel de la vía pública.' },
        { id: 8, tipo: 'Plusvalía', estado: false, comentario: 'Topografía del solar de forma plana.' },
        { id: 9, tipo: 'Plusvalía', estado: false, comentario: 'Se tuvo a la vista plano de medición el cual coincide área y distancias replanteadas.' },
        { id: 10, tipo: 'Plusvalía', estado: false, comentario: 'Inmueble ubicado en sector con buen desarrollo urbanístico y adecuada dinámica inmobiliaria.' },
        { id: 11, tipo: 'Plusvalía', estado: false, comentario: 'Inmueble de uso comercial ubicado en sector comercial.' },
        { id: 12, tipo: 'Plusvalía', estado: false, comentario: 'Vías de acceso principales en buen estado de mantenimiento, que facilitan la vialidad vehicular y peatonal.' },
        { id: 13, tipo: 'Plusvalía', estado: false, comentario: 'Conexión propia a los servicios públicos de energía eléctrica y agua entubada.' },
        { id: 14, tipo: 'Plusvalía', estado: false, comentario: 'Los linderos y mojones se encuentran bien delimitados.' },
        { id: 15, tipo: 'Plusvalía', estado: false, comentario: 'El área registral y el área física son coincidentes.' },
        { id: 16, tipo: 'Plusvalía', estado: false, comentario: 'Se tuvo a la vista plano de medición el cual coincide con lo replanteado.' },
        { id: 17, tipo: 'Plusvalía', estado: false, comentario: 'Terreno a nivel de la vía pública.' },
        { id: 18, tipo: 'Plusvalía', estado: false, comentario: 'Solar con topografía plana predominante.' },
        { id: 19, tipo: 'Plusvalía', estado: false, comentario: 'Inmueble ubicado en esquina de cuadra.' },
        { id: 20, tipo: 'Plusvalía', estado: false, comentario: 'La finca objeto de avalúo se encuentra libre de gravámenes, limitaciones y anotaciones.' },
        { id: 21, tipo: 'Plusvalía', estado: false, comentario: 'El terreno se encuentra con cobertura de pasto, en buen estado de mantenimiento.' },
        { id: 22, tipo: 'Plusvalía', estado: false, comentario: 'Dentro del inmueble se encuentran nacimientos de agua.' },
        { id: 23, tipo: 'Plusvalía', estado: false, comentario: 'El uso del inmueble es ganadero.' },
    ];

    let minusvalia = [
        { id: 0, tipo: 'Minusvalía', estado: false, comentario: 'El expediente registral se encuentra con gravamen de hipoteca vigente.' },
        { id: 1, tipo: 'Minusvalía', estado: false, comentario: 'La calle que colinda con el inmueble se encuentra en regular estado de mantenimiento ' },
        { id: 2, tipo: 'Minusvalía', estado: false, comentario: 'El sector no cuenta con disponibilidad de servicios públicos de drenajes.' },
        { id: 3, tipo: 'Minusvalía', estado: false, comentario: 'La finca objeto de avalúo se encuentra con un gravamen de hipoteca vigente. ' },
        { id: 4, tipo: 'Minusvalía', estado: false, comentario: 'Inmueble ubicado en sector de lento crecimiento urbanístico y baja dinámica inmobiliaria.' },
        { id: 5, tipo: 'Minusvalía', estado: false, comentario: 'Vías de acceso principales en regular estado de mantenimiento.' },
        { id: 6, tipo: 'Minusvalía', estado: false, comentario: 'Sector con construcciones heterogéneas.' },
        { id: 7, tipo: 'Minusvalía', estado: false, comentario: 'La relación de medida entre el frente y el fondo del solar es desfavorable.' },
        { id: 8, tipo: 'Minusvalía', estado: false, comentario: 'No se tuvo a la vista plano de medición.' },
        { id: 9, tipo: 'Minusvalía', estado: false, comentario: 'Dentro del inmueble viven aproximadamente familias.' },
        { id: 10, tipo: 'Minusvalía', estado: false, comentario: 'Las construcciones presentan obsolescencia de diseño.' },
        { id: 11, tipo: 'Minusvalía', estado: false, comentario: 'Las construcciones presentan regular y mal estado de mantenimiento.' },
        { id: 12, tipo: 'Minusvalía', estado: false, comentario: 'Las construcciones presentan una edad efectiva superior a 6 años.' },
        { id: 13, tipo: 'Minusvalía', estado: false, comentario: 'Se tuvo a la vista plano de medición el cual no coincide con lo replanteado.' },
        { id: 14, tipo: 'Minusvalía', estado: false, comentario: 'Terreno con topografía plana e inclinada.' },
    ];

    const [plusvalias, setPlusvalias] = useState([]);
    const [minusvalias, setMinusvalias] = useState([]);
    const { register, watch, formState: { errors }, setValue } = useForm();
    const { plusvaliaMinusvalia, crearPlusvaliaMinusvaliaIndividual } = usePlusvaliaMinusvalia();
    let watchItems = watch();
    const { id } = useParams();

    useEffect(() => {
        let plusvaliaAux = [];
        let minusvaliaAux = [];

        for (let i = 0; i < plusvalia.length; i++) {
            let contador = 0;
            for (let j = 0; j < plusvaliaMinusvalia.length; j++) {
                if (plusvalia[i].comentario === plusvaliaMinusvalia[j].comentario) {
                    contador = 1;
                }
            }
            if (contador <= 0) {
                plusvaliaAux.push(plusvalia[i]);
            }
        }

        for (let i = 0; i < minusvalia.length; i++) {
            let contador = 0;
            for (let j = 0; j < plusvaliaMinusvalia.length; j++) {
                if (minusvalia[i].comentario === plusvaliaMinusvalia[j].comentario) {
                    contador = 1;
                }
            }
            if (contador <= 0) {
                minusvaliaAux.push(minusvalia[i]);
            }
        }

        setPlusvalias(plusvaliaAux);
        setMinusvalias(minusvaliaAux);

        // eslint-disable-next-line
    }, [plusvaliaMinusvalia]);


    const activar = (item, index, tipo, setTipo) => {
        if (tipo[index].estado) {
            let idObjetoModificar = tipo.findIndex(objeto => objeto.id === item.id);
            tipo[idObjetoModificar].estado = false;
            setTipo([...tipo]);
        } else {
            let indiceObjetoModificar = tipo.findIndex(objeto => objeto.id === item.id);
            tipo[indiceObjetoModificar].estado = true;
            setTipo([...tipo]);
        }
    }

    const agregarPlusvaliaMinusvalia = (tipo, setTipo) => {
        if (watchItems.comentario === "" || watchItems.comentario === undefined) {
            errorModal('se necesita llenar el campo');
        } else {
            let auxArray = tipo;
            let objeto = {
                id: tipo.length,
                tipo: watchItems.tipo,
                estado: true,
                comentario: watchItems.comentario
            }
            auxArray.push(objeto);
            setTipo(auxArray);

            setValue('tipo', '');
            setValue('comentario', '');
            successModal('elemento agregado correctamente');
        }
    }

    const guardarDatos = () => {
        let data = [];

        for (let i = 0; i < plusvalias.length; i++) {
            if (plusvalias[i].estado === true) {
                data.push({
                    tipo: plusvalias[i].tipo,
                    comentario: plusvalias[i].comentario
                });
            }
        }

        for (let i = 0; i < minusvalias.length; i++) {
            if (minusvalias[i].estado === true) {
                data.push({
                    tipo: minusvalias[i].tipo,
                    comentario: minusvalias[i].comentario
                });
            }
        }

        if (data.length > 0) {
            crearPlusvaliaMinusvaliaIndividual(decryptId(id), data, setShow);
        } else {
            errorModal('Debe agregar al menos una plusvalía o minusvalía');
        }

    }



    return (
        <div className='flex justify-center mt-4'>
            <div className='w-full max-w-7xl bg-gray-50 rounded-lg shadow-2xl p-6'>
                <div className=" mx-auto p-4">
                    <div className="flex flex-wrap">
                        <div className='w-full pt-5 mb-4 border-b-8 border-gray-300 rounded-md flex items-center justify-center'>
                            <label className=' text-center block text-2xl font-bold text-gray-400'>
                                Plusvalías/Minusvalías
                            </label>
                        </div>

                        <div className='flex flex-wrap justify-between w-full'>

                            <SelectOption nameLabel='Tipo' register={register} name='tipo' message='El dato es requerido' errors={errors} array={plusvaliasMinusvalias} position="lg:w-1/4" valueOption='tipo' nameOption='tipo' requerido={false} />
                            <div className="flex items-end">
                                <NormalButton funcion={() => questionModal('Guardar', '¿Confirma el guardado de la información?', guardarDatos)} bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="px-4 py-2 mb-6 rounded" title={
                                    <div className="flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                        </svg>
                                        Guardar
                                    </div>
                                } />
                            </div>

                            {
                                watchItems.tipo !== undefined && watchItems.tipo !== "" ?
                                    <div className='flex flex-wrap justify-between w-full'>
                                        <InputText register={register} name='comentario' message="El dato es requerido" errors={errors} Placeholder="Acceso regular y en buen estado" position="lg:w-10/12" requerido={false} />
                                        <div className="flex items-end">
                                            <NormalButton funcion={() => agregarPlusvaliaMinusvalia(watchItems.tipo === 'Plusvalía' ? plusvalias : minusvalias, watchItems.tipo === 'Plusvalía' ? setPlusvalias : setMinusvalias)} bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="px-4 py-2 mb-6 rounded" title={
                                                <div className="flex">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    Agregar
                                                </div>
                                            } />
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>

                        <table className={"border-collapse w-full"}>
                            <thead>
                                <tr>
                                    <ThTable titulo='Plusvalías' />
                                    <ThTable titulo='Acción' />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    plusvalias.map((item, index) => (
                                        <TrTable key={index}>
                                            <TdTable titulo='Plusvalía' valor={item.comentario} />
                                            <TdTable valor={
                                                <div className='flex justify-center mx-2'>
                                                    <SwitchComponent enabled={item.estado} setEnabled={() => activar(item, index, plusvalias, setPlusvalias)} />
                                                </div>
                                            } />
                                        </TrTable>
                                    ))
                                }
                            </tbody>
                        </table>
                        <table className={"border-collapse w-full mt-4"}>
                            <thead>
                                <tr>
                                    <ThTable titulo='Minusvalías' />
                                    <ThTable titulo='Acción' />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    minusvalias.map((item, index) => (
                                        <TrTable key={index}>
                                            <TdTable titulo='Minusvalía' valor={item.comentario} />
                                            <TdTable valor={
                                                <div className='flex justify-center mx-2'>
                                                    <SwitchComponent enabled={item.estado} setEnabled={() => activar(item, index, minusvalias, setMinusvalias)} />
                                                </div>
                                            } />
                                        </TrTable>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

