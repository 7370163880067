import { useEffect, useState } from 'react'
import { Loader, NormalButton, Pagination, TransitionEdition, TransitionPopover } from '../../../../../ui'
import { TdTable, ThTable, TrTable } from '../../../components';
import { useAvaluoSlice, useRegistroPropiedadSlice } from '../../../../../hooks';
import { useParams } from 'react-router-dom';
import { decryptId, questionModal } from '../../../../../utils';
import { CrearRegistro } from './crearRegistroPropiedad/CrearRegistro';
import { EditarRegistro } from './editarRegistroPropiedad/EditarRegistro';

export const RegistroPropiedad = () => {

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [edicion, setEdicion] = useState(false);
  const [page, setPage] = useState(1);
  const { id } = useParams();

  const { registro_propiedad, loading, totalPaginas, obtenerTotalRegistroPropiedad, obtenerRegistroPropiedadIndividual, eliminaRegistroPropiedad } = useRegistroPropiedadSlice();
  const { obtenerAvaluoIndividual, avaluoIndividual } = useAvaluoSlice();


  const obtenerDatosRegistroPropiedadIndividual = (id_registro_propiedad) => {
    obtenerRegistroPropiedadIndividual(id_registro_propiedad, setShow2);
  }

  useEffect(() => {
    obtenerTotalRegistroPropiedad(decryptId(id));
    obtenerAvaluoIndividual(decryptId(id));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    obtenerTotalRegistroPropiedad(decryptId(id));
    // eslint-disable-next-line
  }, [show2]);

  return (
    <>
      {loading && <Loader />}
      <div className='mx-20 my-10'>
        <div className='flex justify-end'>
          <NormalButton funcion={() => setShow(true)} bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' title={
            <div className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Crear registro
            </div>
          } />
        </div>
        <table className={"border-collapse w-full"}>
          <thead>
            <tr>
              {
                Object.keys(avaluoIndividual).length > 0 ?
                  avaluoIndividual.solicitud_avaluo.tipo_inmueble === "Derechos de posesión" ?
                    <>
                      <ThTable titulo='No. Escritura' />
                      <ThTable titulo='Fecha escrituración' />
                      <ThTable titulo='Nombre del notario' />
                    </> :
                    <>
                      <ThTable titulo='Finca' />
                      <ThTable titulo='Folio' />
                      <ThTable titulo='Libro' />
                    </>
                  : null
              }
              <ThTable titulo='Departamento' />
              <ThTable titulo='Acciones' />
            </tr>
          </thead>
          <tbody>
            {
              registro_propiedad.map((item, index) => (
                <TrTable key={index}>
                  {
                    Object.keys(avaluoIndividual).length > 0 ?
                      avaluoIndividual.solicitud_avaluo.tipo_inmueble === "Derechos de posesión" ?
                        <>
                          <TdTable titulo='No. Escritura' valor={item.numero_escritura} />
                          <TdTable titulo='Fecha escrituración' valor={item.fecha_escrituracion} />
                          <TdTable titulo='Nombre del notario' valor={item.nombre_notario} />
                        </> :
                        <>
                          <TdTable titulo='Finca' valor={item.finca} />
                          <TdTable titulo='Folio' valor={item.folio} />
                          <TdTable titulo='Libro' valor={item.libro} />
                        </>
                      : null
                  }

                  <TdTable titulo='Departamento' valor={item.departamento} />
                  <TdTable valor={
                    <div className='flex justify-center mx-2'>
                      <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                        <svg onClick={() => obtenerDatosRegistroPropiedadIndividual(item.id_registro_propiedad)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                        </svg>
                      </div>
                      <div className="w-4 transform hover:text-red-500 hover:scale-110">
                        <svg onClick={() => questionModal('Eliminar', '¿Desea eliminar la información de este registro de la propiedad?', () => eliminaRegistroPropiedad(item.id_registro_propiedad, decryptId(id)))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                      </div>
                    </div>
                  } />
                </TrTable>
              ))
            }
          </tbody>
        </table>
        <div className="flex justify-end mt-3">
          <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
        </div>
        <TransitionPopover show={show} setShow={setShow}>
          <CrearRegistro setShow={setShow} />
        </TransitionPopover>

        <TransitionEdition show={show2} setShow={setShow2} edicion={edicion}>
          <EditarRegistro setShow={setShow2} edicion={edicion} setEdicion={setEdicion} />
        </TransitionEdition>
      </div >
    </>
  )
}
