import { createSlice } from '@reduxjs/toolkit';

export const solicitudSlice = createSlice({
    name: 'solicitud',
    initialState: {
        solicitud_avaluos: [],
        cantidad: null,
        totalPaginas: null,
        loading: false,
        solicitudIndividual: {},
        imagenes: [],
        comentarios: [],
        correlativos: []
    },
    reducers: {
        solicitudCredentials: (state, { payload }) => {
            state.solicitud_avaluos = payload.solicitud_avaluos;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        modificarValorCredentials: (state, { payload }) => {
            state.solicitud_avaluos = payload;
        },
        solicitudIndividualCredentials: (state, {payload}) => {
            state.solicitudIndividual = payload.solicitudIndividual;
            state.imagenes = payload.imagenes;
            state.loading = false;
        },
        limpiarSolicitudIndividualCredentials: (state) => {
            state.solicitudIndividual = {};
            state.imagenes = [];
            state.loading = false;
        },
        comentariosSolicitudCredentials: (state, {payload}) => {
            state.comentarios = payload.comentarios;
            state.loading = false;
        },
        correlativosSolicitudCredentials: (state, {payload}) => {
            state.correlativos = payload.correlativos_asignados;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { solicitudCredentials, solicitudIndividualCredentials , isLoading, loaded, limpiarSolicitudIndividualCredentials, comentariosSolicitudCredentials, correlativosSolicitudCredentials, modificarValorCredentials } = solicitudSlice.actions;