import { createSlice } from '@reduxjs/toolkit';

export const registroPropiedadSlice = createSlice({
    name: 'registroPropiedad',
    initialState: {
        registro_propiedad: [],
        cantidad: null,
        totalPaginas: null,
        loading: false,
        detalleCompletoRegistroPropiedad: {},
        gravamen: {},
        anotacion: {},
        limitacion: {},
        colindancia: {},
        desmembracion: {}
    },
    reducers: {
        registroPropiedadCredentials: (state, { payload }) => {
            state.registro_propiedad = payload.registro_propiedad;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        registroPropiedadIndividualCredential: (state, { payload }) => {
            state.detalleCompletoRegistroPropiedad = payload.detalleCompletoRegistroPropiedad;
            state.loading = false;
        },
        gravamenCredentials: (state, { payload }) => {
            state.gravamen = payload.get_item;
            state.loading = false;
        },
        anotacionCredentials: (state, { payload }) => {
            state.anotacion = payload.get_item;
            state.loading = false;
        },
        limitacionCredentials: (state, { payload }) => {
            state.limitacion = payload.get_item;
            state.loading = false;
        },
        colindanciaCredentials: (state, { payload }) => {
            state.colindancia = payload.get_item;
            state.loading = false;
        },
        desmembracionCredentials: (state, { payload }) => {
            state.desmembracion = payload.get_item;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { registroPropiedadCredentials, isLoading, loaded, registroPropiedadIndividualCredential, gravamenCredentials, anotacionCredentials, limitacionCredentials, colindanciaCredentials, desmembracionCredentials } = registroPropiedadSlice.actions;