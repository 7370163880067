export const TdTable = ({ titulo, valor, lectura = 1 }) => {
    return (
        <td className={`w-full sm:w-auto py-3 px-1 ${lectura === 0 ? 'text-gray-900': 'text-gray-500'} font-semibold border border-b border-gray-300 relative sm:static`}>
            <div className={`flex ${titulo === undefined && 'justify-center'}`}>
                {titulo !== undefined && <span className="sm:hidden  py-1 text-xs font-bold uppercase h-full flex w-1/2">{titulo}</span>}
                <div className="text-center sm:text-base text-xs w-1/2 sm:w-full">
                    {valor}
                </div>
            </div>
        </td>
    )
}
