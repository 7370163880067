import { useDispatch, useSelector } from "react-redux";
import { crearDescripcionReferencia, crearMemoriaCalculo, editarDescripcionReferencial, editarMemoriaCalculo, eliminarDescripcionReferencial, eliminarValorInmueble, obtenerDescripcionReferencial, obtenerValorInmuebleIndividual, obtenerValoresInmueble } from "../store/slice/valorInmueble";

export const useValorInmueble = () => {

    const dispatch = useDispatch();
    const { valorInmueble, loading, descripcionReferencial, descripcionReferencialIndividual, valorInmuebleIndividual } = useSelector((state) => state.valorInmueble);

    const obtenervaloresInmuebleCompleto = (id_avaluo) => {
        dispatch(obtenerValoresInmueble(id_avaluo));
    }

    const crearDescripcionReferencialIndividual = (id_avaluo, data, setShow) => {
        dispatch(crearDescripcionReferencia(id_avaluo, data, setShow));
    }

    const obtenerDescripcionReferencialIndividual = (id_descripcion_referencial, setShow2) => {
        dispatch(obtenerDescripcionReferencial(id_descripcion_referencial, setShow2));
    }

    const editarDescripcionReferencialIndividual = (id_descripcion_referencial, id_avaluo, data, setShow2) => {
        dispatch(editarDescripcionReferencial(id_descripcion_referencial, id_avaluo, data, setShow2));
    }

    const eliminarDescripcionReferencialIndividual = (id_descripcion_referencial, id_avaluo) => {
        dispatch(eliminarDescripcionReferencial(id_descripcion_referencial, id_avaluo));
    }

    const crearRegistroMemoriaCalculo = (id_avaluo, data, setShow4) => {
        dispatch(crearMemoriaCalculo(id_avaluo, data, setShow4));
    }

    const editarRegistroMemoriaCalculo = (id_avaluo, data, setShow5, id_valor_inmueble_terreno) => {
        dispatch(editarMemoriaCalculo(id_avaluo, data, setShow5, id_valor_inmueble_terreno));
    }

    const eliminarValorInmuebleEspecifico = (id_valor_inmueble_terreno, id_avaluo) => {
        dispatch(eliminarValorInmueble(id_valor_inmueble_terreno, id_avaluo));
    }

    const obtenerValorInmuebleIndividualTotal = (id_valor_inmueble_terreno, setShow5) => {
        dispatch(obtenerValorInmuebleIndividual(id_valor_inmueble_terreno, setShow5));
    }

    return {
        //valores
        valorInmueble,
        loading,
        descripcionReferencial,
        descripcionReferencialIndividual,
        valorInmuebleIndividual,

        //funciones
        obtenervaloresInmuebleCompleto,
        crearDescripcionReferencialIndividual,
        obtenerDescripcionReferencialIndividual,
        editarDescripcionReferencialIndividual,
        eliminarDescripcionReferencialIndividual,
        crearRegistroMemoriaCalculo,
        editarRegistroMemoriaCalculo,
        eliminarValorInmuebleEspecifico,
        obtenerValorInmuebleIndividualTotal
    }

}