import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { InputText, SelectOption } from '../../../../ui';
import { questionModal, successModal } from '../../../../utils';
import { useAvaluoSlice } from '../../../../hooks';

let estadoAutorizacion = [{ id: 2, autorizacion: 'fase de autorización' }, { id: 3, autorizacion: 'autorizado' }]
export const DetallesAvaluo = ({ setShow, infoAvaluo }) => {

    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();
    const { retrocesoAvaluo } = useAvaluoSlice();

    useEffect(() => {
        setValue('correlativo', infoAvaluo.solicitud_avaluo.correlativo);
        setValue('cantidad_avaluos', infoAvaluo.solicitud_avaluo.cantidad_avaluos);
        setValue('avaluos_completados', infoAvaluo.solicitud_avaluo.avaluos_completados);
        setValue('autorizacion', infoAvaluo.autorizacion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const switchHabilitar = () => {
        if (disabledSubmit) {
            setDisabledSubmit(false);
            successModal('Edición activada');
        } else {
            setDisabledSubmit(true);
            successModal('Edición desactivada');
        }
    }


    return (
        <div className='flex justify-center'>
            <div className="w-full max-w-4xl bg-white rounded-xl shadow-2xl px-6 py-3 mb-5 mt-10">



                <div className='flex justify-end'>
                    <button type="button" onClick={() => questionModal(disabledSubmit ? 'Habilitar' : 'Deshabilitar', disabledSubmit ? '¿Desea habiliar la edición para este registro?' : '¿Desea deshabilitar la edición para este registro?', switchHabilitar)} className="font-bold text-white w-1/6 px-4 py-2 bg-green-700 rounded-md cursor-pointer hover:bg-green-600 focus:outline-none focus:shadow-outline">
                        {disabledSubmit ? 'Habilitar' : 'Deshabilitar'}
                    </button>
                </div>
                <form onSubmit={handleSubmit((data) => questionModal('Guardar', '¿Desea guardar los cambios?', () => retrocesoAvaluo(infoAvaluo.id_avaluo, data, setShow)))}>
                    <div className='mb-6 text-gray-400 font-bold sm:text-2xl text-xl flex justify-between border-b-2'>
                        <h1>
                            DETALLES DE LA SOLICITUD DE AVALÚO
                        </h1>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <InputText nameLabel='Correlativo' register={register} name="correlativo" message="El correlativo es requerido" errors={errors} Placeholder="Ej. 8-082023" position="md:w-1/3" disabled={true} textOrientation='text-center' />
                        <InputText nameLabel='Avalúos asignados' register={register} name="cantidad_avaluos" message="El correlativo es requerido" errors={errors} Placeholder="3" position="md:w-1/3" disabled={disabledSubmit} textOrientation='text-center' />
                        <InputText nameLabel='Avalúos completados' register={register} name="avaluos_completados" message="El correlativo es requerido" errors={errors} Placeholder="3" position="md:w-1/3" disabled={true} textOrientation='text-center' />
                    </div>

                    <div className='mb-6 text-gray-400 font-bold sm:text-2xl text-xl flex justify-between border-b-2'>
                        <h1>
                            DETALLES DEL AVALÚO
                        </h1>
                    </div>
                    <div className="flex flex-wrap -mx-3">
                        <SelectOption nameLabel='Estado del avalúo' register={register} name='autorizacion' message='El valor es requerido' errors={errors} array={estadoAutorizacion} valueOption='id' nameOption='autorizacion' position="md:w-1/2" disabled={disabledSubmit} />
                    </div>

                    <div className="w-full md:w-full px-3">
                        <div className="text-center flex">
                            <input
                                className={`w-full mr-1 px-3 py-2 font-bold text-white ${disabledSubmit ? 'bg-blue-300' : 'bg-blue-900 cursor-pointer hover:bg-blue-800'} rounded-md focus:outline-none focus:shadow-outline`}
                                type="submit"
                                value='Actualizar'
                                disabled={disabledSubmit}

                            />
                            <button type="button" onClick={() => setShow(false)} className="font-bold text-white w-full px-4 py-2 bg-red-700 rounded-md cursor-pointer hover:bg-red-600 focus:outline-none focus:shadow-outline">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
