import { useForm } from "react-hook-form";
import { useRegistroPropiedadSlice } from "../../../../../../hooks";
import { InputText, Modal, ModalCreate, ModalEditNumberGravamen, NormalButton } from "../../../../../../ui";
import { TdTable, ThTable, TrTable } from "../../../../components";
import { useEffect, useState } from "react";
import { questionModal } from "../../../../../../utils";

export const EdicionRegistroFase2 = () => {

  const { detalleCompletoRegistroPropiedad, obtenerGravamen, gravamen, editarGravamen, crearGravamen, eliminarGravamen, editarNoGravamen } = useRegistroPropiedadSlice();
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const [showModal, setShowModal] = useState(false);
  const [showModalNoGravamen, setShowModalNoGravamen] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [idGravamen, setIdGravamen] = useState();


  useEffect(() => {
    setValue('deudor', gravamen.deudor);
    setValue('acreedor', gravamen.acreedor);
    // eslint-disable-next-line
  }, [gravamen])

  const abrirModal = (id_gravamen) => {
    obtenerGravamen(id_gravamen, setShowModal);
    setIdGravamen(id_gravamen);
  }

  const abrirModalCrear = () => {
    if (detalleCompletoRegistroPropiedad.gravamen.length > 0) {
      setIdGravamen(detalleCompletoRegistroPropiedad.gravamen[0].numero_gravamen);
    } else {
      setIdGravamen(0);
    }
    setShowModalCreate(true);
  }

  const abrirModalNoGravamen = () => {
    setShowModalNoGravamen(true);
    setValue('numero_gravamen', detalleCompletoRegistroPropiedad.gravamen[0].numero_gravamen);
  }

  return (
    <div className='mx-20 my-10'>
      <div className='flex flex-wrap justify-end'>
        <NormalButton funcion={abrirModalNoGravamen} bgColor='bg-green-700' bgText='text-white font-semibold' hoverColor='hover:bg-green-800' title={
          <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
            </svg>
            Editar No. Gravamen
          </div>
        } />
        <NormalButton funcion={abrirModalCrear} bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="px-4 py-2 mb-2 rounded sm:ml-1" title={
          <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Crear Gravamen
          </div>
        } />
      </div>
      <table className={"border-collapse w-full"}>
        <thead>
          <tr>
            <ThTable titulo='Deudor' />
            <ThTable titulo='Acreedor' />
            <ThTable titulo='Acciones' />
          </tr>
        </thead>
        <tbody>
          {
            detalleCompletoRegistroPropiedad.gravamen.map((item, index) => (
              <TrTable key={index}>
                <TdTable titulo='Deudor' valor={item.deudor} />
                <TdTable titulo='Acreedor' valor={item.acreedor} />
                <TdTable valor={
                  <div className='flex justify-center mx-2'>
                    <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                      <svg onClick={() => abrirModal(item.id_gravamen)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                      </svg>
                    </div>
                    <div className="w-4 transform hover:text-red-500 hover:scale-110">
                      <svg onClick={() => questionModal('Eliminar gravamen', '¿Desea eliminar este gravamen?', () => eliminarGravamen(item.id_gravamen, item.id_registro_propiedad))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>

                    </div>
                  </div>
                } />
              </TrTable>
            ))
          }
        </tbody>
      </table>
      <Modal showModal={showModal} setShowModal={setShowModal} title='EDITAR GRAVAMEN' handleSubmit={handleSubmit} id_item={idGravamen} funcion={editarGravamen} id_registro_propiedad={detalleCompletoRegistroPropiedad.registro_propiedad.id_registro_propiedad} >
        <div className='my-4 px-1 pb-4 w-full  flex flex-wrap' >
          <div className='w-full bg-gray-400 rounded-t-xl flex items-center justify-center'>
            <label className='text-center block text-xl font-bold text-white'>
              Gravamen
            </label>
          </div>
          <div className='w-full md:flex border-x-4 border-b-4 border-gray-400 rounded-b-xl shadow-lg p-2'>
            <div className='md:mr-2 md:w-1/2'>
              <InputText nameLabel='Deudor' register={register} name='deudor' message="El deudor es requerido" errors={errors} Placeholder="Hector Mazariegos" />
            </div>
            <div className='md:ml-2 md:w-1/2'>
              <InputText nameLabel='Acreedor' register={register} name='acreedor' message="El acreedor es requerido" errors={errors} Placeholder="COOPERATIVA DE AHORRO Y CRÉDITO INTEGRAL “COBAN” RESPONSABILIDAD LIMITADA" />
            </div>
          </div>
        </div>
      </Modal>

      <ModalCreate showModal={showModalCreate} setShowModal={setShowModalCreate} title='CREAR GRAVAMEN' handleSubmit={handleSubmit} reset={reset} crearGravamen={crearGravamen} idGravamen={idGravamen} idRegistroPropiedad={detalleCompletoRegistroPropiedad.registro_propiedad.id_registro_propiedad}>
        <div>
          {
            detalleCompletoRegistroPropiedad.gravamen.length <= 0
              ?
              < InputText nameLabel='Numero de gravamen' register={register} name='numero_gravamen' message="El numero es requerido" errors={errors} Placeholder="1 y 2" />
              :
              null
          }
          <div className='my-4 px-1 pb-4 w-full  flex flex-wrap' >
            <div className='w-full bg-gray-400 rounded-t-xl flex items-center justify-center'>
              <label className='text-center block text-xl font-bold text-white'>
                Gravamen
              </label>
            </div>
            <div className='w-full md:flex border-x-4 border-b-4 border-gray-400 rounded-b-xl shadow-lg p-2'>
              <div className='md:mr-2 md:w-1/2'>
                <InputText nameLabel='Deudor' register={register} name='deudorA' message="El deudor es requerido" errors={errors} Placeholder="Hector Mazariegos" />
              </div>
              <div className='md:ml-2 md:w-1/2'>
                <InputText nameLabel='Acreedor' register={register} name='acreedorA' message="El acreedor es requerido" errors={errors} Placeholder="COOPERATIVA DE AHORRO Y CRÉDITO INTEGRAL “COBAN” RESPONSABILIDAD LIMITADA" />
              </div>
            </div>
          </div>
        </div>
      </ModalCreate>

      <ModalEditNumberGravamen showModalNoGravamen={showModalNoGravamen} setShowModalNoGravamen={setShowModalNoGravamen} handleSubmit={handleSubmit} reset={reset} id_registro_propiedad={detalleCompletoRegistroPropiedad.registro_propiedad.id_registro_propiedad} editarNoGravamen = {editarNoGravamen}>
      <div className='my-4 px-1 pb-4 w-full  flex flex-wrap' >
        <div className='w-full bg-gray-400 rounded-t-xl flex items-center justify-center'>
          <label className='text-center block text-xl font-bold text-white'>
            Gravamen
          </label>
        </div>
        <div className='w-full md:flex border-x-4 border-b-4 border-gray-400 rounded-b-xl shadow-lg p-2'>
          <div className='md:mr-2 w-full'>
            < InputText nameLabel='Número de gravamen' register={register} name='numero_gravamen' message="El numero es requerido" errors={errors} Placeholder="1 y 2" />
          </div>
        </div>
      </div>
    </ModalEditNumberGravamen>
    </div >
  )
}
