import { useDispatch, useSelector } from 'react-redux';
import { obtenerPuestos, obtenerPuestosEdicion } from '../store/slice/puesto';

export const usePuesto = () => {

    const dispatch = useDispatch();
    const { puesto, loading, cantidad, totalPaginas, puestoIndividual } = useSelector((state) => state.puesto);

    const obtenerTotalPuestos = () => {
        dispatch(obtenerPuestos());
    }

    const obtenerTotalPuestosEdicion = (setValue, puesto) => {
        dispatch(obtenerPuestosEdicion(setValue, puesto));
    }

    return {
        //valores
        puesto,
        loading,
        cantidad,
        totalPaginas,
        puestoIndividual,

        //funciones
        obtenerTotalPuestos,
        obtenerTotalPuestosEdicion

    }
}
