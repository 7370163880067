import { createSlice } from '@reduxjs/toolkit';

export const colaboradorSlice = createSlice({
    name: 'colaborador',
    initialState: {
        colaborador: [],
        cantidad: null,
        totalPaginas: null,
        loading: false,
        colaboradorIndividual: {},
    },
    reducers: {
        colaboradorCredentials: (state, { payload }) => {
            state.colaborador = payload.colaborador;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        colaboradorFilstroCredentials: (state, { payload }) => {
            state.colaborador = payload.colaborador;
            state.loading = false;
        },
        colaboradorIndividualCredentials: (state, {payload}) => {
            state.colaboradorIndividual = payload.colaborador
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { colaboradorCredentials, colaboradorIndividualCredentials, isLoading, loaded, colaboradorFilstroCredentials } = colaboradorSlice.actions;