import { useForm } from "react-hook-form";
import { useSolicitud, useUser } from "../../../../hooks";
import { InputDate, InputDecimal, InputNumber, InputPDF, InputText, Lash, SelectOption } from "../../../../ui";
import { useEffect, useState } from "react";
import { decryptRolLocalStorage, departamentos, errorModal, questionModal, textAreaModal, textAreaModal2 } from "../../../../utils";
import { Tab } from "@headlessui/react";
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/outline'


let creditos = [{ credito: 'Crédito Nuevo' }, { credito: 'Recredito' }]
let inmueble = [{ tipo_inmueble: 'Finca registrada' }, { tipo_inmueble: 'Derechos de posesión' }]

let tabs = [];
export const EditarSolicitudAvaluo = ({ setShow3, setValue2 }) => {

    const { register, handleSubmit, formState: { errors }, watch, setValue, unregister } = useForm();
    const { solicitudIndividual, imagenes, comentarios, modificarValorSolicitud, editarNuevaSolicitud, obtenerTotalSolicitudes, eliminarDocumentoInformacion, rechazarSolicitudIndividual, obtenerComentariosSolicitud, respuestaComentario, ingresarSolicitudAvaluos } = useSolicitud();
    const { valuadores, obtenerTotalValuadores } = useUser();
    const [fileList, setFileList] = useState([]);
    let rol = decryptRolLocalStorage();
    let watchItem = watch();

    const [contador, setContador] = useState();
    const [datos, setDatos] = useState([]);

    if (rol === '4') {
        tabs = [
            { titulo: 'Datos de la solicitud de avalúo' },
            { titulo: 'Documento de la solicitud de avalúo' },
            { titulo: 'Registro de comentarios' }
        ];
    } else {
        tabs = [
            { titulo: 'Datos de la solicitud de avalúo' },
            { titulo: 'Registro de comentarios' }
        ];
    }

    useEffect(() => {
        setValue('correlativo', solicitudIndividual.correlativo);
        setValue('tipo_inmueble', solicitudIndividual.tipo_inmueble);

        if (solicitudIndividual.tipo_inmueble === 'Finca registrada') {
            let finca_folio_libro_valor = solicitudIndividual.finca_folio_libro.split(',');
            let valor_inmueble = solicitudIndividual.lugar_inmueble.split(',');
            setContador(finca_folio_libro_valor.length + 1);
            let auxDatos = [];
            for (let i = 0; i < finca_folio_libro_valor.length; i++) {
                auxDatos.push({ numero: i + 1 });

                let valor = finca_folio_libro_valor[i].split(" ");
                setValue(`finca${i + 1}`, valor[0]);
                setValue(`folio${i + 1}`, valor[1]);
                setValue(`libro${i + 1}`, valor[2]);

                let departamentoAux = '';
                for (let j = 3; j < valor.length; j++) {
                    if (j + 1 === valor.length) {
                        departamentoAux += valor[j];
                    } else {
                        departamentoAux += valor[j] + " ";
                    }
                }
                setValue(`departamento${i + 1}`, departamentoAux);
                setValue(`lugar_inmueble${i + 1}`, valor_inmueble[i]);
            }
            setDatos(auxDatos);

        } else {
            if (solicitudIndividual.numero_fecha_nombre_escritura !== undefined) {
                let numero_fecha_nombre_escritura_valor = solicitudIndividual.numero_fecha_nombre_escritura.split(',');
                let valor_inmueble = solicitudIndividual.lugar_inmueble.split(',');
                setContador(numero_fecha_nombre_escritura_valor.length + 1);
                let auxDatos = [];

                for (let i = 0; i < numero_fecha_nombre_escritura_valor.length; i++) {
                    auxDatos.push({ numero: i + 1 });

                    let valor = numero_fecha_nombre_escritura_valor[i].split(" ");
                    setValue(`numero_escritura${i + 1}`, valor[0]);
                    setValue(`fecha_escrituracion${i + 1}`, valor[1]);
                    setValue(`nombre_notario${i + 1}`, valor[2]);
                    setValue(`lugar_inmueble${i + 1}`, valor_inmueble[i]);
                }
                setDatos(auxDatos);
            }
        }

        if (rol === '4') {
            setValue('id_valuador', solicitudIndividual.id_valuador);
            setValue('cantidad_avaluos', solicitudIndividual.cantidad_avaluos)
        }

        setValue('solicitante', solicitudIndividual.solicitante);
        setValue('cif_solicitante', solicitudIndividual.cif_solicitante);
        setValue('telefono_solicitante', solicitudIndividual.telefono_solicitante);
        setValue('propietario', solicitudIndividual.propietario);
        setValue('numero_boleta_pago', solicitudIndividual.numero_boleta_pago);
        setValue('fecha_pago_avaluo', solicitudIndividual.fecha_pago_avaluo);
        setValue('cantidad_pago_avaluo', solicitudIndividual.cantidad_pago_avaluo);
        setValue('tipo_credito', solicitudIndividual.tipo_credito);

        if (solicitudIndividual.id_solicitud_avaluo !== undefined) {
            obtenerComentariosSolicitud(solicitudIndividual.id_solicitud_avaluo);
            if (rol === '4') {
                modificarValorSolicitud(solicitudIndividual.id_solicitud_avaluo);
            }
        }

        // eslint-disable-next-line
    }, [solicitudIndividual])

    useEffect(() => {
        obtenerTotalValuadores();
        // eslint-disable-next-line
    }, [])


    const agregarDatos = () => {
        setDatos([...datos, { numero: contador }]);
        setContador(contador + 1);
    }

    const quitarDatos = (item, index) => {
        if (item.numero === (contador - 1)) {
            let auxDatos = [...datos]
            auxDatos.splice(index, 1)
            setDatos(auxDatos)

            if (solicitudIndividual.tipo_inmueble === 'Finca registrada') {
                unregister(`finca${item.numero}`);
                unregister(`folio${item.numero}`);
                unregister(`libro${item.numero}`);
                unregister(`departamento${item.numero}`);
            } else {
                unregister(`numero_escritura${item.numero}`);
                unregister(`fecha_escrituracion${item.numero}`);
                unregister(`nombre_notario${item.numero}`);
            }
            unregister(`lugar_inmueble${item.numero}`);
            setContador(contador - 1)
        } else {
            errorModal('existen registros posteriores a este');
        }
    }

    return (
        <Lash arrays={tabs}>
            <Tab.Panel key={1}>
                <>
                    <div className='flex justify-center'>
                        <div className="w-full max-w-4xl bg-white rounded-xl shadow-2xl px-6 py-3 mb-5 mt-10">

                            <div className='mb-6 text-gray-400 font-bold sm:text-2xl text-xl flex justify-between'>
                                <h1>
                                    SOLICITUD DE AVALÚO
                                    <hr />
                                </h1>
                                <h6 className="text-xs">
                                    Estado: {solicitudIndividual.asignado === 1 ? 'Asignada' : solicitudIndividual.asignado === 0 ? 'Pendiente de asignación' : solicitudIndividual.asignado === 2 ? 'Rechazada' : solicitudIndividual.asignado === 3 ? 'Finalizada' : 'Ingresada'}
                                </h6>
                            </div>


                            <form onSubmit={handleSubmit((data) => questionModal(rol === '4' ? 'Asignación' : 'Edición', rol === '4' ? '¿Está seguro que desea asignar la solicitud de avalúo?' : '¿Está seguro que desea editar la solicitud de avalúo?', rol === '4' ? () => editarNuevaSolicitud(solicitudIndividual.id_solicitud_avaluo, data, setShow3, setValue2, obtenerTotalSolicitudes, watchItem, fileList, setFileList, contador, rol) : () => textAreaModal2(() => editarNuevaSolicitud(solicitudIndividual.id_solicitud_avaluo, data, setShow3, setValue2, obtenerTotalSolicitudes, watchItem, fileList, setFileList, contador, rol), respuestaComentario, solicitudIndividual.id_solicitud_avaluo)))}>
                                {
                                    rol === '4' ?
                                        solicitudIndividual.asignado === 3 ?
                                            null :
                                            <div className="flex flex-wrap">
                                                <div className=" w-full flex flex-wrap justify-end">
                                                    {
                                                        solicitudIndividual.asignado === 2 || solicitudIndividual.asignado === 1 || solicitudIndividual.asignado === 4 ?
                                                            null :
                                                            <button type="button" onClick={() => questionModal('Ingresar solicitud', '¿Desea ingresar la solicitud al sistema', () => ingresarSolicitudAvaluos(solicitudIndividual.id_solicitud_avaluo, setValue2, watchItem, setShow3))} className="font-bold text-white w-1/7 mr-1 px-4 py-2 bg-blue-900 rounded-md cursor-pointer hover:bg-blue-950 focus:outline-none focus:shadow-outline">
                                                                <div className="flex">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
                                                                    </svg>
                                                                    Ingresar
                                                                </div>
                                                            </button>
                                                    }
                                                    <button type="submit" className="font-bold text-white w-1/7 mr-1 px-4 py-2 bg-green-700 rounded-md cursor-pointer hover:bg-green-800 focus:outline-none focus:shadow-outline">
                                                        <div className="flex">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                            </svg>
                                                            Asignar
                                                        </div>
                                                    </button>
                                                    {
                                                        solicitudIndividual.asignado === 2 ?
                                                            null :
                                                            <button type="button" onClick={() => textAreaModal(rechazarSolicitudIndividual, obtenerTotalSolicitudes, solicitudIndividual.id_solicitud_avaluo, watchItem, setShow3, setValue2)} className="font-bold text-white w-1/7 px-4 py-2 bg-red-700 rounded-md cursor-pointer hover:bg-red-600 focus:outline-none focus:shadow-outline sm:mt-0 mt-1">
                                                                <div className="flex">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                    Rechazar
                                                                </div>
                                                            </button>
                                                    }
                                                </div>
                                                <div className="w-full flex flex-wrap">
                                                    <InputText nameLabel='No. avalúos' register={register} name="cantidad_avaluos" margenb='lg:mb-6 mb-2' message="requerido" errors={errors} Placeholder="Ej. 3" position="md:w-1/4" textOrientation='text-center' />
                                                    <SelectOption nameLabel='Asignar valuador' register={register} name='id_valuador' message='El valor es requerido' margenb='lg:mb-6 mb-2' errors={errors} array={valuadores} valueOption='id_usuario' position="md:w-3/4" nameOption='nombre' />
                                                </div>
                                            </div>
                                        : null
                                }
                                <div className="flex flex-wrap -mx-3 mb-6">
                                    <InputText nameLabel='Correlativo' register={register} name="correlativo" message="El correlativo es requerido" errors={errors} Placeholder="Ej. Pedro González Hernandez" position="md:w-1/5" disabled={true} textOrientation='text-center' />
                                    <SelectOption nameLabel='Tipo de inmueble' register={register} name='tipo_inmueble' message='El valor es requerido' errors={errors} array={inmueble} valueOption='tipo_inmueble' nameOption='tipo_inmueble' position="md:w-4/5" disabled={true} />

                                    <InputText nameLabel='Solicitante' register={register} name="solicitante" message="El solicitante es requerido" errors={errors} Placeholder="Ej. Pedro González Hernandez" />
                                    <InputNumber nameLabel='CIF del solicitante' register={register} name="cif_solicitante" message="El cif es requerido" errors={errors} Placeholder="Ej. 90785" position="md:w-1/2" />
                                    <InputText nameLabel='Teléfono del solicitante' register={register} name="telefono_solicitante" message="El telefono es requerido" errors={errors} Placeholder="Ej. 31769822" position="md:w-1/2" />
                                    <InputText nameLabel='Nombre(s) de propietario(s)' register={register} name="propietario" message="El propietario es requerido" errors={errors} Placeholder="Ej. Juan Fernández, Fredy Ortiz" />

                                    <div className='w-full bg-gray-400 rounded-md flex items-center justify-center mx-3 mb-3'>
                                        <label className='text-center block text-sm font-bold text-white'>
                                            {
                                                solicitudIndividual.tipo_inmueble === 'Finca registrada' ?
                                                    'Finca Folio Libro'
                                                    :
                                                    'No. escritura, fecha escritura, nombre notario'
                                            }
                                        </label>
                                        <button type='button' className='h-5 w-5 ml-2 text-green-700 hover:-translate-y-1 hover:scale-110 ease-in-out delay-150 duration-100' onClick={agregarDatos}>
                                            <PlusCircleIcon />
                                        </button>
                                    </div>
                                    {
                                        datos.map((item, index) =>
                                            solicitudIndividual.tipo_inmueble === 'Finca registrada' ?
                                                <div className="w-full flex flex-wrap border-2 rounded-xl pt-2 mb-5">
                                                    <InputText nameLabel='finca' register={register} name={`finca${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. finca 15" position="md:w-1/3" />
                                                    <InputText nameLabel='folio' register={register} name={`folio${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. folio 22" position="md:w-1/3" />
                                                    <InputText nameLabel={
                                                        <div className="flex justify-between">
                                                            Libro
                                                            {
                                                                index === 0 ? null :
                                                                    <button type='button' className='h-6 w-6 ml-5 text-red-600 hover:-translate-y-1 hover:scale-110 ease-in-out delay-150 duration-100' onClick={() => questionModal('Eliminar', '¿Está seguro que desea eliminar el registro?', () => { quitarDatos(item, index) })}>
                                                                        <XCircleIcon />
                                                                    </button>
                                                            }
                                                        </div>
                                                    }
                                                        register={register} name={`libro${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. libro 3E" position="md:w-1/3" />
                                                    <SelectOption nameLabel='departamento' register={register} name={`departamento${item.numero}`} message='El valor es requerido' errors={errors} array={departamentos} valueOption='departamento' nameOption='departamento' position="md:w-1/3" />
                                                    <InputText nameLabel={`Lugar del inmueble ${item.numero}`} register={register} name={`lugar_inmueble${item.numero}`} message="El lugar es requerido" errors={errors} Placeholder="Ej. BARRIO SAN MARCOS ZONA 4 COBAN AV" />
                                                </div>
                                                :
                                                <div className="w-full flex flex-wrap border-2 rounded-xl pt-2 mb-5">
                                                    <InputText nameLabel='No. de escritura' register={register} name={`numero_escritura${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. 558874" position="md:w-1/3" />
                                                    <InputDate nameLabel='Fecha de la escrituración' register={register} name={`fecha_escrituracion${item.numero}`} message="La fecha es requerida" position="md:w-1/3" errors={errors} />
                                                    <InputText nameLabel={
                                                        <div className="flex justify-between">
                                                            Nombre del notario
                                                            {
                                                                index === 0 ? null :
                                                                    <button type='button' className='h-6 w-6 ml-5 text-red-600 hover:-translate-y-1 hover:scale-110 ease-in-out delay-150 duration-100' onClick={() => questionModal('Eliminar', '¿Está seguro que desea eliminar el registro?', () => { quitarDatos(item, index) })}>
                                                                        <XCircleIcon />
                                                                    </button>
                                                            }
                                                        </div>
                                                    }
                                                        register={register} name={`nombre_notario${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. Lic. Fausto Hernández" position="md:w-1/3" />
                                                    <InputText nameLabel={`Lugar del inmueble ${item.numero}`} register={register} name={`lugar_inmueble${item.numero}`} message="El lugar es requerido" errors={errors} Placeholder="Ej. BARRIO SAN MARCOS ZONA 4 COBAN AV" />
                                                </div>
                                        )
                                    }

                                    <InputText nameLabel='Número de boleta' register={register} name="numero_boleta_pago" message="El número es requerido" errors={errors} Placeholder="Ej. 2125862" position="md:w-1/2" disabled={rol === '4' ? false : true} />
                                    <InputDate nameLabel='Fecha de la boleta de pago' register={register} name="fecha_pago_avaluo" message="La fecha es requerida" position="md:w-1/2" errors={errors} disabled={rol === '4' ? false : true} />
                                    <InputDecimal nameLabel='Monto del pago' register={register} name="cantidad_pago_avaluo" message="El monto es requerido" errors={errors} Placeholder="Ej. 125" position="md:w-1/2" disabled={rol === '4' ? false : true} />
                                    <SelectOption nameLabel='Tipo de crédito' register={register} name='tipo_credito' message='El valor es requerido' errors={errors} array={creditos} valueOption='credito' nameOption='credito' position="md:w-1/2" disabled={rol === '4' ? false : true} />

                                    {
                                        rol !== '4' ?
                                            <>
                                                {
                                                    imagenes.length <= 0 ?
                                                        <InputPDF fileList={fileList} setFileList={setFileList} nameLabel={solicitudIndividual.tipo_inmueble === 'Finca registrada' ? 'DPI, CONSULTA ELECTRÓNICA, PLANO Y BOLETAS DE PAGO' : "DPI, CONSTANCIA DE CATASTRO/DECLARACIÓN JURADA, ESCRITURAS Y PLANO"} />
                                                        :
                                                        null
                                                }

                                                <div className='w-full pr-2 mt-2 mb-6'>
                                                    <div className="my-2 w-full px-3 ">
                                                        <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">DOCUMENTOS GUARDADOS</h1>
                                                    </div>
                                                    <div className='flex flex-wrap place-content-center w-full'>
                                                        {
                                                            imagenes ?
                                                                imagenes.map((item, index) =>
                                                                    <div key={index} className='relative  pt-5 pr-5 m-1 cursor-auto z-10'>
                                                                        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png' className="h-28 w-24  shadow-xl animate__animated animate__bounce" alt='doc' />
                                                                        <button type="button" className='h-7 w-7 bg-red-500 text-white rounded-full absolute top-0 right-0 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-red-700 duration-300'
                                                                            onClick={() => questionModal('Eliminar', 'Desea eliminar el documento cargado?', () => eliminarDocumentoInformacion(item.id_imagen_solicitud_avaluo, solicitudIndividual.id_solicitud_avaluo))}
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                ) :
                                                                <p className="mt-2 text-gray-500 tracking-wide">Sin documentos</p>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="w-full md:w-full px-3">
                                                    <div className="text-center flex">
                                                        <input
                                                            className="w-full mr-1 px-3 py-2 font-bold text-white bg-blue-900 rounded-md cursor-pointer hover:bg-blue-800 focus:outline-none focus:shadow-outline"
                                                            type="submit"
                                                            value='Editar'
                                                        />
                                                        <button type="button" onClick={() => setShow3(false)} className="font-bold text-white w-full px-4 py-2 bg-red-700 rounded-md cursor-pointer hover:bg-red-600 focus:outline-none focus:shadow-outline">
                                                            Cerrar
                                                        </button>
                                                    </div>
                                                </div>

                                            </>
                                            :
                                            null

                                    }


                                </div>
                            </form>
                        </div>
                    </div>

                </>
            </Tab.Panel>

            {
                rol === '4' ?
                    <>
                        <Tab.Panel key={2}>
                            <>
                                <div className='flex justify-center'>
                                    <div className="w-full bg-white rounded-xl shadow-2xl px-6 py-3 mb-5 mt-2">
                                        <div className="flex -mx-3 mb-6 justify-center">
                                            {
                                                imagenes.map((item, index) =>
                                                    <iframe
                                                        key={index}
                                                        title="PDF Preview"
                                                        src={item.url}
                                                        width="90%"
                                                        height="1000px"
                                                    ></iframe>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                            </>
                        </Tab.Panel>
                        <Tab.Panel key={3}>
                            {
                                comentarios.map((item, index) => (
                                    <>
                                        <div key={index} className={`flex ${item.usuario.rol.rol === "4" ? 'justify-start' : 'justify-end'} mt-6 mb-4`}>
                                            {
                                                item.usuario.rol.rol === "4" ?
                                                    <>
                                                        <img
                                                            src="https://yt3.googleusercontent.com/HWNwre6rXvQv1tbt9KpFjW5YLxSfhmpPYvL4rjbR8wXOA9jK1eIdjlJKKDtM80Nod4-dDRYF6w=s900-c-k-c0x00ffffff-no-rj"
                                                            className="object-cover h-8 w-8 rounded-full"
                                                            alt="imagen"
                                                        />
                                                        <div className="flex flex-wrap ml-2 py-3 px-4 bg-blue-400 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white">
                                                            <div>
                                                                {item.comentario}
                                                            </div>
                                                            <div className="w-full text-end text-[10px]">
                                                                {item.usuario.rol.descripcion} | {item.fecha_comentario}
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="flex flex-wrap mr-2 py-3 px-4 bg-green-600 rounded-bl-3xl rounded-tr-xl rounded-tl-3xl text-white">
                                                            <div>
                                                                {item.comentario}
                                                            </div>
                                                            <div className="w-full text-end text-[10px]">
                                                                {item.usuario.rol.descripcion} | {item.fecha_comentario}
                                                            </div>
                                                        </div>
                                                        <img
                                                            src="https://yt3.googleusercontent.com/HWNwre6rXvQv1tbt9KpFjW5YLxSfhmpPYvL4rjbR8wXOA9jK1eIdjlJKKDtM80Nod4-dDRYF6w=s900-c-k-c0x00ffffff-no-rj"
                                                            className="object-cover h-8 w-8 rounded-full"
                                                            alt="imagen"
                                                        />
                                                    </>
                                            }
                                        </div>
                                    </>
                                ))
                            }
                        </Tab.Panel>
                    </>

                    :

                    <Tab.Panel key={2}>
                        {
                            comentarios.map((item, index) => (
                                <>
                                    <div key={index} className={`flex ${item.usuario.rol.rol === "4" ? 'justify-start' : 'justify-end'} mt-6 mb-4`}>
                                        {
                                            item.usuario.rol.rol === "4" ?
                                                <>
                                                    <img
                                                        src="https://yt3.googleusercontent.com/HWNwre6rXvQv1tbt9KpFjW5YLxSfhmpPYvL4rjbR8wXOA9jK1eIdjlJKKDtM80Nod4-dDRYF6w=s900-c-k-c0x00ffffff-no-rj"
                                                        className="object-cover h-8 w-8 rounded-full"
                                                        alt="imagen"
                                                    />
                                                    <div className="flex flex-wrap ml-2 py-3 px-4 bg-blue-400 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white">
                                                        <div>
                                                            {item.comentario}
                                                        </div>
                                                        <div className="w-full text-end text-[10px]">
                                                            {item.usuario.rol.descripcion} | {item.fecha_comentario}
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="flex flex-wrap mr-2 py-3 px-4 bg-green-600 rounded-bl-3xl rounded-tr-xl rounded-tl-3xl text-white">
                                                        <div>
                                                            {item.comentario}
                                                        </div>
                                                        <div className="w-full text-end text-[10px]">
                                                            {item.usuario.rol.descripcion} | {item.fecha_comentario}
                                                        </div>
                                                    </div>
                                                    <img
                                                        src="https://yt3.googleusercontent.com/HWNwre6rXvQv1tbt9KpFjW5YLxSfhmpPYvL4rjbR8wXOA9jK1eIdjlJKKDtM80Nod4-dDRYF6w=s900-c-k-c0x00ffffff-no-rj"
                                                        className="object-cover h-8 w-8 rounded-full"
                                                        alt="imagen"
                                                    />
                                                </>
                                        }
                                    </div>
                                </>
                            ))
                        }
                    </Tab.Panel>
            }

        </Lash>
    )
}
