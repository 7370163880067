import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { loaded } from "../auth";
import { descripcionReferencialCredentials, isLoading, valorInmuebleCredentials, valorInmuebleIndividualCredentials } from "./valorInmuebleSlice";


export const obtenerValoresInmueble = (id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/valorinmuebleterreno', { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(valorInmuebleCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('error');
        }
    }
}

export const crearDescripcionReferencia = (id_avaluo, data, setShow) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/descripcionreferencial/crear', data, { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            dispatch(obtenerValoresInmueble(id_avaluo));
            setShow(false);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const obtenerDescripcionReferencial = (id_descripcion_referencial, setShow2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/descripcionreferencial/individual', { headers: { 'token': getToken() }, params: { id_descripcion_referencial } });
            dispatch(descripcionReferencialCredentials(data));
            setShow2(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('error');
        }
    }
}

export const editarDescripcionReferencial = (id_descripcion_referencial, id_avaluo, data, setShow2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/descripcionreferencial/editar', data, { headers: { 'token': getToken() }, params: { id_descripcion_referencial } });
            dispatch(loaded())
            successModal('Registro editado correctamente', 'center');
            dispatch(obtenerValoresInmueble(id_avaluo));
            setShow2(false);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded())
            errorModal('error al hacer la edición');
        }
    }
}

export const eliminarDescripcionReferencial = (id_descripcion_referencial, id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/descripcionreferencial/eliminar', { headers: { 'token': getToken() }, params: { id_descripcion_referencial } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerValoresInmueble(id_avaluo));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('error al hacer la eliminación');
        }
    }
}

export const crearMemoriaCalculo = (id_avaluo, data, setShow4) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/valorinmuebleterreno/crear', data, { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            dispatch(obtenerValoresInmueble(id_avaluo));
            setShow4(false);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const editarMemoriaCalculo = (id_avaluo, data, setShow5, id_valor_inmueble_terreno) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/valorinmuebleterreno/editar', data, { headers: { 'token': getToken() }, params: { id_valor_inmueble_terreno} });
            dispatch(loaded())
            successModal('Registro editado correctamente', 'center');
            dispatch(obtenerValoresInmueble(id_avaluo));
            setShow5(false);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const eliminarValorInmueble = (id_valor_inmueble_terreno, id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/valorinmuebleterreno/eliminar', { headers: { 'token': getToken() }, params: { id_valor_inmueble_terreno } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerValoresInmueble(id_avaluo));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('error al hacer la eliminación');
        }
    }
}

export const obtenerValorInmuebleIndividual = (id_valor_inmueble_terreno, setShow5) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/valorinmuebleterreno/individual', { headers: { 'token': getToken() }, params: { id_valor_inmueble_terreno } });
            dispatch(valorInmuebleIndividualCredentials(data));
            setShow5(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('error');
        }
    }
}