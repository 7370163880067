import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TdTable, ThTable, TrTable } from "../../components";
import { InputDate2, InputSearch2, Loader, NormalButton, Pagination, SelectOption4, TotalPagesAndData, TransitionPopover } from "../../../../ui";
import { useAgencia, useSolicitud, useUser } from "../../../../hooks";
import { CrearSolicitudAvaluo } from "./CrearSolicitudAvaluo";
import { VisualizarSolicitudAvaluo } from "./VisualizarSolicitudAvaluo";
import { EditarSolicitudAvaluo } from "./EditarSolicitudAvaluo";
import { decryptRolLocalStorage } from "../../../../utils";


let fases = [{ id_fase: 0, fase: 'Pendientes de revisión' }, { id_fase: 4, fase: 'Solicitudes ingresadas' }, { id_fase: 1, fase: 'Solicitudes asignadas' }, { id_fase: 2, fase: 'Solicitudes rechazadas' }, { id_fase: 3, fase: 'Solicitudes finalizadas' }]
export const SolicitudAvaluo = () => {

    const { register, watch, setValue } = useForm();
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const { solicitud_avaluos, cantidad, totalPaginas, loading, obtenerTotalSolicitudes, obtenerNuevaSolicitud, limpiarTotalSolicitudIndividual } = useSolicitud();
    const { agencia, obtenerTotalAgencias } = useAgencia();
    const { asesores, obtenerTotalAsesores } = useUser();
    let rol = decryptRolLocalStorage();
    let watchItems = watch();


    useEffect(() => {
        obtenerTotalSolicitudes(watchItems.buscador, "", page, watchItems.id_usuario, watchItems.id_agencia, watchItems.fechaA, watchItems.fechaB, watchItems.asignado);
        // eslint-disable-next-line
    }, [watchItems.buscador, watchItems.id_agencia, watchItems.id_usuario, watchItems.asignado, watchItems.fechaA, watchItems.fechaB, page]);

    useEffect(() => {
        setPage(1);
    }, [watchItems.buscador, watchItems.id_agencia, watchItems.id_usuario, watchItems.asignado, watchItems.fechaA, watchItems.fechaB]);

    useEffect(() => {
        obtenerTotalAgencias();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (watchItems.id_agencia === undefined || watchItems.id_agencia === '') {
            obtenerTotalAsesores('');
        } else {
            obtenerTotalAsesores(watchItems.id_agencia);
        }
        // eslint-disable-next-line
    }, [watchItems.id_agencia])

    const obtenerInfoSolicitud = (id, setShow) => {
        obtenerNuevaSolicitud(id);
        setShow(true);
    }

    return (
        <>
            {loading && <Loader />}
            <div className='mx-20 my-14'>
                <div className='my-5 text-gray-400 font-bold text-4xl'>
                    <h1>
                        SOLICITUD DE AVALÚO
                        <hr className='w-1/3' />
                    </h1>

                </div>
                <div className="flex flex-wrap justify-between">
                    <div className={`flex lg:flex-nowrap lg:justify-start flex-wrap ${rol !== '4' ? 'lg:w-3/4' : ''} w-full`}>
                        <InputSearch2 nameLabel='buscador' register={register} name="buscador" position='lg:w-1/3 mb-3 font-semibold text-gray-500 mx-1' />

                        {
                            rol !== '3' ?
                                <SelectOption4 nameLabel='fase' register={register} name="asignado" array={fases} valueOption='id_fase' nameOption='fase' position='lg:w-1/3 mb-3 font-semibold text-gray-500 mx-1' />
                                :
                                null
                        }
                        {
                            rol === '4' || rol === '3' ?
                                <>
                                    <SelectOption4 nameLabel='agencia' register={register} name="id_agencia" array={agencia} valueOption='id_agencia' nameOption='agencia' position='lg:w-1/3 mb-3 font-semibold text-gray-500 mx-1' />
                                    <SelectOption4 nameLabel='asesor' register={register} name="id_usuario" array={asesores} valueOption='id_usuario' nameOption='nombre' position='lg:w-1/3 mb-3 font-semibold text-gray-500 mx-1' />
                                    <InputDate2 nameLabel='desde' register={register} name='fechaA' position='lg:w-1/5' />
                                    <InputDate2 nameLabel='hasta' register={register} name='fechaB' position='lg:w-1/5' />
                                </>
                                : null
                        }
                    </div>

                    {
                        rol !== '4' && rol !== '3' && rol !== '2' ?
                            <div>
                                <NormalButton funcion={() => setShow(true)} bgColor='bg-blue-900' bgText='text-white font-semibold' dimension="px-4 py-2 lg:mt-7 mb-2 mx-1 rounded" hoverColor='hover:bg-blue-800' title={
                                    <div className="flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                        </svg>
                                        Crear Solicitud
                                    </div>
                                } />
                            </div>
                            : null
                    }

                </div>
                <table className={"border-collapse w-full"}>
                    <thead>
                        <tr>
                            <ThTable titulo='Correlativo' />
                            <ThTable titulo='Fecha de ingreso' />
                            <ThTable titulo='Propietario' />
                            <ThTable titulo='Solicitante' />
                            <ThTable titulo='CIF del solicitante' />
                            {
                                rol === '4' || rol === '3' ?
                                    <>
                                        <ThTable titulo='Asesor' />
                                        <ThTable titulo='Agencia' />
                                    </>
                                    :
                                    null
                            }
                            <ThTable titulo='Estado' />
                            <ThTable titulo='Valuador asignado' />
                            <ThTable titulo='Acciones' />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            solicitud_avaluos.map((item, index) => (
                                <TrTable key={index} bg={item.asignado === 2 ? 'hover:bg-red-200 bg-red-100' : item.asignado === 3 ? 'hover:bg-green-200 bg-green-100' : item.asignado === 4 ? 'hover:bg-yellow-200 bg-yellow-100' : item.asignado === 1 ? 'hover:bg-orange-100 bg-orange-50' :'hover:bg-gray-100 bg-white'}>
                                    <TdTable titulo='Correlativo' valor={item.correlativo} lectura={rol === '4' ? item.lectura : 1} />
                                    <TdTable titulo='Fecha de ingreso' valor={item.fecha_ingreso_solicitud} lectura={rol === '4' ? item.lectura : 1} />
                                    <TdTable titulo='Propietario' valor={item.propietario} lectura={rol === '4' ? item.lectura : 1} />
                                    <TdTable titulo='Solitante' valor={item.solicitante} lectura={rol === '4' ? item.lectura : 1} />
                                    <TdTable titulo='CIF del solitante' valor={item.cif_solicitante} lectura={rol === '4' ? item.lectura : 1} />
                                    {
                                        rol === '4' || rol === '3' ?
                                            <>
                                                <TdTable titulo='Asesor' valor={item.usuario.nombre} lectura={rol === '4' ? item.lectura : 1} />
                                                <TdTable titulo='Agencia' valor={item.agencium.agencia} lectura={rol === '4' ? item.lectura : 1} />
                                            </>
                                            :
                                            null
                                    }
                                    <TdTable titulo='Estado' valor={item.asignado === 0 ? 'Pendiente de revisión' : item.asignado === 1 ? 'Asignada' : item.asignado === 2 ? 'Rechazada' : item.asignado === 3 ? 'Finalizada' : 'Ingresada'} lectura={rol === '4' ? item.lectura : 1} />
                                    <TdTable titulo='Valuador asignado' valor={item.valuador === null ? 'Pendiente' : item.valuador.nombre} lectura={rol === '4' ? item.lectura : 1} />
                                    <TdTable valor={
                                        <div className='flex justify-center mx-2'>

                                            {
                                                rol !== '4' ?
                                                    <>
                                                        <div className="w-4 mr-3 transform hover:text-green-500 hover:scale-110 ">
                                                            <svg onClick={() => obtenerInfoSolicitud(item.id_solicitud_avaluo, setShow2)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            </svg>
                                                        </div>
                                                        {
                                                            item.asignado === 2 && (rol !== '3' && rol !=='2') ?
                                                                <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110 ">
                                                                    <svg onClick={() => obtenerInfoSolicitud(item.id_solicitud_avaluo, setShow3)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                    </svg>

                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                    :
                                                    <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110 ">
                                                        <svg onClick={() => obtenerInfoSolicitud(item.id_solicitud_avaluo, setShow3)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                                        </svg>
                                                    </div>

                                            }

                                        </div>
                                    } />
                                </TrTable>
                            ))
                        }
                    </tbody>
                </table>
                <div className="flex justify-end mt-3">
                    <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
                </div>
                <div className="flex justify-end mt-3">
                    <TotalPagesAndData amount={cantidad} page={page} totPages={totalPaginas} />
                </div>

                <TransitionPopover show={show} setShow={setShow}>
                    <CrearSolicitudAvaluo setShow={setShow} setValue2={setValue} />
                </TransitionPopover>

                <TransitionPopover show={show2} setShow={setShow2} limpiar={limpiarTotalSolicitudIndividual}>
                    <VisualizarSolicitudAvaluo setShow2={setShow2} />
                </TransitionPopover>

                <TransitionPopover show={show3} setShow={setShow3} limpiar={limpiarTotalSolicitudIndividual}>
                    <EditarSolicitudAvaluo setShow3={setShow3} setValue2={setValue} />
                </TransitionPopover>
            </div>
        </>
    )
}
