import { createSlice } from '@reduxjs/toolkit';

export const notificacionSlice = createSlice({
    name: 'notificacion',
    initialState: {
        notificaciones: [],
        cantidad_notificaciones: null,
        cantidad_notificaciones_completadas: null,
        confirmacion: null,
        loading: false,
    },
    reducers: {
        notificacionCredentials: (state, { payload }) => {
            state.notificaciones = payload.notificaciones;
            state.cantidad_notificaciones = payload.cantidad_notificaciones;
            state.cantidad_notificaciones_completadas = payload.cantidad_notificaciones_completadas;
            state.confirmacion = payload.confirmacion;
            state.loading = false;
        },
        modificarNotificacionesCredentials: (state, { payload }) => {
            state.notificaciones = payload;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});


// Action creators are generated for each case reducer function
export const { notificacionCredentials, isLoading, loaded, modificarNotificacionesCredentials } = notificacionSlice.actions;