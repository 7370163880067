import { useDispatch, useSelector } from "react-redux";
import { correlativosAsignados, crearSolicitudes, editarSolicitud, eliminarDocumento, ingresarSolicitud, limpiarSolicitudIndividual, modificarValor, obtenerComentarios, obtenerSolicitud, obtenerSolicitudes, rechazarSolicitud, responderComentario } from "../store/slice/solicitud";

export const useSolicitud = () => {

    const dispatch = useDispatch();
    const { solicitud_avaluos, cantidad, totalPaginas, loading, solicitudIndividual, imagenes, comentarios, correlativos } = useSelector((state) => state.solicitud);

    const obtenerTotalSolicitudes = (busqueda, limite, pagina, id_usuario, id_agencia, fechaA, fechaB, asignado) => {
        dispatch(obtenerSolicitudes(busqueda, limite, pagina, id_usuario, id_agencia, fechaA, fechaB, asignado));
    }

    const crearNuevaSolicitud = (data, setShow, setValue2, funcion, watchItem, fileList, setFileList, contador) => {
        dispatch(crearSolicitudes(data, setShow, setValue2, funcion, watchItem, fileList, setFileList, contador));
    }

    const editarNuevaSolicitud = (id_solicitud_avaluo, data, setShow3, setValue, funcion, watchItem, fileList, setFileList, contador, rol) => {
        dispatch(editarSolicitud(id_solicitud_avaluo, data, setShow3, setValue, funcion, watchItem, fileList, setFileList, contador, rol));
    }

    const obtenerNuevaSolicitud = (id_solicitud_avaluo) => {
        dispatch(obtenerSolicitud(id_solicitud_avaluo));
    }

    const eliminarDocumentoInformacion = (id_imagen_solicitud_avaluo, id_solicitud_avaluo) => {
        dispatch(eliminarDocumento(id_imagen_solicitud_avaluo, id_solicitud_avaluo));
    }

    const limpiarTotalSolicitudIndividual = () => {
        dispatch(limpiarSolicitudIndividual());
    }

    const rechazarSolicitudIndividual = (funcion, id_solicitud_avaluo, data, watchItems, setShow3, setValue2) => {
        dispatch(rechazarSolicitud(funcion, id_solicitud_avaluo, data, watchItems, setShow3, setValue2));
    }

    const respuestaComentario = (id_solicitud_avaluo, data) => {
        dispatch(responderComentario(id_solicitud_avaluo, data));
    }

    const obtenerComentariosSolicitud = (id_solicitud_avaluo) => {
        dispatch(obtenerComentarios(id_solicitud_avaluo));
    }

    const totalCorrelativosAsignados = () => {
        dispatch(correlativosAsignados());
    }

    const modificarValorSolicitud = (id) => {
        dispatch(modificarValor(solicitud_avaluos, id));
    }

    const ingresarSolicitudAvaluos = (id_solicitud_avaluo, setValue2, watchItems, setShow3) => {
        dispatch(ingresarSolicitud(id_solicitud_avaluo, setValue2, watchItems, setShow3))
    }

    return {
        //valores
        solicitud_avaluos,
        cantidad,
        totalPaginas,
        loading,
        solicitudIndividual,
        imagenes,
        comentarios,
        correlativos,

        //funciones
        obtenerTotalSolicitudes,
        crearNuevaSolicitud,
        editarNuevaSolicitud,
        obtenerNuevaSolicitud,
        eliminarDocumentoInformacion,
        limpiarTotalSolicitudIndividual,
        rechazarSolicitudIndividual,
        obtenerComentariosSolicitud,
        respuestaComentario,
        totalCorrelativosAsignados,
        modificarValorSolicitud,
        ingresarSolicitudAvaluos
    }
}
