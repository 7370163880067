import { createSlice } from '@reduxjs/toolkit';

export const anexoSlice = createSlice({
    name: 'anexo',
    initialState: {
        anexo: [],
        loading: false,
        anexoIndividual: {},
        imagenes: []
    },
    reducers: {
        anexoCredentials: (state, { payload }) => {
            state.anexo = payload.anexo;
            state.loading = false;
        },
        anexoIndividualCredentials: (state, { payload }) => {
            state.anexoIndividual = payload.anexoIndividual;
            state.imagenes = payload.imagenes;
            state.loading = false;
        },
        imagenCredentials: (state, { payload }) => {
            state.imagenes = payload.imagenes;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { anexoCredentials, anexoIndividualCredentials, isLoading, loaded, imagenCredentials } = anexoSlice.actions;