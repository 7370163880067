import { useForm } from "react-hook-form";
import { useSolicitud } from "../../../../hooks";
import { InputDate, InputDecimal, InputNumber, InputText, Lash, SelectOption } from "../../../../ui";
import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { decryptRolLocalStorage, departamentos } from "../../../../utils";


let creditos = [{ credito: 'Crédito Nuevo' }, { credito: 'Recredito' }]
let tabs = [
    { titulo: 'Datos de la solicitud de avalúo' },
    { titulo: 'Documento de la solicitud de avalúo' }
];
let inmueble = [{ tipo_inmueble: 'Finca registrada' }, { tipo_inmueble: 'Derechos de posesión' }]

export const VisualizarSolicitudAvaluo = ({ setShow2 }) => {

    const { register, formState: { errors }, setValue } = useForm();
    const { solicitudIndividual, imagenes } = useSolicitud();
    const [datos, setDatos] = useState([]);
    let rol = decryptRolLocalStorage();

    useEffect(() => {
        if (rol === '3') {
            setValue('cantidad_avaluos', solicitudIndividual.cantidad_avaluos);
        }
        setValue('correlativo', solicitudIndividual.correlativo);
        setValue('tipo_inmueble', solicitudIndividual.tipo_inmueble);

        if (solicitudIndividual.tipo_inmueble === 'Finca registrada') {
            let finca_folio_libro_valor = solicitudIndividual.finca_folio_libro.split(',');
            let valor_inmueble = solicitudIndividual.lugar_inmueble.split(',');
            let auxDatos = [];
            for (let i = 0; i < finca_folio_libro_valor.length; i++) {
                auxDatos.push({ numero: i + 1 });

                let valor = finca_folio_libro_valor[i].split(" ");
                setValue(`finca${i + 1}`, valor[0]);
                setValue(`folio${i + 1}`, valor[1]);
                setValue(`libro${i + 1}`, valor[2]);

                let departamentoAux = '';
                for (let j = 3; j < valor.length; j++) {
                    if (j + 1 === valor.length) {
                        departamentoAux += valor[j];
                    } else {
                        departamentoAux += valor[j] + " ";
                    }
                }

                setValue(`departamento${i + 1}`, departamentoAux);
                setValue(`lugar_inmueble${i + 1}`, valor_inmueble[i]);
            }
            setDatos(auxDatos);

        } else {
            if (solicitudIndividual.numero_fecha_nombre_escritura !== undefined) {
                let numero_fecha_nombre_escritura_valor = solicitudIndividual.numero_fecha_nombre_escritura.split(',');
                let valor_inmueble = solicitudIndividual.lugar_inmueble.split(',');
                let auxDatos = [];

                for (let i = 0; i < numero_fecha_nombre_escritura_valor.length; i++) {
                    auxDatos.push({ numero: i + 1 });

                    let valor = numero_fecha_nombre_escritura_valor[i].split(" ");
                    setValue(`numero_escritura${i + 1}`, valor[0]);
                    setValue(`fecha_escrituracion${i + 1}`, valor[1]);
                    setValue(`nombre_notario${i + 1}`, valor[2]);
                    setValue(`lugar_inmueble${i + 1}`, valor_inmueble[i]);
                }
                setDatos(auxDatos);
            }
        }

        setValue('solicitante', solicitudIndividual.solicitante);
        setValue('cif_solicitante', solicitudIndividual.cif_solicitante);
        setValue('telefono_solicitante', solicitudIndividual.telefono_solicitante);
        setValue('propietario', solicitudIndividual.propietario);
        setValue('numero_boleta_pago', solicitudIndividual.numero_boleta_pago);
        setValue('fecha_pago_avaluo', solicitudIndividual.fecha_pago_avaluo);
        setValue('cantidad_pago_avaluo', solicitudIndividual.cantidad_pago_avaluo);
        setValue('tipo_credito', solicitudIndividual.tipo_credito);
        // eslint-disable-next-line
    }, [solicitudIndividual])



    return (

        <Lash arrays={tabs}>
            <Tab.Panel key={1}>
                <>
                    <div className='flex justify-center'>
                        <div className="w-full max-w-4xl bg-white rounded-xl shadow-2xl px-6 py-3 mb-5 mt-10">
                            <div className='mb-6 text-gray-400 font-bold sm:text-2xl text-xl'>
                                <h1>
                                    DATOS SOLICITUD DE AVALÚO
                                    <hr />
                                </h1>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                {
                                    rol === '3' ?
                                        <div className="w-full">
                                            <InputText nameLabel='Avalúos solicitados' register={register} name="cantidad_avaluos" message="La cantidad es requerida" errors={errors} Placeholder="Ej. 3" position="md:w-1/4" disabled={true} textOrientation='text-center' />
                                        </div>
                                        :
                                        null
                                }
                                <InputText nameLabel='Correlativo' register={register} name="correlativo" message="El correlativo es requerido" errors={errors} Placeholder="Ej. Pedro González Hernandez" position="md:w-1/5" disabled={true} textOrientation='text-center' />
                                <SelectOption nameLabel='Tipo de inmueble' register={register} name='tipo_inmueble' message='El valor es requerido' errors={errors} array={inmueble} valueOption='tipo_inmueble' nameOption='tipo_inmueble' position="md:w-4/5" disabled={true} />

                                <InputText nameLabel='Solicitante' register={register} name="solicitante" message="El solicitante es requerido" errors={errors} Placeholder="Ej. Pedro González Hernandez" disabled={true} />
                                <InputNumber nameLabel='CIF del solicitante' register={register} name="cif_solicitante" message="El cif es requerido" errors={errors} Placeholder="Ej. 90785" position="md:w-1/2" disabled={true} />
                                <InputText nameLabel='Teléfono del solicitante' register={register} name="telefono_solicitante" message="El telefono es requerido" errors={errors} Placeholder="Ej. 31769822" position="md:w-1/2" disabled={true} />
                                <InputText nameLabel='Nombre(s) de propietario(s)' register={register} name="propietario" message="El propietario es requerido" errors={errors} Placeholder="Ej. Juan Fernández, Fredy Ortiz" disabled={true} />

                                <div className='w-full bg-gray-400 rounded-md flex items-center justify-center mx-3 mb-3'>
                                    <label className='text-center block text-sm font-bold text-white'>
                                        {
                                            solicitudIndividual.tipo_inmueble === 'Finca registrada' ?
                                                'Finca Folio Libro'
                                                :
                                                'No. escritura, fecha escritura, nombre notario'
                                        }
                                    </label>
                                </div>
                                {
                                    datos.map((item) =>
                                        solicitudIndividual.tipo_inmueble === 'Finca registrada' ?
                                            <div className="w-full flex flex-wrap border-2 rounded-xl pt-2 mb-5">
                                                <InputText nameLabel='finca' register={register} name={`finca${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. finca 15" position="md:w-1/3" disabled={true} />
                                                <InputText nameLabel='folio' register={register} name={`folio${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. folio 22" position="md:w-1/3" disabled={true} />
                                                <InputText nameLabel='libro' register={register} name={`libro${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. libro 3E" position="md:w-1/3" disabled={true} />
                                                <SelectOption nameLabel='departamento' register={register} name={`departamento${item.numero}`} message='El valor es requerido' errors={errors} array={departamentos} valueOption='departamento' nameOption='departamento' position="md:w-1/3" disabled={true} />
                                                <InputText nameLabel={`Lugar del inmueble ${item.numero}`} register={register} name={`lugar_inmueble${item.numero}`} message="El lugar es requerido" errors={errors} Placeholder="Ej. BARRIO SAN MARCOS ZONA 4 COBAN AV" disabled={true} />
                                            </div>
                                            :
                                            <div className="w-full flex flex-wrap border-2 rounded-xl pt-2 mb-5">
                                                <InputText nameLabel='No. de escritura' register={register} name={`numero_escritura${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. 558874" position="md:w-1/3" disabled={true} />
                                                <InputDate nameLabel='Fecha de la escrituración' register={register} name={`fecha_escrituracion${item.numero}`} message="La fecha es requerida" position="md:w-1/3" errors={errors} disabled={true} />
                                                <InputText nameLabel='Nombre del notario' register={register} name={`nombre_notario${item.numero}`} message="La información es requerida" errors={errors} Placeholder="Ej. Lic. Fausto Hernández" position="md:w-1/3" disabled={true} />
                                                <InputText nameLabel={`Lugar del inmueble ${item.numero}`} register={register} name={`lugar_inmueble${item.numero}`} message="El lugar es requerido" errors={errors} Placeholder="Ej. BARRIO SAN MARCOS ZONA 4 COBAN AV" disabled={true} />
                                            </div>
                                    )
                                }

                                <InputText nameLabel='Número de boleta' register={register} name="numero_boleta_pago" message="El número es requerido" errors={errors} Placeholder="Ej. 2125862" position="md:w-1/2" disabled={true} />
                                <InputDate nameLabel='Fecha de la boleta de pago' register={register} name="fecha_pago_avaluo" message="La fecha es requerida" position="md:w-1/2" errors={errors} disabled={true} />
                                <InputDecimal nameLabel='Monto del pago' register={register} name="cantidad_pago_avaluo" message="El monto es requerido" errors={errors} Placeholder="Ej. 125" position="md:w-1/2" disabled={true} />
                                <SelectOption nameLabel='Tipo de crédito' register={register} name='tipo_credito' message='El valor es requerido' errors={errors} array={creditos} valueOption='credito' nameOption='credito' position="md:w-1/2" disabled={true} />
                            </div>
                        </div>
                    </div>

                </>
            </Tab.Panel>

            <Tab.Panel key={2}>
                <>
                    <div className='flex justify-center'>
                        <div className="w-full bg-white rounded-xl shadow-2xl px-6 py-3 mb-5 mt-2">
                            <div className="flex -mx-3 mb-6 justify-center">
                                {
                                    imagenes.map((item, index) =>
                                        <iframe
                                            key={index}
                                            title="PDF Preview"
                                            src={item.url}
                                            width="90%"
                                            height="1000px"
                                        ></iframe>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                </>
            </Tab.Panel>
        </Lash>

    )
}
