import { useDispatch, useSelector } from 'react-redux';
import { crearUsuario, editarUsuario, obtenerAsesores, obtenerRoles, obtenerUsuarioIndividual, obtenerUsuarios, obtenerValuadores } from '../store/slice/user';


export const useUser = () => {

    const dispatch = useDispatch();
    const { valuadores, loading, usuarios, cantidad, totalPaginas, roles, usuario, asesores } = useSelector((state) => state.user);

    const obtenerTotalValuadores = () => {
        dispatch(obtenerValuadores());
    }

    const obtenerTotalUsuarios = (busqueda, pagina) => {
        dispatch(obtenerUsuarios(busqueda, pagina));
    }

    const obtenerUsuarioIndividualTotal = (id, setShow2) => {
        dispatch(obtenerUsuarioIndividual(id, setShow2));
    }

    const obtenerRolesTotales = () => {
        dispatch(obtenerRoles());
    }

    const crearUsuarioIndividual = (data, setShow, setValue) => {
        dispatch(crearUsuario(data, setShow, setValue));
    }

    const editarUsuarioIndividual = (id, data, setShow2, setValue2) => {
        dispatch(editarUsuario(id, data, setShow2, setValue2));
    }

    const obtenerTotalAsesores = (id_agencia) => {
        dispatch(obtenerAsesores(id_agencia));
    }


    return {
        //valores
        valuadores,
        asesores,
        loading,
        usuarios,
        cantidad,
        totalPaginas,
        roles,
        usuario,

        //funciones
        obtenerTotalValuadores,
        obtenerTotalUsuarios,
        obtenerUsuarioIndividualTotal,
        obtenerRolesTotales,
        crearUsuarioIndividual,
        editarUsuarioIndividual,
        obtenerTotalAsesores
    }
}
