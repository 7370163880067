import { useDispatch, useSelector } from "react-redux";
import { crearAnotacionIndividual, crearColindanciaIndividual, crearDesmembracionIndividual, crearGravamenIndividual, crearLimitacionIndividual, editarAnotacionIndividual, editarColindanciaIndividual, editarDesmembracionIndividual, editarDetalleDesmembracionIndividual, editarGravamenIndividual, editarLimitacionIndividual, editarNoGravamenIndividual, editarRegistroPropiedadIndividual, eliminarAnotacionIndividual, eliminarColindanciaIndividual, eliminarDesmembracionIndividual, eliminarGravamenIndividual, eliminarLimitacionIndividual, eliminarRegistroPropiedadIndividual, guardarRegistroPropiedad, obtenerAnotacionIndividual, obtenerColindanciaIndividual, obtenerDesmembracionIndividual, obtenerGravamenIndividual, obtenerLimitacionIndividual, obtenerRegistroPropiedad, obtenerRegistroPropiedadDetallado, obtenerRegistroPropiedadDetallado2 } from "../store/slice/registroPropiedad";

export const useRegistroPropiedadSlice = () => {

    const dispatch = useDispatch();
    const { registro_propiedad, cantidad, totalPaginas, loading, detalleCompletoRegistroPropiedad, gravamen, anotacion, limitacion, colindancia, desmembracion } = useSelector((state) => state.registroPropiedad);

    const obtenerTotalRegistroPropiedad = (id_avaluo) => {
        dispatch(obtenerRegistroPropiedad(id_avaluo));
    }

    const crearRegistro = (id_avaluo, data, setShow, funcion) => {
        dispatch(guardarRegistroPropiedad(id_avaluo, data, setShow, funcion))
    }

    const obtenerRegistroPropiedadIndividual = (id_registro_propiedad, setShow2) => {
        dispatch(obtenerRegistroPropiedadDetallado(id_registro_propiedad, setShow2));
    }

    const obtenerRegistroPropiedadIndividual2 = (id_registro_propiedad) => {
        dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
    }

    const editarRegistroPropiedad = (id_registro_propiedad, data, setEdicion) => {
        dispatch(editarRegistroPropiedadIndividual(id_registro_propiedad, data, setEdicion));
    }

    const eliminaRegistroPropiedad = (id_registro_propiedad, id_avaluo) => {
        dispatch(eliminarRegistroPropiedadIndividual(id_registro_propiedad, id_avaluo));
    }

    //GRAVAMEN
    const obtenerGravamen = (id_gravamen, setShowModal) => {
        dispatch(obtenerGravamenIndividual(id_gravamen, setShowModal));
    }

    const editarGravamen = (id_gravamen, data, setShowModal, id_registro_propiedad) => {
        dispatch(editarGravamenIndividual(id_gravamen, data, setShowModal, id_registro_propiedad));
    }

    const crearGravamen = (data, setShowModalCreate, numero_gravamen, id_registro_propiedad, reset) => {
        dispatch(crearGravamenIndividual(data, setShowModalCreate, numero_gravamen, id_registro_propiedad, reset));
    }

    const eliminarGravamen = (id_gravamen, id_registro_propiedad) => {
        dispatch(eliminarGravamenIndividual(id_gravamen, id_registro_propiedad));
    }

    //ANOTACION
    const obtenerAnotacion = (id_anotacion, setShowModal) => {
        dispatch(obtenerAnotacionIndividual(id_anotacion, setShowModal));
    }

    const editarAnotacion = (id_anotacion, data, setShowModal, id_registro_propiedad) => {
        dispatch(editarAnotacionIndividual(id_anotacion, data, setShowModal, id_registro_propiedad));
    }

    const crearAnotacion = (data, setShowModalCreate, id_registro_propiedad, reset) => {
        dispatch(crearAnotacionIndividual(data, setShowModalCreate, id_registro_propiedad, reset));
    }

    const eliminarAnotacion = (id_anotacion, id_registro_propiedad) => {
        dispatch(eliminarAnotacionIndividual(id_anotacion, id_registro_propiedad));
    }

    //LIMITACION
    const obtenerLimitacion = (id_limitacion, setShowModal) => {
        dispatch(obtenerLimitacionIndividual(id_limitacion, setShowModal));
    }

    const editarLimitacion = (id_limitacion, data, setShowModal, id_registro_propiedad) => {
        dispatch(editarLimitacionIndividual(id_limitacion, data, setShowModal, id_registro_propiedad));
    }

    const crearLimitacion = (data, setShowModalCreate, id_registro_propiedad, reset) => {
        dispatch(crearLimitacionIndividual(data, setShowModalCreate, id_registro_propiedad, reset));
    }

    const eliminarLimitacion = (id_limitacion, id_registro_propiedad) => {
        dispatch(eliminarLimitacionIndividual(id_limitacion, id_registro_propiedad));
    }

    //COLINDANCIA
    const obtenerColindacia = (id_colindancia, setShowModal) => {
        dispatch(obtenerColindanciaIndividual(id_colindancia, setShowModal));
    }

    const editarColindacia = (id_colindancia, data, setShowModal, id_registro_propiedad) => {
        dispatch(editarColindanciaIndividual(id_colindancia, data, setShowModal, id_registro_propiedad));
    }

    const crearColindacia = (data, setShowModalCreate, id_registro_propiedad, reset) => {
        dispatch(crearColindanciaIndividual(data, setShowModalCreate, id_registro_propiedad, reset));
    }

    const eliminarColindacia = (id_colindancia, id_registro_propiedad) => {
        dispatch(eliminarColindanciaIndividual(id_colindancia, id_registro_propiedad));
    }

    //DESMEMBRACION
    const obtenerDesmembracion = (id_desmembracion, setShowModal) => {
        dispatch(obtenerDesmembracionIndividual(id_desmembracion, setShowModal));
    }

    const editarDesmembracion = (id_desmembracion, data, setShowModal, id_registro_propiedad) => {
        dispatch(editarDesmembracionIndividual(id_desmembracion, data, setShowModal, id_registro_propiedad));
    }

    const crearDesmembracion = (data, setShowModalCreate, id_registro_propiedad, reset) => {
        dispatch(crearDesmembracionIndividual(data, setShowModalCreate, id_registro_propiedad, reset));
    }

    const eliminarDesmembracion = (id_desmembracion, id_registro_propiedad) => {
        dispatch(eliminarDesmembracionIndividual(id_desmembracion, id_registro_propiedad));
    }

    //DETALLE DESMEMBRACION
    const editarDetalleDesmembracion = (id_detalle_desmembracion, data, setEdicion, id_registro_propiedad) => {
        dispatch(editarDetalleDesmembracionIndividual(id_detalle_desmembracion, data, setEdicion, id_registro_propiedad));
    }

    const editarNoGravamen = (data, setShowModalNoGravamen, id_registro_propiedad) => {
        dispatch(editarNoGravamenIndividual(data, setShowModalNoGravamen, id_registro_propiedad));
    }

    return {
        //valores
        registro_propiedad,
        cantidad,
        totalPaginas,
        loading,
        detalleCompletoRegistroPropiedad,
        gravamen,
        anotacion,
        limitacion,
        colindancia,
        desmembracion,

        //funciones
        obtenerTotalRegistroPropiedad,
        crearRegistro,
        obtenerRegistroPropiedadIndividual,
        editarRegistroPropiedad,
        obtenerGravamen,
        editarGravamen,
        obtenerRegistroPropiedadIndividual2,
        crearGravamen,
        eliminarGravamen,
        obtenerAnotacion,
        editarAnotacion,
        eliminarAnotacion,
        crearAnotacion,
        obtenerLimitacion,
        editarLimitacion,
        crearLimitacion,
        eliminarLimitacion,
        obtenerColindacia,
        editarColindacia,
        crearColindacia,
        eliminarColindacia,
        obtenerDesmembracion,
        editarDesmembracion,
        crearDesmembracion,
        eliminarDesmembracion,
        editarDetalleDesmembracion,
        eliminaRegistroPropiedad,
        editarNoGravamen
    }
}
