import { useDispatch, useSelector } from 'react-redux';
import { crearColaborador, editarColaborador, eliminarColaborador, obtenerColaboradorIndividual, obtenerColaboradores, obtenerColaboradoresFiltro } from '../store/slice/colaborador';

export const useColaborador = () => {

    const dispatch = useDispatch();
    const { colaborador, loading, cantidad, totalPaginas, colaboradorIndividual } = useSelector((state) => state.colaborador);

    const obtenerTotalColaboradores = (busqueda, limite, pagina, agencia, puesto) => {
        dispatch(obtenerColaboradores(busqueda, limite, pagina, agencia, puesto));
    }

    const obtenerTotalColaboradoresFiltro = (agencia, setValue, colaborador) => {
        dispatch(obtenerColaboradoresFiltro(agencia, setValue, colaborador));
    }

    const obtenerColaboradorIndividualTotal = (id_colaborador, setShow2) => {
        dispatch(obtenerColaboradorIndividual(id_colaborador, setShow2));
    }

    const crearColaboradorTotal = (data, setShow, setValue) => {
        dispatch(crearColaborador(data, setShow, setValue));
    }

    const editarColaboradorTotal = (id_colaborador, data, setShow2, setValue2) => {
        dispatch(editarColaborador(id_colaborador, data, setShow2, setValue2));
    }

    const eliminarColaboradorTotal = (id_colaborador) => {
        dispatch(eliminarColaborador(id_colaborador));
    }


    return {
        //valores
        colaborador,
        loading,
        cantidad,
        totalPaginas,
        colaboradorIndividual,

        //funciones
        obtenerTotalColaboradores,
        obtenerColaboradorIndividualTotal,
        crearColaboradorTotal,
        editarColaboradorTotal,
        eliminarColaboradorTotal,
        obtenerTotalColaboradoresFiltro
    }
}
