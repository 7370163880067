import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse } from "../../../utils";
import { agenciaCredentials, isLoading, loaded } from "./agenciaSlice";

export const obtenerAgencias = () => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/agencia', { headers: { 'token': getToken() } });
            dispatch(agenciaCredentials(data));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
            errorModal('ocurrió un error al cargar la información de las agencias');
        }
    }
}

export const obtenerAgenciasEdicion = (setValue, agencia) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/agencia', { headers: { 'token': getToken() } });
            dispatch(agenciaCredentials(data));
            setValue('id_agencia', agencia);
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
            errorModal('ocurrió un error al cargar la información de las agencias');
        }
    }
}