import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { CrearUsuario } from "./CrearUsuario";
import { EditarUsuario } from "./EditarUsuario";
import { useUser } from "../../hooks";
import { InputSearch, Loader, NormalButton, Pagination, TransitionPopover } from "../../ui";
import { TdTable, ThTable, TrTable } from "../avaluo/components";

export const Usuario = () => {

    const [page, setPage] = useState(1);
    const { register, watch, setValue } = useForm();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const { loading, usuarios, totalPaginas, obtenerTotalUsuarios, obtenerUsuarioIndividualTotal } = useUser();
    let watchItems = watch();

    useEffect(() => {
        obtenerTotalUsuarios(watchItems.buscador, page);
        // eslint-disable-next-line
    }, [page, watchItems.buscador]);

    useEffect(() => {
        setPage(1);
    }, [watchItems.buscador]);

    return (
        <>
            {loading && <Loader />}
            <div className='mx-20 my-14'>
                <div className='my-5 text-gray-400 font-bold text-4xl'>
                    <h1>
                        USUARIOS
                        <hr className='w-1/6' />
                    </h1>

                </div>

                <div className='flex lg:flex-nowrap lg:justify-between flex-wrap'>
                    <InputSearch register={register} name="buscador" position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                    <NormalButton bgColor='bg-blue-900  mx-1' bgText='text-white' hoverColor='hover:bg-blue-800' title={
                        <div className="flex font-semibold">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Crear Usuario
                        </div>

                    } funcion={() => setShow(true)} />
                </div>

                <table className={"border-collapse w-full"}>
                    <thead>
                        <tr>
                            <ThTable titulo='Nombre' />
                            <ThTable titulo='Usuario' />
                            <ThTable titulo='Correo electrónico' />
                            <ThTable titulo='Celular' />
                            <ThTable titulo='Estado' />
                            <ThTable titulo='Rol' />
                            <ThTable titulo='Acciones' />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            usuarios.length === 0 ?
                                <tr>
                                    <td>
                                        <div className=' text-center font-bold text-gray-400 m-4'>No existen registros de Usuarios</div>
                                    </td>
                                </tr>
                                :
                                usuarios.map((item, index) => (
                                    <TrTable key={index} bg={item.estado !== 1 ? 'hover:bg-red-200 bg-red-100' : 'hover:bg-gray-100 bg-white'}>
                                        <TdTable valor={item.nombre} />
                                        <TdTable valor={item.usuario} />
                                        <TdTable valor={item.correo_electronico} />
                                        <TdTable valor={item.telefono} />
                                        <TdTable valor={item.estado === 1 ? 'Habilitado' : 'Deshabilitado'} />
                                        <TdTable valor={item.rol.descripcion} />
                                        <TdTable valor={
                                            <div className='flex justify-center mx-2'>
                                                <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                                                    <svg onClick={() => obtenerUsuarioIndividualTotal(item.id_usuario, setShow2)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                    </svg>
                                                </div>
                                            </div>} />
                                    </TrTable>
                                ))
                        }

                    </tbody>
                </table>
                <div className="flex justify-end mt-3">
                    <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
                </div>
                <TransitionPopover show={show} setShow={setShow} >
                    <CrearUsuario setShow={setShow} setValue={setValue} />
                </TransitionPopover>
                <TransitionPopover show={show2} setShow={setShow2} >
                    <EditarUsuario setShow2={setShow2} setValue2={setValue} />
                </TransitionPopover>
            </div>
        </>
    )
}
