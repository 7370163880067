import { createSlice } from '@reduxjs/toolkit';

export const puestoSlice = createSlice({
    name: 'puesto',
    initialState: {
        puesto: [],
        cantidad: null,
        totalPaginas: null,
        loading: false,
        puestoIndividual: {},
    },
    reducers: {
        puestoCredentials: (state, { payload }) => {
            state.puesto = payload.puesto;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        puestoIndividualCredentials: (state, { payload }) => {
            state.puestoIndividual = payload.puesto
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { puestoCredentials, puestoIndividualCredentials, isLoading, loaded } = puestoSlice.actions;