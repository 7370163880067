import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { loaded } from "../auth";
import { isLoading, plusvaliaMinusvaliaCredentials } from "./plusvaliaMinusvaliaSlice";


export const obtenerPlusvaliasMinusvalias = (tipo, id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/plusvaliaminusvalia', { headers: { 'token': getToken() }, params: { id_avaluo, tipo } });
            dispatch(plusvaliaMinusvaliaCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const eliminarPlusvaliaMinusvalia = (id_plusvalia_minusvalia, tipo, id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/plusvaliaminusvalia/eliminar', { headers: { 'token': getToken() }, params: { id_plusvalia_minusvalia } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerPlusvaliasMinusvalias(tipo, id_avaluo));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}


export const crearPlusvaliaMinusvalia = (id_avaluo, data, setShow, tipo) => {
    return async (dispatch) => {

        try {
            dispatch(isLoading());
            await apiRest.post('/plusvaliaminusvalia/crear', data, { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            dispatch(obtenerPlusvaliasMinusvalias(tipo, id_avaluo));
            setShow(false);
        } catch (error) {
            dispatch(loaded())
            interceptorResponse(error);
            errorModal('error al hacer el guardado');
        }
    }
}
