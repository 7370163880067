
export const TotalPagesAndData = ({ amount, page, totPages }) => {

    return (
        <>
            <div className='pr-2'>
                <h6 className='text-gray-400 text-end'>Registros: {amount}</h6>
            </div>
                <h6 className='text-gray-400'>|</h6>
            <div className=' pl-2'>
                <h6 className='text-gray-400 text-end'>Pág. {`${page}/${totPages === null || totPages === 0 ? 1 : totPages}`}</h6>
            </div>
        </>
    )
}
