import { useForm } from "react-hook-form";
import { useRegistroPropiedadSlice } from "../../../../../../hooks";
import { InputText, Modal, ModalGenericoCrear, NormalButton, SelectOption } from "../../../../../../ui";
import { TdTable, ThTable, TrTable } from "../../../../components";
import { useEffect, useState } from "react";
import { questionModal } from "../../../../../../utils";

const linderos = [{ lindero: "Norte" }, { lindero: "Sur" }, { lindero: "Oriente" }, { lindero: "Poniente" }]
export const EdicionRegistroFase5 = () => {

    const { colindancia, detalleCompletoRegistroPropiedad, obtenerColindacia, editarColindacia, eliminarColindacia, crearColindacia } = useRegistroPropiedadSlice();
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const [showModal, setShowModal] = useState(false);
    const [showModalCreate, setShowModalCreate] = useState(false);
    const [idColindancia, setIdColindancia] = useState();

    useEffect(() => {
        setValue('lindero', colindancia.lindero);
        setValue('colindancia_registral', colindancia.colindancia_registral);
        // eslint-disable-next-line
    }, [colindancia])

    const abrirModalEdicion = (id_colindancia) => {
        obtenerColindacia(id_colindancia, setShowModal);
        setIdColindancia(id_colindancia);
    }

    return (
        <div className='mx-20 my-10'>
            <div className='flex justify-end'>
                <NormalButton funcion={() => setShowModalCreate(true)} bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' title={
                    <div className="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Crear Colindancia
                    </div>
                } />
            </div>
            <table className={"border-collapse w-full"}>
                <thead>
                    <tr>
                        <ThTable titulo='Lindero' />
                        <ThTable titulo='Colindancia registral' />
                        <ThTable titulo='Acciones' />
                    </tr>
                </thead>
                <tbody>
                    {
                        detalleCompletoRegistroPropiedad.colindancia.map((item, index) => (
                            <TrTable key={index}>
                                <TdTable titulo='Lindero' valor={item.lindero} />
                                <TdTable titulo='Colindancia registral' valor={item.colindancia_registral} />
                                <TdTable valor={
                                    <div className='flex justify-center mx-2'>
                                        <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                                            <svg onClick={() => abrirModalEdicion(item.id_colindancia)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                            </svg>
                                        </div>
                                        <div className="w-4 transform hover:text-red-500 hover:scale-110">
                                            <svg onClick={() => questionModal('Eliminar Colindancia', '¿Desea eliminar esta colindancia?', () => eliminarColindacia(item.id_colindancia, item.id_registro_propiedad))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>

                                        </div>
                                    </div>
                                } />
                            </TrTable>
                        ))
                    }
                </tbody>
            </table>
            <Modal showModal={showModal} setShowModal={setShowModal} title='EDITAR COLINDANCIA' handleSubmit={handleSubmit} id_item={idColindancia} funcion={editarColindacia} id_registro_propiedad={detalleCompletoRegistroPropiedad.registro_propiedad.id_registro_propiedad} >
                <div className='my-4 px-1 pb-4 w-full  flex flex-wrap' >

                    <div className='w-full bg-gray-400 rounded-t-xl flex items-center justify-center'>
                        <label className='text-center block text-xl font-bold text-white'>
                            Lindero y Colindancia Registral
                        </label>
                    </div>

                    <div className='w-full md:flex border-x-4 border-b-4 border-gray-400 rounded-b-xl shadow-lg p-2'>
                        <div className='md:mr-2 md:w-1/2'>
                            <SelectOption nameLabel='Lindero' register={register} name='lindero' message='El lindero es requerido' errors={errors} array={linderos} valueOption='lindero' nameOption='lindero' />
                        </div>
                        <div className='md:ml-2 md:w-1/2'>
                            <InputText nameLabel='Colindancia registral' register={register} name='colindancia_registral' message="La colindancia registral es requerida" errors={errors} Placeholder="Ej. 36.70 metros con Ruta Nacional" />
                        </div>
                    </div>
                </div>
            </Modal>

            <ModalGenericoCrear showModal={showModalCreate} setShowModal={setShowModalCreate} title='CREAR COLINDANCIA' handleSubmit={handleSubmit} reset={reset} funcion={crearColindacia} id_registro_propiedad={detalleCompletoRegistroPropiedad.registro_propiedad.id_registro_propiedad}>
                <div className='my-4 px-1 pb-4 w-full  flex flex-wrap' >

                    <div className='w-full bg-gray-400 rounded-t-xl flex items-center justify-center'>
                        <label className='text-center block text-xl font-bold text-white'>
                            Lindero y Colindancia Registral
                        </label>
                    </div>

                    <div className='w-full md:flex border-x-4 border-b-4 border-gray-400 rounded-b-xl shadow-lg p-2'>
                        <div className='md:mr-2 md:w-1/2'>
                            <SelectOption nameLabel='Lindero' register={register} name='linderoA' message='El lindero es requerido' errors={errors} array={linderos} valueOption='lindero' nameOption='lindero' />
                        </div>
                        <div className='md:ml-2 md:w-1/2'>
                            <InputText nameLabel='Colindancia registral' register={register} name='colindancia_registralA' message="La colindancia registral es requerida" errors={errors} Placeholder="Ej. 36.70 metros con Ruta Nacional" />
                        </div>
                    </div>
                </div>
            </ModalGenericoCrear >
        </div>
    )
}
