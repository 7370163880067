import { useForm } from "react-hook-form";
import { InputDate2, Loader, SelectOption6 } from "../../../../ui"
import { useAvaluoSlice } from "../../../../hooks";
import { decryptRolLocalStorage, questionModal } from "../../../../utils";

let reportesAvaluos = [{ reporte: 'reporte de avalúos' }, { reporte: 'comisiones' }];
let reportesAvaluos2 = [{ reporte: 'reporte de avalúos' }];
export const Reportes = () => {

    const { register, watch } = useForm();
    const { generarReporteMensual, loading } = useAvaluoSlice();
    let watchItems = watch();
    let rol = decryptRolLocalStorage();

    return (
        <>
            {loading && <Loader />}
            <div className='flex justify-center'>
                <div className="w-full max-w-4xl bg-white rounded-xl shadow-2xl px-6 py-3 mb-5 mt-10">
                    <div className='mb-6 text-gray-400 font-bold sm:text-2xl text-xl'>
                        <h1>
                            REPORTERÍA DE AVALÚOS Y COMISIONES
                            <hr />
                        </h1>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <InputDate2 nameLabel='desde' register={register} name='fechaA' position='lg:w-1/2' />
                        <InputDate2 nameLabel='hasta' register={register} name='fechaB' position='lg:w-1/2' />
                        <SelectOption6 nameLabel='Tipo de reporte' register={register} name="tipo" array={rol === '8' ? reportesAvaluos2 : reportesAvaluos} valueOption='reporte' nameOption='reporte' position=' mb-3 font-semibold text-gray-500 mx-1' />
                        <div className="w-full px-3 mt-5 flex justify-end">
                            <div className="text-center flex w-full md:w-1/3">
                                <button type="button" onClick={() => questionModal('Reporte', '¿Desea generar el reporte?', () => generarReporteMensual(watchItems.fechaA, watchItems.fechaB, watchItems.tipo))} className="font-bold text-white w-full px-4 py-2 bg-blue-900 rounded-md cursor-pointer hover:bg-blue-950 focus:outline-none focus:shadow-outline">
                                    <div className="flex justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
                                        </svg>
                                        Generar reporte
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}
