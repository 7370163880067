import { useDispatch, useSelector } from "react-redux";
import { crearNotificacion, editarNotificacion, modificarNotificaciones, obtenerNotificaciones } from "../store/slice/notificacion";

export const useNotificacion = () => {

    const dispatch = useDispatch();
    const { notificaciones, loading, cantidad_notificaciones, cantidad_notificaciones_completadas, confirmacion } = useSelector((state) => state.notificacion);

    const obtenerTotalNotificaciones = (id_avaluo) => {
        dispatch(obtenerNotificaciones(id_avaluo));
    }

    const crearNotificacionIndividual = (id_avaluo, data, setOpenModal) => {
        dispatch(crearNotificacion(id_avaluo, data, setOpenModal));
    }

    const actualizarNotificacionIndividual = (id_avaluo, data, setOpenModal, completado = 0) => {
        dispatch(editarNotificacion(id_avaluo, data, setOpenModal, completado));
    }

    const modNotificaciones = (arreglo) => {
        dispatch(modificarNotificaciones(arreglo));
    }

    return {
        //valores
        notificaciones,
        loading,
        cantidad_notificaciones,
        cantidad_notificaciones_completadas,
        confirmacion,

        //funciones
        obtenerTotalNotificaciones,
        crearNotificacionIndividual,
        modNotificaciones,
        actualizarNotificacionIndividual
    }
}