import { useDispatch, useSelector } from "react-redux";
import { crearPlusvaliaMinusvalia, eliminarPlusvaliaMinusvalia, obtenerPlusvaliasMinusvalias } from "../store/slice/plusvaliaMinusvalia";

export const usePlusvaliaMinusvalia = () => {

    const dispatch = useDispatch();
    const { plusvaliaMinusvalia, loading } = useSelector((state) => state.plusvaliaMinusvalia);

    const obtenerPlusvaliasMinusvaliasCompletas = (tipo, id_avaluo) => {
        dispatch(obtenerPlusvaliasMinusvalias(tipo, id_avaluo));
    }

    const eliminarPlusvaliaMinusvaliaIndividual = (id_plusvalia_minusvalia, tipo = '', id_avaluo) => {
        dispatch(eliminarPlusvaliaMinusvalia(id_plusvalia_minusvalia, tipo, id_avaluo));
    }

    const crearPlusvaliaMinusvaliaIndividual = (id_avaluo, data, setShow, tipo = '') => {
        dispatch(crearPlusvaliaMinusvalia(id_avaluo, data, setShow, tipo));
    }


    return {
        //valores
        plusvaliaMinusvalia,
        loading,

        //funciones
        obtenerPlusvaliasMinusvaliasCompletas,
        eliminarPlusvaliaMinusvaliaIndividual,
        crearPlusvaliaMinusvaliaIndividual
    }
}