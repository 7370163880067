import { useForm } from "react-hook-form";
import { useRegistroPropiedadSlice } from "../../../../../../hooks";
import { useEffect } from "react";
import { InputDecimal, NormalButton, NormalButton2 } from "../../../../../../ui";
import { questionModal, successModal } from "../../../../../../utils";
export const EdicionRegistroFase7 = ({ edicion, setEdicion }) => {

    const { detalleCompletoRegistroPropiedad, editarDetalleDesmembracion } = useRegistroPropiedadSlice();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    let watchItems = watch();

    const activarEdicion = () => {
        if (edicion) {
            successModal('Edicion desactivada', 'center', 'info');
            setEdicion(false);
        } else {
            successModal('Edicion activada', 'center', 'warning');
            setEdicion(true);
        }
    }

    useEffect(() => {
        let valorAreaOriginal = Number.parseFloat(watchItems.area_original);
        let valorDesmembracion = 0;
        let valorAreaRegistralActual = 0;
        for (let i = 0; i < detalleCompletoRegistroPropiedad.desmembracion.length; i++) {
            valorDesmembracion = valorDesmembracion + Number.parseFloat(detalleCompletoRegistroPropiedad.desmembracion[i].area_desmembracion);
        }

        valorAreaRegistralActual = (valorAreaOriginal - valorDesmembracion).toFixed(2);
        setValue('area_original', detalleCompletoRegistroPropiedad.detalle_desmembracion[0].area_original);
        setValue('total_desmembracion', valorDesmembracion.toFixed(2));
        setValue('area_registral_actual', valorAreaRegistralActual);
        // eslint-disable-next-line
    }, [detalleCompletoRegistroPropiedad]);

    useEffect(() => {
        let valorAreaOriginal = Number.parseFloat(watchItems.area_original);
        let valorDesmembracion = 0;
        let valorAreaRegistralActual = 0;
        for (let i = 0; i < detalleCompletoRegistroPropiedad.desmembracion.length; i++) {
            valorDesmembracion = valorDesmembracion + Number.parseFloat(detalleCompletoRegistroPropiedad.desmembracion[i].area_desmembracion);
        }
        valorAreaRegistralActual = (valorAreaOriginal - valorDesmembracion).toFixed(2);
        setValue('total_desmembracion', valorDesmembracion.toFixed(2));

        if (isNaN(valorAreaOriginal)) {
            setValue('area_registral_actual', 0)
        } else {
            setValue('area_registral_actual', valorAreaRegistralActual);
        }
        // eslint-disable-next-line
    }, [watchItems.area_original])


    return (
        <>
            <div className=" mx-auto p-4">
                <form onSubmit={handleSubmit((data) => editarDetalleDesmembracion(detalleCompletoRegistroPropiedad.detalle_desmembracion[0].id_detalle_desmembracion, data, setEdicion, detalleCompletoRegistroPropiedad.registro_propiedad.id_registro_propiedad))}>
                    <div className="flex flex-wrap -mx-3 mb-6">

                        <div className="w-full mx-3 mt-5 text-center flex justify-end">
                            <NormalButton funcion={() => questionModal(edicion ? 'Desactivar edición' : 'Activar edición', edicion ? '¿Desea desactivar el modo de edición?' : '¿Desea activar el modo de edición?', activarEdicion)} bgColor={edicion ? 'bg-yellow-700' : 'bg-green-700'} bgText='text-white font-semibold' hoverColor={edicion ? 'hover:bg-yellow-800' : 'hover:bg-green-800'} dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title={edicion ? "Desactivar edición" : "Activar edición"} />
                            <NormalButton2 typeButton="submit" bgColor={edicion ? 'bg-blue-900  cursor-pointer' : 'bg-blue-300'} bgText='text-white font-semibold' hoverColor={edicion ? 'hover:bg-blue-800' : ''} dimension="mr-1 px-1 sm:px-6 py-2 rounded-md" title="Guardar" disabledButton={edicion ? false : true} />
                        </div>

                        <div className="mb-2 w-full px-3 ">
                            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Área actual</h1>
                        </div>
                        <InputDecimal nameLabel='Área original (1ra de dominio)' register={register} name="area_original" message="El área es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. 16,787.04" disabled={edicion ? false : true} />
                        <InputDecimal nameLabel='Total, desmembraciones' register={register} name="total_desmembracion" message="El total es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 510" disabled={true} />
                        <InputDecimal nameLabel='Área registral actual' register={register} name="area_registral_actual" message="El área es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. 16,787.04" disabled={true} />
                    </div>
                </form>
            </div>
        </>
    )
}
