import { useForm } from "react-hook-form";
import { decryptId, questionModal } from "../../../../utils";
import { InputDecimal, InputNumber, InputText, NormalButton2, SelectOption, TextArea } from "../../../../ui";
import { useParams } from "react-router-dom";
import { useValorInmueble } from "../../../../hooks";
import { useEffect } from "react";

let dimensionales = [{dimensional: 'Mz'}, {dimensional: 'm2'}];

export const EditarDescripcionReferencial = ({ setShow2 }) => {

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const { descripcionReferencialIndividual, editarDescripcionReferencialIndividual } = useValorInmueble();
    const { id } = useParams();

    useEffect(() => {
        setValue('nombre', descripcionReferencialIndividual.nombre);
        setValue('telefono', descripcionReferencialIndividual.telefono);
        setValue('dimensional', descripcionReferencialIndividual.dimensional);
        setValue('valor', descripcionReferencialIndividual.valor);
        setValue('referencia', descripcionReferencialIndividual.referencia);
        // eslint-disable-next-line
    }, [descripcionReferencialIndividual])


    return (
        <div className='flex justify-center mt-4'>
            <div className='w-full max-w-7xl bg-gray-50 rounded-lg shadow-2xl p-6'>
                <div className=" mx-auto p-4">
                    <form onSubmit={handleSubmit((data) => questionModal('Editar', '¿Desea guardar el registro?', () => editarDescripcionReferencialIndividual(descripcionReferencialIndividual.id_descripcion_referencial, decryptId(id), data, setShow2)))}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="my-2 w-full px-3 ">
                                <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">CREAR REFERENCIA</h1>
                            </div>
                            <InputText nameLabel='Nombre del entrevistado' register={register} name="nombre" message="El dato es requerido" position="md:w-1/2" errors={errors} Placeholder="Ej. Fernando Roldán Recinos" />
                            <InputNumber nameLabel='Teléfono' register={register} name="telefono" message="El dato es requerido" position="md:w-1/2" errors={errors} Placeholder="Ej. 31769827" requerido={false} />
                            <InputDecimal nameLabel='Valor' register={register} name="valor" message="El dato es requerido" position="md:w-1/2" errors={errors} Placeholder="Ej. 45000" />
                            <SelectOption nameLabel='Dimensional' register={register} name='dimensional' message='El valor es requerido' errors={errors} array={dimensionales} valueOption='dimensional' nameOption='dimensional' position="md:w-1/2" />
                            <TextArea nameLabel='Referencia' register={register} name="referencia" message="El dato es requerido" errors={errors} Placeholder="Ej. escribe una referencia" />

                            <div className="w-full mx-3 mt-1 text-center flex justify-end">
                                <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title='Guardar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}
