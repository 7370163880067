import { useDispatch, useSelector } from "react-redux";
import { autorizacionAvaluo, crearAvaluo, editarAvaluo, enviarAprobacionAvaluo, enviarAutorizacionAvaluo, generarInformeAvaluoPDF, generarReporte, getAvaluos, informeAvaluo, limpiarAvaluoIndividual, obtenerAvaluo, retroceso } from "../store/slice/avaluo";

export const useAvaluoSlice = () => {

    const dispatch = useDispatch();
    const { avaluos, cantidad, totalPaginas, loading, avaluoIndividual, informe } = useSelector((state) => state.avaluos);

    const obtenerTotalAvaluos = (busqueda, pagina, estado, filtro_usuario, limite, id_agencia, id_usuario, fechaA, fechaB) => {
        dispatch(getAvaluos(busqueda, pagina, estado, filtro_usuario, limite, id_agencia, id_usuario, fechaA, fechaB));
    }

    const crearNuevoAvaluo = (data1, setShow, setValue, funcion, watchItem) => {
        dispatch(crearAvaluo(data1, setShow, setValue, funcion, watchItem));
    }

    const obtenerAvaluoIndividual = (id_avaluo) => {
        dispatch(obtenerAvaluo(id_avaluo));
    }

    const editarAvaluoIndividual = (id_avaluo, data1, setShow, setValue, funcion, watchItem) => {
        dispatch(editarAvaluo(id_avaluo, data1, setShow, setValue, funcion, watchItem));
    }

    const avaluoInforme = (id_avaluo, setShow) => {
        dispatch(informeAvaluo(id_avaluo, setShow));
    }

    const enviarAprobacion = (id_avaluo, funcion) => {
        dispatch(enviarAprobacionAvaluo(id_avaluo, funcion));
    }

    const enviarAutorizacion = (id_avaluo, funcion) => {
        dispatch(enviarAutorizacionAvaluo(id_avaluo, funcion));
    }

    const avaluoAutorizado = (id_avaluo, funcion) => {
        dispatch(autorizacionAvaluo(id_avaluo, funcion));
    }

    const generarAvaluoPDF = (id_avaluo, solicitante) => {
        dispatch(generarInformeAvaluoPDF(id_avaluo, solicitante));
    }

    const limpiarAvaluoInd = () => {
        dispatch(limpiarAvaluoIndividual());
    }

    const generarReporteMensual = (fechaA, fechaB, tipo) => {
        dispatch(generarReporte(fechaA, fechaB, tipo));
    }

    const retrocesoAvaluo = (id_avaluo, data, setShow) => {
        dispatch(retroceso(id_avaluo, data, setShow));
    }



    return {
        //valores
        avaluos,
        cantidad,
        totalPaginas,
        loading,
        avaluoIndividual,
        informe,

        //funciones
        obtenerTotalAvaluos,
        crearNuevoAvaluo,
        obtenerAvaluoIndividual,
        editarAvaluoIndividual,
        enviarAprobacion,
        avaluoInforme,
        enviarAutorizacion,
        avaluoAutorizado,
        generarAvaluoPDF,
        limpiarAvaluoInd,
        generarReporteMensual,
        retrocesoAvaluo
    }
}
