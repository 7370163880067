import { createSlice } from '@reduxjs/toolkit';

export const detalleInmuebleSlice = createSlice({
    name: 'detalleInmueble',
    initialState: {
        detalle_inmueble: [],
        area_legal: null,
        loading: false,
        detalle_inmueble_total: []
    },
    reducers: {
        detalleInmuebleCredentials: (state, { payload }) => {
            state.area_legal = payload.area_legal;
            state.detalle_inmueble = payload.detalle_inmueble;
            state.loading = false;
        },
        detalleInmuebleTotalCredentials: (state, { payload }) => {
            state.detalle_inmueble_total = payload.detalle_inmueble_total;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});


// Action creators are generated for each case reducer function
export const { detalleInmuebleCredentials, isLoading, loaded, detalleInmuebleTotalCredentials } = detalleInmuebleSlice.actions;