import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { anotacionCredentials, colindanciaCredentials, desmembracionCredentials, gravamenCredentials, isLoading, limitacionCredentials, loaded, registroPropiedadCredentials, registroPropiedadIndividualCredential } from "./registroPropiedadSlice";


export const obtenerRegistroPropiedad = (id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/registropropiedad', { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(registroPropiedadCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const guardarRegistroPropiedad = (id_avaluo, data, setShow, funcion) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/registropropiedad/crear', data, { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(loaded());
            successModal('Registro creado correctamente', 'center');
            setShow(false);
            funcion();
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const obtenerRegistroPropiedadDetallado = (id_registro_propiedad, setShow2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/registropropiedad/detalleregistro', { headers: { 'token': getToken() }, params: { id_registro_propiedad } });
            dispatch(registroPropiedadIndividualCredential(data));
            setShow2(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const obtenerRegistroPropiedadDetallado2 = (id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/registropropiedad/detalleregistro', { headers: { 'token': getToken() }, params: { id_registro_propiedad } });
            dispatch(registroPropiedadIndividualCredential(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const editarRegistroPropiedadIndividual = (id_registro_propiedad, data, setEdicion) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/registropropiedad/editar', data, { headers: { 'token': getToken() }, params: { id_registro_propiedad } });
            successModal('Registro editado correctamente, edición desactivada', 'center');
            setEdicion(false)
            dispatch(loaded());
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const eliminarRegistroPropiedadIndividual = (id_registro_propiedad, id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/registropropiedad/eliminar', { headers: { 'token': getToken() }, params: { id_registro_propiedad } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerRegistroPropiedad(id_avaluo));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

//GRAVAMEN
export const obtenerGravamenIndividual = (id_gravamen, setShowModal) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/registropropiedad/gravamen', { headers: { 'token': getToken() }, params: { id_gravamen } });
            dispatch(gravamenCredentials(data));
            setShowModal(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const editarGravamenIndividual = (id_gravamen, data, setShowModal, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/registropropiedad/gravamen/editar', data, { headers: { 'token': getToken() }, params: { id_gravamen } });
            successModal('Registro editado correctamente', 'center');
            setShowModal(false)
            dispatch(loaded());
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const crearGravamenIndividual = (data, setShowModalCreate, numero_gravamen, id_registro_propiedad, reset) => {
    let data1 = {};
    if (numero_gravamen !== 0) {
        data1 = { deudor: data.deudorA, acreedor: data.acreedorA, numero_gravamen }
    } else {
        data1 = { deudor: data.deudorA, acreedor: data.acreedorA, numero_gravamen: data.numero_gravamen };
    }

    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/registropropiedad/gravamen/crear', data1, { headers: { 'token': getToken() }, params: { id_registro_propiedad } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            setShowModalCreate(false);
            reset();
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const eliminarGravamenIndividual = (id_gravamen, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/registropropiedad/gravamen/eliminar', { headers: { 'token': getToken() }, params: { id_gravamen } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const editarNoGravamenIndividual = (data, setShowModalNoGravamen, id_registro_propiedad) => {
    console.log(id_registro_propiedad, 'id')
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/registropropiedad/gravamen/numero', data, { headers: { 'token': getToken() }, params: { id_registro_propiedad } });
            successModal('Registro editado correctamente', 'center');
            setShowModalNoGravamen(false)
            dispatch(loaded());
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

//ANOTACION
export const obtenerAnotacionIndividual = (id_anotacion, setShowModal) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/registropropiedad/anotacion', { headers: { 'token': getToken() }, params: { id_anotacion } });
            dispatch(anotacionCredentials(data));
            setShowModal(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const editarAnotacionIndividual = (id_anotacion, data, setShowModal, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/registropropiedad/anotacion/editar', data, { headers: { 'token': getToken() }, params: { id_anotacion } });
            successModal('Registro editado correctamente', 'center');
            setShowModal(false)
            dispatch(loaded());
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const crearAnotacionIndividual = (data, setShowModalCreate, id_registro_propiedad, reset) => {
    let data1 = {};

    data1 = { letra: data.letraA, descripcion: data.descripcionA }

    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/registropropiedad/anotacion/crear', data1, { headers: { 'token': getToken() }, params: { id_registro_propiedad } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            setShowModalCreate(false);
            reset();
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const eliminarAnotacionIndividual = (id_anotacion, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/registropropiedad/anotacion/eliminar', { headers: { 'token': getToken() }, params: { id_anotacion } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

//LIMITACION
export const obtenerLimitacionIndividual = (id_limitacion, setShowModal) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/registropropiedad/limitacion', { headers: { 'token': getToken() }, params: { id_limitacion } });
            dispatch(limitacionCredentials(data));
            setShowModal(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const editarLimitacionIndividual = (id_limitacion, data, setShowModal, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/registropropiedad/limitacion/editar', data, { headers: { 'token': getToken() }, params: { id_limitacion } });
            successModal('Registro editado correctamente', 'center');
            setShowModal(false)
            dispatch(loaded());
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const crearLimitacionIndividual = (data, setShowModalCreate, id_registro_propiedad, reset) => {
    let data1 = {};

    data1 = { numero_limitacion: data.numero_limitacionA, descripcion: data.descripcionA }

    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/registropropiedad/limitacion/crear', data1, { headers: { 'token': getToken() }, params: { id_registro_propiedad } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            setShowModalCreate(false);
            reset();
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const eliminarLimitacionIndividual = (id_limitacion, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/registropropiedad/limitacion/eliminar', { headers: { 'token': getToken() }, params: { id_limitacion } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

//COLINDANCIA
export const obtenerColindanciaIndividual = (id_colindancia, setShowModal) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/registropropiedad/colindancia', { headers: { 'token': getToken() }, params: { id_colindancia } });
            dispatch(colindanciaCredentials(data));
            setShowModal(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const editarColindanciaIndividual = (id_colindancia, data, setShowModal, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/registropropiedad/colindancia/editar', data, { headers: { 'token': getToken() }, params: { id_colindancia } });
            successModal('Registro editado correctamente', 'center');
            setShowModal(false)
            dispatch(loaded());
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const crearColindanciaIndividual = (data, setShowModalCreate, id_registro_propiedad, reset) => {
    let data1 = {};

    data1 = { lindero: data.linderoA, colindancia_registral: data.colindancia_registralA }

    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/registropropiedad/colindancia/crear', data1, { headers: { 'token': getToken() }, params: { id_registro_propiedad } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            setShowModalCreate(false);
            reset();
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const eliminarColindanciaIndividual = (id_colindancia, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/registropropiedad/colindancia/eliminar', { headers: { 'token': getToken() }, params: { id_colindancia } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

//DESMEMBRACION
export const obtenerDesmembracionIndividual = (id_desmembracion, setShowModal) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/registropropiedad/desmembracion', { headers: { 'token': getToken() }, params: { id_desmembracion } });
            dispatch(desmembracionCredentials(data));
            setShowModal(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const editarDesmembracionIndividual = (id_desmembracion, data, setShowModal, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/registropropiedad/desmembracion/editar', data, { headers: { 'token': getToken() }, params: { id_desmembracion } });
            successModal('Registro editado correctamente', 'center');
            setShowModal(false)
            dispatch(loaded());
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

export const crearDesmembracionIndividual = (data, setShowModalCreate, id_registro_propiedad, reset) => {
    let data1 = {};

    data1 = { numero_desmembracion: data.numero_desmembracionA, finca: data.fincaA, folio: data.folioA, libro: data.libroA, departamento: data.departamentoA, fecha: data.fechaA, area_desmembracion: data.area_desmembracionA }

    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/registropropiedad/desmembracion/crear', data1, { headers: { 'token': getToken() }, params: { id_registro_propiedad } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            setShowModalCreate(false);
            reset();
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            dispatch(loaded())
            errorModal('error al hacer el guardado');
        }
    }
}

export const eliminarDesmembracionIndividual = (id_desmembracion, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.delete('/registropropiedad/desmembracion/eliminar', { headers: { 'token': getToken() }, params: { id_desmembracion } });
            dispatch(loaded());
            successModal('Registro eliminado correctamente', 'center');
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}

//DETALLE DESMEMBRACION
export const editarDetalleDesmembracionIndividual = (id_detalle_desmembracion, data, setEdicion, id_registro_propiedad) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/registropropiedad/detalledesmembracion/editar', data, { headers: { 'token': getToken() }, params: { id_detalle_desmembracion } });
            successModal('Registro editado correctamente', 'center');
            setEdicion(false);
            dispatch(loaded());
            dispatch(obtenerRegistroPropiedadDetallado2(id_registro_propiedad));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
        }
    }
}