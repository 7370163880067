import { useDispatch, useSelector } from 'react-redux';
import { crearAnexo, editarAnexo, eliminarAnexo, eliminarImagen, obtenerAnexoIndividual, obtenerAnexos } from '../store/slice/anexo';


export const useAnexo = () => {

    const dispatch = useDispatch();
    const { anexo, loading, anexoIndividual, imagenes } = useSelector((state) => state.anexo);

    const obtenerAnexosTotales = (id_avaluo) => {
        dispatch(obtenerAnexos(id_avaluo));
    }

    const obtenerAnexoIndividualTotal = (id_anexo, setShow2) => {
        dispatch(obtenerAnexoIndividual(id_anexo, setShow2));
    }

    const eliminarAnexoIndividualTotal = (id_anexo, id_avaluo) => {
        dispatch(eliminarAnexo(id_anexo, id_avaluo));
    }

    const crearAnexoIndividual = (data, setShow, id_avaluo, fileList, setFileList) => {
        dispatch(crearAnexo(data, setShow, id_avaluo, fileList, setFileList));
    }

    const editarAnexoIndividual = (data, setShow2, id_avaluo, fileList, setFileList, id_anexo) => {
        dispatch(editarAnexo(data, setShow2, id_avaluo, fileList, setFileList, id_anexo));
    }

    const eliminarImagenIndividual = (id_imagen, id_anexo) => {
        dispatch(eliminarImagen(id_imagen, id_anexo));
    }


    return {
        //valores
        anexo,
        loading,
        anexoIndividual,
        imagenes,

        //funciones
        obtenerAnexosTotales,
        obtenerAnexoIndividualTotal,
        eliminarAnexoIndividualTotal,
        crearAnexoIndividual,
        editarAnexoIndividual,
        eliminarImagenIndividual
    }
}
