import { createSlice } from '@reduxjs/toolkit';

export const agenciaSlice = createSlice({
    name: 'agencia',
    initialState: {
        agencia: [],
        cantidad: null,
        totalPaginas: null,
        loading: false,
        agenciaIndividual: {},
    },
    reducers: {
        agenciaCredentials: (state, { payload }) => {
            state.agencia = payload.agencia;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        agenciaIndividualCredentials: (state, {payload}) => {
            state.agenciaIndividual = payload.agencia
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { agenciaCredentials, agenciaIndividualCredentials, isLoading, loaded } = agenciaSlice.actions;