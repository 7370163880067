import Swal from "sweetalert2"

export const successModal = (text, position1 = 'center', icono = 'success') => {
    Swal.fire({
        position: position1,
        icon: icono,
        title: text,
        showConfirmButton: false,
        timer: 1500
    })
}

export const errorModal = (textError) => {
    Swal.fire({
        icon: 'error',
        text: textError,
        showConfirmButton: true,
        timer: 30000
    })
}

export const questionModal = (title, mensaje, funcion) => {
    Swal.fire({
        title: title,
        text: mensaje,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar'
    }).then((result) => {
        if (result.isConfirmed) {
            funcion();
        }
    })
}

export const textAreaModal = async (funcion, obtenerTotalSolicitudes, id_solicitud_avaluo, watchItems, setShow3, setValue2) => {

    const { value: text } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'Mensaje',
        inputPlaceholder: 'Escribe el mensaje aquí...',
        inputAttributes: {
            'aria-label': 'Type your message here'
        },
        showCancelButton: true
    });
    if (text) {
        let data = {
            comentario: text
        };
        funcion(obtenerTotalSolicitudes, id_solicitud_avaluo, data, watchItems, setShow3, setValue2);
    } else if (text === '') {
        errorModal('Es necesario que ingrese un mensaje, para validar el rechazo de la solicitud');
    }
}

export const textAreaModal2 = async (funcion, respuestaComentario, id_solicitud_avaluo) => {

    const { value: text } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'Mensaje',
        inputPlaceholder: 'Escribe un mensaje de respuesta...',
        inputAttributes: {
            'aria-label': 'Type your message here'
        },
        showCancelButton: true
    });
    if (text) {
        let data = {
            comentario: text
        };
        respuestaComentario(id_solicitud_avaluo, data);
        funcion();

    } else if (text === '') {
        errorModal('Es necesario que ingrese un mensaje, para validar el rechazo de la solicitud');
    }
}