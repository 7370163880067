import { useDispatch, useSelector } from "react-redux";
import { crearInfraestructura, editarInfraestructura, eliminarInfraestructura, obtenerInfraestructura, obtenerInfraestructuras } from "../store/slice/infraestructura";

export const useInfraestructuraSlice = () => {

    const dispatch = useDispatch();
    const { infraestructuras, loading, infraestructura } = useSelector((state) => state.infraestructura);

    const obtenerInfraestructurasCompletas = (id_avaluo) => {
        dispatch(obtenerInfraestructuras(id_avaluo));
    }

    const obtenerInfraestructuraIndividual = (id_infraestructura, setShow2) => {
        dispatch(obtenerInfraestructura(id_infraestructura, setShow2));
    }

    const eliminaInfraestructuraIndividual = (id_infraestructura, id_avaluo) => {
        dispatch(eliminarInfraestructura(id_infraestructura, id_avaluo));
    }

    const crearInfraestructuraIndividual = (id_avaluo, data, setShow) => {
        dispatch(crearInfraestructura(id_avaluo, data, setShow));
    }

    const editarInfraestructuraIndividual = (id_infraestructura, data, id_avaluo, setShow2) => {
        dispatch(editarInfraestructura(id_infraestructura, data, id_avaluo, setShow2));
    }

    return {
        //valores
        infraestructuras,
        loading,
        infraestructura,

        //funciones
        obtenerInfraestructurasCompletas,
        obtenerInfraestructuraIndividual,
        eliminaInfraestructuraIndividual,
        crearInfraestructuraIndividual,
        editarInfraestructuraIndividual
    }


}