
export const departamentos = [
    {departamento: "Alta Verapaz"},
    {departamento: "Baja Verapaz"},
    {departamento: "Chimaltenango"},
    {departamento: "Chiquimula"},
    {departamento: "El Progreso"},
    {departamento: "Escuintla"},
    {departamento: "Guatemala"},
    {departamento: "Huehuetenango"},
    {departamento: "Izabal"},
    {departamento: "Jalapa"},
    {departamento: "Jutiapa"},
    {departamento: "Petén"},
    {departamento: "Quetzaltenango"},
    {departamento: "Quiché"},
    {departamento: "Retalhuleu"},
    {departamento: "Sacatepéquez"},
    {departamento: "San Marcos"},
    {departamento: "Santa Rosa"},
    {departamento: "Sololá"},
    {departamento: "Suchitepéquez"},
    {departamento: "Totonicapán"},
    {departamento: "Zacapa"},
    {departamento: "Jalapa-Jutiapa"},
    {departamento: "Propiedad Horizontal Guatemala"},
    {departamento: "Grupo Norte"},
    {departamento: "Propiedad Horizontal Escuintla"},
    {departamento: "Propiedad Horizontal Sacatepéquez"},
    {departamento: "Libro Antiguo"},
    {departamento: "Propiedad Horizontal Alta Verapaz"},
    {departamento: "Vivienda Popular"},
    {departamento: "Propiedad Horizontal de Quetzaltenango"},
    {departamento: "Transformación Agraria"},
    {departamento: "Reforma Agraria"},
    {departamento: "Inscripciones Especiales"},
    {departamento: "Concesiones"},
    {departamento: "Minas"},
    {departamento: "Bienes Muebles de Quetzaltenango"},
    {departamento: "Libro de Aguas de Quetzaltenango"},
    {departamento: "Prenda Especial"},
    {departamento: "Prenda Agraria"},
    {departamento: "Prenda Ganadera"},
    {departamento: "Prenda Industrial"},
    {departamento: "Prenda Agricola Industrial"},
    {departamento: "Libro de Minas de Quetzaltenango"},
    {departamento: "Libro Bienes de la Nacion"},
    {departamento: "Prenda Agraria de Quetzaltenango"},
    {departamento: "Concesiones Quetzaltenango"},
    {departamento: "Primera Serie"},
    {departamento: "Amatitlan"},
    {departamento: "Vehículos Motorizados"},
    {departamento: "Prendas de Vehículos Motorizados"},
    {departamento: "Ferrocarriles"},
    {departamento: "Diario de Alta Verapaz"},
    {departamento: "Naves y Aeronaves"},
    {departamento: "Testamentos"},
    {departamento: "Testamentos Quetzaltenango"},
    {departamento: "Vehículos Motorizados Quetzaltenango"},
    {departamento: "Prendas de Vehículos Motorizados Quetzaltenango"},
    {departamento: "Propiedad Horizontal de Solola"},
    {departamento: "Propiedad Horizontal de Totonicapan"},
    {departamento: "Propiedad Horizontal de Suchitepequez"},
    {departamento: "Propiedad Horizontal de Retalhuleu"},
    {departamento: "Propiedad Horizontal de San Marcos"},
    {departamento: "Propiedad Horizontal de Huehuetenango"},
    {departamento: "Propiedad Horizontal de El Quiche"},
    {departamento: "Propiedad Horizontal Izabal"},
    {departamento: "Inscripciones Especiales Quetzaltenango"},
    {departamento: "Propiedad Horizontal de Santa Rosa"},
    {departamento: "Propiedad Horizontal de El Peten"},
    {departamento: "Prenda Especial Quetzaltenango"},
    {departamento: "Prenda Agraria Bandesa"},
    {departamento: "Prenda Especial Bandesa"},
    {departamento: "Prenda Ganadera Bandesa"},
    {departamento: "Prenda Pequeña Empresa"},
    {departamento: "Propiedad Horizontal Jutiapa"},
    {departamento: "Libro de Minas de El Quiché"},
    {departamento: "Libro de Minas e Hidrocarburos de Solola"},
    {departamento: "Propiedad Horizontal Chimaltenango"},
    {departamento: "Diario de Grupo Norte"},


]