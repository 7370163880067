import { useEffect, useState } from 'react'
import { useAgencia, useAvaluoSlice, useUser } from '../../../hooks'
import { useForm } from 'react-hook-form';
import { TrTable, TdTable, ThTable } from '../components';
import { NavLink } from 'react-router-dom';
import { InputSearch2, Loader, NormalButton, Pagination, SelectOption4, TotalPagesAndData, TransitionPopover } from '../../../ui';
import { encryptId, questionModal } from '../../../utils';
import { CrearAvaluo } from './CrearAvaluo';
import { EditarAvaluo } from './EditarAvaluo';
import { InformeAvaluo } from './InformeAvaluo';

export const Avaluo = () => {

    const { register, watch, setValue } = useForm();
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const { avaluos, totalPaginas, cantidad, obtenerTotalAvaluos, obtenerAvaluoIndividual, enviarAprobacion, loading, avaluoInforme, limpiarAvaluoInd } = useAvaluoSlice();
    const { agencia, obtenerTotalAgencias } = useAgencia();
    const { asesores, obtenerTotalAsesores } = useUser();

    let watchItems = watch();

    useEffect(() => {
        obtenerTotalAvaluos(watchItems.buscador, page, 0, "", "", watchItems.id_agencia, watchItems.id_usuario);
        // eslint-disable-next-line
    }, [watchItems.buscador, page, watchItems.id_agencia, watchItems.id_usuario]);

    useEffect(() => {
        setPage(1);
    }, [watchItems.buscador, watchItems.id_agencia, watchItems.id_usuario]);

    useEffect(() => {
        obtenerTotalAgencias();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (watchItems.id_agencia === undefined || watchItems.id_agencia === '') {
            obtenerTotalAsesores('');
        } else {
            obtenerTotalAsesores(watchItems.id_agencia);
        }
        // eslint-disable-next-line
    }, [watchItems.id_agencia])

    const avaluoIndividual = (id_avaluo) => {
        obtenerAvaluoIndividual(id_avaluo);
        setShowEdit(true);
    }

    const visualizarInforme = (id_avaluo) => {
        avaluoInforme(id_avaluo, setShow2);
    }

    return (
        <>
            {loading && <Loader />}
            <div className='mx-20 my-14'>
                <div className='my-5 text-gray-400 font-bold text-4xl'>
                    <h1>
                        AVALÚOS
                        <hr className='w-44' />
                    </h1>

                </div>
                <div className='flex lg:flex-nowrap lg:justify-between flex-wrap'>
                    <div className='lg:w-3/4 w-full flex flex-wrap'>
                        <InputSearch2 nameLabel='buscador' register={register} name="buscador" position='lg:w-1/3 mb-3 font-semibold text-gray-500 mx-1' />
                        <SelectOption4 nameLabel='agencia' register={register} name="id_agencia" array={agencia} valueOption='id_agencia' nameOption='agencia' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                        <SelectOption4 nameLabel='asesor' register={register} name="id_usuario" array={asesores} valueOption='id_usuario' nameOption='nombre' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                    </div>
                    <div>
                        <NormalButton funcion={() => setShow(true)} bgColor='bg-blue-900' bgText='text-white font-semibold' dimension="px-4 py-2 lg:mt-7 mb-2 mx-1 rounded" hoverColor='hover:bg-blue-800' title={
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                Crear informe
                            </div>
                        } />
                    </div>
                </div>
                <table className={"border-collapse w-full"}>
                    <thead>
                        <tr>
                            <ThTable titulo='Correlativo' />
                            <ThTable titulo='Solicitante' />
                            <ThTable titulo='CIF' />
                            <ThTable titulo='Ubicación Legal' />
                            <ThTable titulo='Agencia' />
                            <ThTable titulo='Asesor' />
                            <ThTable titulo='Tipo de avalúo' />
                            <ThTable titulo='Inmueble' />
                            <ThTable titulo='Acciones' />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            avaluos.map((item, index) => (
                                <TrTable key={index}>
                                    <TdTable titulo='Correlativo' valor={item.solicitud_avaluo.correlativo} />
                                    <TdTable titulo='Solicitante' valor={item.solicitante} />
                                    <TdTable titulo='CIF' valor={item.solicitud_avaluo.cif_solicitante} />
                                    <TdTable titulo='Ubicación Legal' valor={item.ubicacion_legal} />
                                    <TdTable titulo='Agencia' valor={item.solicitud_avaluo.agencium.agencia} />
                                    <TdTable titulo='Asesor' valor={item.solicitud_avaluo.usuario.nombre} />
                                    <TdTable titulo='Tipo de avalúo' valor={item.tipo_avaluo === 1 ? 'Hipotecario' : 'Activo'} />
                                    <TdTable titulo='Inmueble' valor={item.solicitud_avaluo.tipo_inmueble} />
                                    <TdTable valor={
                                        <div className='flex justify-center mx-2'>
                                            <div className="w-4 mr-3 transform hover:text-green-500 hover:scale-110 ">
                                                <NavLink to={`/avaluo/generacion/${encryptId(item.id_avaluo)}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                                    </svg>
                                                </NavLink>
                                            </div>
                                            <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                                                <svg onClick={() => avaluoIndividual(item.id_avaluo)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                </svg>
                                            </div>
                                            <div className="w-4 mr-3 transform hover:text-green-500 hover:scale-110 ">
                                                <svg onClick={() => visualizarInforme(item.id_avaluo)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </div>
                                            <div className="w-4 transform hover:text-blue-500 hover:scale-110">
                                                <svg onClick={() => questionModal('Revisión', '¿Desea enviar este avalúo a revisión para su aprobación?', () => enviarAprobacion(item.id_avaluo, obtenerTotalAvaluos))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                                </svg>
                                            </div>
                                        </div>
                                    } />
                                </TrTable>
                            ))
                        }
                    </tbody>
                </table>
                <div className="flex justify-end mt-3">
                    <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
                </div>
                <div className="flex justify-end mt-3">
                    <TotalPagesAndData amount={cantidad} page={page} totPages={totalPaginas} />
                </div>

                <TransitionPopover show={show} setShow={setShow}>
                    <CrearAvaluo setShow={setShow} setValue={setValue} />
                </TransitionPopover>

                <TransitionPopover show={showEdit} setShow={setShowEdit} limpiar={limpiarAvaluoInd} >
                    <EditarAvaluo setShowEdit={setShowEdit} setValue2={setValue} />
                </TransitionPopover>

                <TransitionPopover show={show2} setShow={setShow2}>
                    <InformeAvaluo setShow={setShow2} />
                </TransitionPopover>
            </div>
        </>
    )
}
