import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAgencia, useColaborador, usePuesto } from "../../hooks";
import { InputSearch, Loader, NormalButton, Pagination, SelectOption3, TransitionPopover } from "../../ui";
import { TdTable, ThTable, TrTable } from "../avaluo/components";
import { CrearColaborador } from "./CrearColaborador";
import { EditarColaborador } from "./EditarColaborador";
import { questionModal } from "../../utils";

export const Colaborador = () => {

  const [page, setPage] = useState(1);
  const { register, watch, setValue } = useForm();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const { colaborador, loading, totalPaginas, obtenerColaboradorIndividualTotal, obtenerTotalColaboradores, eliminarColaboradorTotal } = useColaborador();
  const { agencia, obtenerTotalAgencias } = useAgencia();
  const { puesto, obtenerTotalPuestos } = usePuesto();
  let watchItems = watch();

  useEffect(() => {

    obtenerTotalColaboradores(watchItems.buscador, 5, page, watchItems.agencia, watchItems.puesto);
    // eslint-disable-next-line
  }, [page, watchItems.buscador, watchItems.agencia, watchItems.puesto]);

  useEffect(() => {
    setPage(1);
  }, [watchItems.buscador, watchItems.agencia, watchItems.puesto]);

  useEffect(() => {
    obtenerTotalAgencias();
    obtenerTotalPuestos();
    // eslint-disable-next-line
  }, [])


  return (
    <>
      {loading && <Loader />}
      <div className='mx-20 my-14'>
        <div className='my-5 text-gray-400 font-bold text-4xl'>
          <h1>
            COLABORADORES
            <hr className='w-1/6' />
          </h1>

        </div>

        <div className='flex lg:flex-nowrap lg:justify-between flex-wrap'>
          <div className="flex md:flex-nowrap flex-wrap justify-start">
            <InputSearch register={register} name="buscador" position=' mb-3 font-semibold text-gray-500 mx-1' />
            <SelectOption3 register={register} name="agencia" array={agencia} valueOption='id_agencia' nameOption='agencia' position=' mb-3 font-semibold text-gray-500 mx-1' />
            <SelectOption3 register={register} name="puesto" array={puesto} valueOption='id_puesto' nameOption='puesto' position=' mb-3 font-semibold text-gray-500 mx-1' />

          </div>
          <NormalButton bgColor='bg-blue-900  mx-1' bgText='text-white' hoverColor='hover:bg-blue-800' title={
            <div className="flex font-semibold">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Crear Colaborador
            </div>

          } funcion={() => setShow(true)} />
        </div>

        <table className={"border-collapse w-full"}>
          <thead>
            <tr>
              <ThTable titulo='Colaborador' />
              <ThTable titulo='Celular' />
              <ThTable titulo='Puesto' />
              <ThTable titulo='Agencia' />
              <ThTable titulo='Acciones' />
            </tr>
          </thead>
          <tbody>
            {
              colaborador.length === 0 ?
                <tr>
                  <td>
                    <div className=' text-center font-bold text-gray-400 m-4'>No existen registros de Colaboradores</div>
                  </td>
                </tr>
                :
                colaborador.map((item, index) => (
                  <TrTable key={index}>
                    <TdTable valor={item.colaborador} />
                    <TdTable valor={item.celular} />
                    <TdTable valor={item.puesto.puesto} />
                    <TdTable valor={item.agencium.agencia} />
                    <TdTable valor={
                      <div className='flex justify-center mx-2'>
                        <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                          <svg onClick={() => obtenerColaboradorIndividualTotal(item.id_colaborador, setShow2)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                          </svg>
                        </div>

                        <div className="w-4 mr-3 transform hover:text-red-500 hover:scale-110">
                          <svg onClick={() => questionModal('Eliminar', '¿Desea eliminar la información de este colaborador?', () => eliminarColaboradorTotal(item.id_colaborador))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                          </svg>
                        </div>
                      </div>} />
                  </TrTable>
                ))
            }

          </tbody>
        </table>
        <div className="flex justify-end mt-3">
          <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
        </div>
        <TransitionPopover show={show} setShow={setShow} >
          <CrearColaborador setShow={setShow} setValue={setValue} />
        </TransitionPopover>
        <TransitionPopover show={show2} setShow={setShow2} >
          <EditarColaborador setShow2={setShow2} setValue2={setValue} />
        </TransitionPopover>
      </div>
    </>
  )
}
