import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useAgencia, useAvaluoSlice, useUser } from '../../../../hooks';
import { InputDate2, InputSearch2, Loader, Pagination, SelectOption4, TotalPagesAndData, TransitionPopover } from '../../../../ui';
import { TdTable, ThTable, TrTable } from '../../components';
import { DetallesAvaluo } from './DetallesAvaluo';

export const Fase = () => {
  const { register, watch } = useForm();
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [infoAvaluo, setInfoAvaluo] = useState({});
  const { avaluos, totalPaginas, obtenerTotalAvaluos, loading, cantidad } = useAvaluoSlice();
  const { valuadores, asesores, obtenerTotalValuadores, obtenerTotalAsesores } = useUser();
  const { agencia, obtenerTotalAgencias } = useAgencia();

  let watchItems = watch();

  useEffect(() => {
    obtenerTotalValuadores()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    obtenerTotalAvaluos(watchItems.buscador, page, 3, watchItems.valuador, "", watchItems.id_agencia, watchItems.id_usuario, watchItems.fechaA, watchItems.fechaB);
    // eslint-disable-next-line
  }, [watchItems.buscador, page, watchItems.valuador, watchItems.id_agencia, watchItems.id_usuario, watchItems.fechaA, watchItems.fechaB]);

  useEffect(() => {
    setPage(1);
  }, [watchItems.buscador, watchItems.valuador, watchItems.id_agencia, watchItems.id_usuario, watchItems.fechaA, watchItems.fechaB]);

  useEffect(() => {
    obtenerTotalAgencias();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (watchItems.id_agencia === undefined || watchItems.id_agencia === '') {
      obtenerTotalAsesores('');
    } else {
      obtenerTotalAsesores(watchItems.id_agencia);
    }
    // eslint-disable-next-line
  }, [watchItems.id_agencia])

  const visualizarInformacion = (avaluo) => {
    setShow(true)
    setInfoAvaluo(avaluo);
  }


  return (
    <>
      {loading && <Loader />}
      <div className='mx-20 my-14'>
        <div className='my-5 text-gray-400 font-bold text-4xl'>
          <h1>
            RETROCESO DE FASES
            <hr className='w-1/4' />
          </h1>

        </div>
        <div className='flex lg:flex-nowrap lg:justify-start flex-wrap'>
          <InputSearch2 nameLabel='buscador' register={register} name="buscador" position='lg:w-1/3 mb-3 font-semibold text-gray-500 mx-1' />
          <SelectOption4 nameLabel='Valuador' register={register} name="valuador" array={valuadores} valueOption='id_usuario' nameOption='nombre' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
          <SelectOption4 nameLabel='agencia' register={register} name="id_agencia" array={agencia} valueOption='id_agencia' nameOption='agencia' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
          <SelectOption4 nameLabel='asesor' register={register} name="id_usuario" array={asesores} valueOption='id_usuario' nameOption='nombre' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
          <InputDate2 nameLabel='(Autorizados) de:' register={register} name='fechaA' position='lg:w-1/5' />
          <InputDate2 nameLabel='(Autorizados) hasta:' register={register} name='fechaB' position='lg:w-1/5' />
        </div>
        <table className={"border-collapse w-full"}>
          <thead>
            <tr>
              <ThTable titulo='Correlativo' />
              <ThTable titulo='Solicitante' />
              <ThTable titulo='CIF' />
              <ThTable titulo='Ubicación Legal' />
              <ThTable titulo='Agencia' />
              <ThTable titulo='Asesor' />
              <ThTable titulo='Valuador' />
              <ThTable titulo='Solicitud' />
              <ThTable titulo='Aprobación' />
              <ThTable titulo='Autorización' />
              <ThTable titulo='Acciones' />
            </tr>
          </thead>
          <tbody>
            {
              avaluos.map((item, index) => (
                <TrTable key={index}>
                  <TdTable titulo='Correlativo' valor={item.solicitud_avaluo.correlativo} />
                  <TdTable titulo='Solicitante' valor={item.solicitante} />
                  <TdTable titulo='CIF' valor={item.solicitud_avaluo.cif_solicitante} />
                  <TdTable titulo='Ubicación Legal' valor={item.ubicacion_legal} />
                  <TdTable titulo='Agencia' valor={item.solicitud_avaluo.agencium.agencia} />
                  <TdTable titulo='Asesor' valor={item.solicitud_avaluo.usuario.nombre} />
                  <TdTable titulo='Valuador' valor={item.usuario.nombre} />
                  <TdTable titulo='Solicitud' valor={item.fecha_aprobacion} />
                  <TdTable titulo='Aprobación' valor={item.fecha_aprobado} />
                  <TdTable titulo='Autorización' valor={item.fecha_autorizado} />
                  <TdTable valor={
                    <div className='flex justify-center mx-2'>
                      <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110 ">
                        <svg onClick={() => visualizarInformacion(item)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                        </svg>
                      </div>
                    </div>


                  } />
                </TrTable>
              ))
            }
          </tbody>
        </table>
        <div className="flex justify-end mt-3">
          <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
        </div>
        <div className="flex justify-end mt-3">
          <TotalPagesAndData amount={cantidad} page={page} totPages={totalPaginas} />
        </div>
        <TransitionPopover show={show} setShow={setShow}>
          <DetallesAvaluo setShow={setShow} infoAvaluo={infoAvaluo} />
        </TransitionPopover>
      </div>
    </>
  )
}
