import { createSlice } from '@reduxjs/toolkit';

export const avaluoSlice = createSlice({
    name: 'avaluo',
    initialState: {
        avaluos: [],
        cantidad: null,
        totalPaginas: null,
        loading: false,
        avaluoIndividual: {},
        informe: {}
    },
    reducers: {
        avaluoCredentials: (state, { payload }) => {
            state.avaluos = payload.avaluos;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        avaluoIndividualCredentials: (state, {payload}) => {
            state.avaluoIndividual = payload.avaluo
            state.loading = false;
        },
        avaluoIndividualClearCredentials: (state) => {
            state.avaluoIndividual = {}
        },
        informeCredentials: (state, {payload}) => {
            state.informe = {
                avaluo: payload.avaluo,
                registro_propiedad: payload.registro_propiedad,
                detalle_inmueble: payload.detalle_inmueble,
                infraestructura: payload.infraestructura,
                plusvaliasMinusvalias: payload.plusvaliasMinusvalias,
                observaciones: payload.observaciones,
                anexos: payload.anexos,
                descripcion_referencial: payload.descripcion_referencial,
                valor_inmueble_terreno: payload.valor_inmueble_terreno,
                usuario: payload.usuario
            }
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { avaluoCredentials, avaluoIndividualCredentials, informeCredentials, isLoading, loaded, avaluoIndividualClearCredentials } = avaluoSlice.actions;