import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse } from "../../../utils";
import { loaded } from "../colaborador";
import { isLoading, puestoCredentials } from "./puestoSlice";


export const obtenerPuestos = () => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/puesto', { headers: { 'token': getToken() } });
            dispatch(puestoCredentials(data));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
            errorModal('ocurrió un error al cargar la información de los puestos');
        }
    }
}

export const obtenerPuestosEdicion = (setValue, puesto) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/puesto', { headers: { 'token': getToken() } });
            dispatch(puestoCredentials(data));
            setValue('id_puesto', puesto);
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
            errorModal('ocurrió un error al cargar la información de los puestos');
        }
    }
}