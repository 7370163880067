import { useDispatch, useSelector } from 'react-redux';
import { obtenerAgencias, obtenerAgenciasEdicion } from '../store/slice/agencia';

export const useAgencia = () => {

    const dispatch = useDispatch();
    const { agencia, loading, cantidad, totalPaginas, agenciaIndividual } = useSelector((state) => state.agencia);

    const obtenerTotalAgencias = () => {
        dispatch(obtenerAgencias());
    }

    const obtenerTotalAgenciasEdicion = (setValue, agencia) => {
        dispatch(obtenerAgenciasEdicion(setValue, agencia));
    }

    return {
        //valores
        agencia,
        loading,
        cantidad,
        totalPaginas,
        agenciaIndividual,

        //funciones
        obtenerTotalAgencias,
        obtenerTotalAgenciasEdicion

    }
}
